import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactDragListView from 'react-drag-listview/lib';
import { useTranslation } from 'react-i18next';
import { Empty, Grid } from 'antd';
import { StyledTable } from 'components/styledComponents/table/Table.styled';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledRowExpandIcon } from 'components/styledComponents/icon/Icon.styled';
import { StyledPaginationWrapper } from 'components/styledComponents/pagination/Pagination.styled';
import { AppConstants } from 'constants/index';
import ResizeableTitle from './ResizeableTitle';

const dragRow = {
  title: '',
  dataIndex: '',
  key: 'drag',
  width: 50,
  disabled: true,
  className: 'drag-row-handle',
  render: () => <IconSvg icon="DragRowsIcon" width="1.71rem" height="1.71rem" color="" />,
};

const actionCols = ['actions', 'action'];
const { useBreakpoint } = Grid;

const AdminTable = (props) => {
  const {
    columns,
    data,
    rowKey,
    pagination,
    rowClassName,
    components,
    rowSelection,
    size,
    scroll,
    loading,
    dragCols,
    dragRows,
    moveRow,
    showHeader,
    onChange,
    expandedRowKeysProp,
    expandedRowRender,
    expandable,
    createOrEditUserConfig,
    tableKey,
    tableLayout,
    footer,
    isResizable,
    sticky,
    virtual,
    configColumns: existColumns,
    pageSize: existPageSize,
    pageSizeKey,
    tblRef,
  } = props;

  const screens = useBreakpoint();

  const isTableResizable = useMemo(() => screens.md && isResizable, [isResizable, screens.md]);
  const [currentPage, setCurrentPage] = useState(0);

  const [expandedRowKeys, setExpandedRowKeys] = useState(expandedRowKeysProp);
  const [tableData, setTableData] = useState(data);
  const [isResizing, setIsResizing] = useState(false);
  const { t } = useTranslation();

  const getColWidth = useCallback(
    (el, colWidthConfig = {}) => (!screens.md && actionCols.includes(el.dataIndex) && AppConstants.actionsWidth.defaultIcon)
      || (el.setInitialWidth ? _.max([el.width, AppConstants.actionsWidth.minWidth]) : colWidthConfig[el.dataIndex] || el.width),
    [screens.md],
  );

  const getTitle = useCallback((el) => (!screens.md && actionCols.includes(el.dataIndex) && ' ') || (_.isString(el.title) ? t(el.title) : el.title), [screens.md, t]);

  const handleTableCols = useCallback(
    (cols) => {
      let changedCols = cols;
      const colWidthConfig = {};

      if (tableKey && !_.isUndefined(existColumns)) {
        const sortedCols = [];
        const checkedCols = [];
        existColumns.forEach((el) => {
          sortedCols.push(el.dataIndex);
          if (el.checked) {
            checkedCols.push(el.dataIndex);
          }
          if (isTableResizable) {
            colWidthConfig[el.dataIndex] = el.width;
          }
        });
        if (dragCols) {
          changedCols = _.sortBy(cols, (column) => sortedCols.indexOf(column.dataIndex));
        }
        if (isTableResizable) {
          changedCols = changedCols.map((el) => (el.dataIndex ? { ...el, width: getColWidth(el, colWidthConfig) } : el));
        }
        changedCols = changedCols.filter(({ dataIndex }) => checkedCols.includes(dataIndex));
      }
      if (dragRows) {
        changedCols = [{ ...dragRow, fixed: changedCols[0]?.fixed }, ...changedCols];
      }
      return changedCols.map((el) => ({ ...el, ...(!!el.dataIndex && { title: getTitle(el), width: getColWidth(el) }) }));
    },
    [dragRows, isTableResizable, existColumns, dragCols, tableKey, getColWidth, getTitle],
  );

  const configColumns = useMemo(() => handleTableCols(columns), [handleTableCols, columns]);

  const [tableCols, setTableCols] = useState(configColumns);

  useEffect(() => {
    setTableCols(configColumns);
  }, [setTableCols, configColumns]);

  useEffect(() => {
    setExpandedRowKeys(expandedRowKeysProp);
  }, [expandedRowKeysProp]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // To handle the case - getting current page but data is not changing yet
  useEffect(() => {
    if (pagination && !_.isNil(pagination.hasNext) && !_.isEqual(tableData, data)) {
      setCurrentPage(pagination.current);
    }
  }, [data, tableData, pagination]);

  const pageSize = useMemo(() => existPageSize || AppConstants.pageSize, [existPageSize]);

  // Calculate total include currentPage
  const currentTotal = useMemo(
    () => (pagination && !_.isNil(pagination.hasNext) ? (currentPage - 1) * pageSize + data?.length + +pagination.hasNext : 0),
    [currentPage, pageSize, data?.length, pagination],
  );

  const saveCols = useCallback(
    (movedCols, isLoading) => {
      let configValue = [];
      const existsConfig = existColumns;
      if (_.isUndefined(existsConfig)) {
        configValue = movedCols.map(({ dataIndex, width }) => ({
          dataIndex,
          checked: true,
          width,
        }));
        createOrEditUserConfig({ key: tableKey, configValue }, isLoading);
      } else {
        const checkedCols = {};
        existsConfig.forEach(({ dataIndex, checked }) => {
          checkedCols[dataIndex] = checked;
        });
        configValue = movedCols.map(({ dataIndex, width }) => ({
          dataIndex,
          checked: checkedCols[dataIndex],
          width,
        }));
        createOrEditUserConfig({ key: tableKey, configValue }, isLoading);
      }
    },
    [existColumns, tableKey, createOrEditUserConfig],
  );

  const getAllSyncedColumns = useCallback(() => {
    const initialConfig = existColumns || columns;
    const currentWidths = tableCols.reduce((acc, { dataIndex, width }) => {
      acc[dataIndex] = width;
      return acc;
    }, {});

    const newCols = initialConfig.map((col) => ({
      ...col,
      width: currentWidths[col.dataIndex] || col.width,
    }));

    return newCols;
  }, [columns, existColumns, tableCols]);

  const dragColProps = {
    onDragEnd(from, to) {
      // to define indexes by column name, and re-order all columns
      const newData = getAllSyncedColumns();
      const fromDataIndex = tableCols[from]?.dataIndex;
      const toDataIndex = tableCols[to]?.dataIndex;
      let fromIndex = 0;
      let toIndex = 1;

      newData.forEach((el, index) => {
        if (fromDataIndex === el.dataIndex) {
          fromIndex = index;
        } else if (toDataIndex === el.dataIndex) {
          toIndex = index;
        }
      });
      [newData[fromIndex], newData[toIndex]] = [newData[toIndex], newData[fromIndex]];

      saveCols(newData);
    },
    nodeSelector: 'th:not([class*="ant-table-cell-fix"]), th.ant-table-cell-resizing:not([draggable="true"])',
    ignoreSelector: 'th.ant-table-row-expand-icon-cell, th.ant-table-selection-column, .ant-table-expanded-row-fixed th, th.ant-table-cell-resizing',
    lineClassName: 'dragging-col-custom',
  };

  const dragRowProps = {
    onDragEnd(fromIndex, toIndex) {
      // when scroll exists measure row adding at the top of table
      const from = scroll && Object.keys(scroll).length ? fromIndex - 1 : fromIndex;
      const to = scroll && Object.keys(scroll).length ? toIndex - 1 : toIndex;

      const newData = [...tableData];
      const item = newData.splice(from, 1)[0];
      newData.splice(to, 0, item);
      setTableData(newData);
      moveRow(from, to);
    },
    ignoreSelector: 'tr.ant-table-expanded-row',
    nodeSelector: 'tr.ant-table-row',
    handleSelector: 'td.drag-row-handle',
    lineClassName: 'dragging-row-custom',
  };

  const dragProps = dragCols && tableKey ? { ...dragColProps } : { ...dragRowProps };

  const onExpand = (expanded, record) => {
    if (!window.getSelection().toString().length) {
      if (expandedRowKeys.includes(record[rowKey])) {
        setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record[rowKey]));
      } else {
        setExpandedRowKeys([...expandedRowKeys, record[rowKey]]);
      }
    }
    if (expandable.onExpand) {
      expandable.onExpand(expanded, record);
    }
  };

  const onTableChange = (pg, filter, sorter) => {
    const isPageSizeChanged = pageSize !== pg.pageSize;
    if (!pg.pageSize || !isPageSizeChanged) {
      return onChange?.(pg, filter, sorter);
    }
    return createOrEditUserConfig({ key: pageSizeKey, configValue: pg.pageSize });
  };

  const handleResize = useCallback(
    (index) => (e, { size: { width } }) => {
      e.preventDefault();
      setTableCols((prev) => {
        const nextColumns = [...prev];
        nextColumns[index] = {
          ...nextColumns[index],
          width,
        };
        return nextColumns;
      });
    },
    [],
  );

  const onResizeStart = useCallback(() => {
    setIsResizing(true);
    setTableCols((prev) => prev?.map((el) => ({ ...el, shouldCellUpdate: () => false })));
  }, []);

  const onResizeStop = useCallback(() => {
    if (isResizing) {
      const newCols = getAllSyncedColumns();
      saveCols(newCols, false);
      setIsResizing(false);
      setTableCols((prev) => prev?.map((el) => ({ ...el, shouldCellUpdate: () => true })));
    }
  }, [isResizing, saveCols, getAllSyncedColumns]);

  const cols = useMemo(
    () => (isTableResizable
      ? tableCols.map((col, index) => (col.dataIndex
        ? {
          ...col,
          onHeaderCell: (column) => ({
            minWidth: column.minWidth,
            width: column.width,
            onResize: handleResize(index),
            onResizeStart,
            onResizeStop,
          }),
        }
        : col))
      : tableCols),
    [isTableResizable, tableCols, handleResize, onResizeStart, onResizeStop],
  );

  const renderTable = (
    <StyledPaginationWrapper>
      <StyledTable
        sticky={sticky}
        columns={cols}
        dataSource={tableData}
        size={size}
        rowClassName={rowClassName}
        rowKey={rowKey}
        ref={tblRef}
        virtual={virtual}
        components={
          isTableResizable && (_.isEmpty(tableData) || tableData.length <= AppConstants.maxResizePageSize)
            ? {
              ...components,
              header: {
                ...components?.header,
                cell: ResizeableTitle,
              },
            }
            : components
        }
        pagination={
          pagination && {
            showSizeChanger: true,
            pageSize: existPageSize,
            showLessItems: true,
            defaultPageSize: AppConstants.pageSize,
            pageSizeOptions: AppConstants.pageSizeOptions,
            size: 'default',
            showTotal: (total, range) => (_.isNil(pagination.hasNext) ? t('showTotal', { min: range[0], max: range[1], total }) : null),
            ...pagination,
            total: pagination.total ?? (!_.isNil(pagination.hasNext) && !!data.length ? currentTotal : null),
          }
        }
        rowSelection={rowSelection}
        scroll={scroll}
        loading={loading}
        className={tableData && tableData.length && `${dragCols ? 'draggable-columns' : ''} ${dragRows ? 'draggable-rows' : ''}`}
        showHeader={showHeader}
        onChange={onTableChange}
        tableLayout={tableLayout}
        footer={footer}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />,
          triggerDesc: t('triggerDesc'),
          triggerAsc: t('triggerAsc'),
          expand: t('expandRow'),
          collapse: t('collapseRow'),
          selectionAll: t('selectionAllData'),
          cancelSort: t('cancelSort'),
        }}
        expandable={
          tableData && tableData.length
            ? {
              expandRowByClick: false,
              onExpand,
              expandedRowKeys,
              expandedRowRender,
              expandIcon: (expandProps) => expandedRowRender
                  && (expandProps.expanded ? (
                    <StyledRowExpandIcon
                      onClick={() => {
                        onExpand(expandProps.expanded, expandProps.record);
                      }}
                    >
                      <IconSvg icon="DropdownIcon" width="0.6rem" height="0.6rem" />
                    </StyledRowExpandIcon>
                  ) : (
                    <StyledRowExpandIcon
                      onClick={() => {
                        onExpand(expandProps.expanded, expandProps.record);
                      }}
                    >
                      <IconSvg icon="ArrowRightIcon" width="0.6rem" height="0.6rem" />
                    </StyledRowExpandIcon>
                  )),
              ...expandable,
            }
            : null
        }
      />
    </StyledPaginationWrapper>
  );
  // drag & drop only if data exists
  if (screens.md && tableData && tableData.length && ((dragCols && tableKey) || dragRows)) {
    return <ReactDragListView {...dragProps}>{renderTable}</ReactDragListView>;
  }
  return renderTable;
};

AdminTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowClassName: PropTypes.func,
  pagination: PropTypes.any,
  components: PropTypes.object,
  rowSelection: PropTypes.object,
  scroll: PropTypes.object,
  size: PropTypes.string,
  loading: PropTypes.any,
  dragCols: PropTypes.bool,
  dragRows: PropTypes.bool,
  moveRow: PropTypes.func,
  showHeader: PropTypes.bool,
  onChange: PropTypes.func,
  expandedRowRender: PropTypes.func,
  expandedRowKeysProp: PropTypes.array,
  expandable: PropTypes.object,
  createOrEditUserConfig: PropTypes.func,
  tableKey: PropTypes.string,
  tableLayout: PropTypes.string,
  footer: PropTypes.func,
  isResizable: PropTypes.bool,
  sticky: PropTypes.bool,
  configColumns: PropTypes.array,
  pageSize: PropTypes.number,
  pageSizeKey: PropTypes.string,
  tblRef: PropTypes.object,
  virtual: PropTypes.bool,
};

AdminTable.defaultProps = {
  data: [],
  rowKey: 'id',
  rowClassName: (record, index) => (index % 2 ? 'even' : 'odd'),
  pagination: false,
  components: {},
  createOrEditUserConfig: () => {},
  rowSelection: null,
  scroll: {},
  size: 'small',
  loading: false,
  dragCols: false,
  dragRows: false,
  moveRow: null,
  showHeader: true,
  onChange: undefined,
  expandedRowRender: null,
  expandedRowKeysProp: [],
  expandable: {},
  tableKey: '',
  tableLayout: '',
  footer: null,
  isResizable: false,
  sticky: false,
  configColumns: undefined,
  pageSize: undefined,
  pageSizeKey: 'pageSize',
  tblRef: null,
  virtual: false,
};

export default AdminTable;
