const BrandSettingActionType = {
  BRAND_SETTING_LOADER: 'BRAND_SETTING_LOADER',

  GET_BRAND_CURRENCIES: 'GET_BRAND_CURRENCIES',
  SAVE_BRAND_CURRENCIES: 'SAVE_BRAND_CURRENCIES',
  RESET_BRAND_CURRENCIES: 'RESET_BRAND_CURRENCIES',

  ADD_BRAND_CURRENCY: 'ADD_BRAND_CURRENCY',

  ENABLE_BRAND_CURRENCY: 'ENABLE_BRAND_CURRENCY',

  SET_DEFAULT_CURRENCY: 'SET_DEFAULT_CURRENCY',

  CHANGE_ORDER_CURRENCY: 'CHANGE_ORDER_CURRENCY',

  GET_BRAND_COUNTRIES: 'GET_BRAND_COUNTRIES',
  SAVE_BRAND_COUNTRIES: 'SAVE_BRAND_COUNTRIES',
  RESET_BRAND_COUNTRIES: 'RESET_BRAND_COUNTRIES',

  ADD_BRAND_COUNTRY: 'ADD_BRAND_COUNTRY',

  ENABLE_BRAND_COUNTRY: 'ENABLE_BRAND_COUNTRY',

  SET_DEFAULT_COUNTRY: 'SET_DEFAULT_COUNTRY',

  GET_BRAND_LANGUAGES: 'GET_BRAND_LANGUAGES',
  SAVE_BRAND_LANGUAGES: 'SAVE_BRAND_LANGUAGES',
  RESET_BRAND_LANGUAGES: 'RESET_BRAND_LANGUAGES',

  ADD_BRAND_LANGUAGE: 'ADD_BRAND_LANGUAGE',

  ENABLE_BRAND_LANGUAGE: 'ENABLE_BRAND_LANGUAGE',

  SET_DEFAULT_LANGUAGE: 'SET_DEFAULT_LANGUAGE',

  CHANGE_ORDER_LANGUAGE: 'CHANGE_ORDER_LANGUAGE',

  CHANGE_SUCCESS_STATUS: 'CHANGE_SUCCESS_STATUS',
  SET_BRAND_MAIN_SETTING: 'SET_BRAND_MAIN_SETTING',

  GET_DOMAINS: 'GET_DOMAINS',
  SAVE_DOMAINS: 'SAVE_DOMAINS',
  ADD_DOMAIN: 'ADD_DOMAIN',
  EDIT_DOMAIN: 'EDIT_DOMAIN',
  DELETE_DOMAIN: 'DELETE_DOMAIN',
  CONNECT_DOMAIN: 'CONNECT_DOMAIN',
  DISCONNECT_DOMAIN: 'DISCONNECT_DOMAIN',

  GET_BRAND_SMS_SETTINGS: 'GET_BRAND_SMS_SETTINGS',
  SAVE_BRAND_SMS_SETTINGS: 'SAVE_BRAND_SMS_SETTINGS',
  EDIT_BRAND_SMS_SETTINGS: 'EDIT_BRAND_SMS_SETTINGS',
  DELETE_BRAND_SMS_SETTINGS: 'DELETE_BRAND_SMS_SETTINGS',
  RESET_BRAND_SMS_SETTINGS: 'RESET_BRAND_SMS_SETTINGS',

  GET_BRAND_SMTP_SETTINGS: 'GET_BRAND_SMTP_SETTINGS',
  SAVE_BRAND_SMTP_SETTINGS: 'SAVE_BRAND_SMTP_SETTINGS',
  EDIT_BRAND_SMTP_SETTINGS: 'EDIT_BRAND_SMTP_SETTINGS',
  EDIT_BRAND_CUSTOM_SMTP_SETTINGS: 'EDIT_BRAND_CUSTOM_SMTP_SETTINGS',
  RESET_BRAND_SMTP_SETTINGS: 'RESET_BRAND_SMTP_SETTINGS',

  GET_BRAND_GENERAL_SETTINGS: 'GET_BRAND_GENERAL_SETTINGS',
  SAVE_BRAND_GENERAL_SETTINGS: 'SAVE_BRAND_GENERAL_SETTINGS',
  ADD_BRAND_GENERAL_SETTINGS: 'ADD_BRAND_GENERAL_SETTINGS',

  GET_BRAND_CAPTCHA_SETTINGS: 'GET_BRAND_CAPTCHA_SETTINGS',
  SAVE_BRAND_CAPTCHA_SETTINGS: 'SAVE_BRAND_CAPTCHA_SETTINGS',
  EDIT_BRAND_CAPTCHA_SETTINGS: 'EDIT_BRAND_CAPTCHA_SETTINGS',
  RESET_BRAND_CAPTCHA_SETTINGS: 'RESET_BRAND_CAPTCHA_SETTINGS',

  GET_BRAND_EMAIL_CONTACTS: 'GET_BRAND_EMAIL_CONTACTS',
  SAVE_BRAND_EMAIL_CONTACTS: 'SAVE_BRAND_EMAIL_CONTACTS',
  RESET_BRAND_EMAIL_CONTACTS: 'RESET_BRAND_EMAIL_CONTACTS',
  ADD_BRAND_EMAIL_CONTACT: 'ADD_BRAND_EMAIL_CONTACT',
  SET_BRAND_EMAIL_CONTACT_AS_DEFAULT: 'SET_BRAND_EMAIL_CONTACT_AS_DEFAULT',
  DELETE_BRAND_EMAIL_CONTACT: 'DELETE_BRAND_EMAIL_CONTACT',

  ADD_BRAND_DEPOSIT_CONVERSION: 'ADD_BRAND_DEPOSIT_CONVERSION',
  ADD_BRAND_WITHDRAWAL_CONVERSION: 'ADD_BRAND_WITHDRAWAL_CONVERSION',
  ADD_BRAND_OTHER_CONVERSION: 'ADD_BRAND_OTHER_CONVERSION',

  GET_BRAND_DEPOSIT_CONVERSION: 'GET_BRAND_DEPOSIT_CONVERSION',
  GET_BRAND_WITHDRAWAL_CONVERSION: 'GET_BRAND_WITHDRAWAL_CONVERSION',
  GET_BRAND_OTHER_CONVERSION: 'GET_BRAND_OTHER_CONVERSION',
  SAVE_BRAND_CONVERSION: 'SAVE_BRAND_CONVERSION',
  RESET_BRAND_CONVERSION: 'RESET_BRAND_CONVERSION',

  EDIT_BRAND_DEPOSIT_CONVERSION: 'EDIT_BRAND_DEPOSIT_CONVERSION',
  EDIT_BRAND_WITHDRAWAL_CONVERSION: 'EDIT_BRAND_WITHDRAWAL_CONVERSION',
  EDIT_BRAND_OTHER_CONVERSION: 'EDIT_BRAND_OTHER_CONVERSION',

  DELETE_BRAND_DEPOSIT_CONVERSION: 'DELETE_BRAND_DEPOSIT_CONVERSION',
  DELETE_BRAND_WITHDRAWAL_CONVERSION: 'DELETE_BRAND_WITHDRAWAL_CONVERSION',
  DELETE_BRAND_OTHER_CONVERSION: 'DELETE_BRAND_OTHER_CONVERSION',

  GET_BRAND_DEPOSIT_CONVERSIONS: 'GET_BRAND_DEPOSIT_CONVERSIONS',
  SAVE_BRAND_DEPOSIT_CONVERSIONS: 'SAVE_BRAND_DEPOSIT_CONVERSIONS',
  RESET_BRAND_DEPOSIT_CONVERSIONS: 'RESET_BRAND_DEPOSIT_CONVERSIONS',

  GET_BRAND_WITHDRAWAL_CONVERSIONS: 'GET_BRAND_WITHDRAWAL_CONVERSIONS',
  SAVE_BRAND_WITHDRAWAL_CONVERSIONS: 'SAVE_BRAND_WITHDRAWAL_CONVERSIONS',
  RESET_BRAND_WITHDRAWAL_CONVERSIONS: 'RESET_BRAND_WITHDRAWAL_CONVERSIONS',

  GET_BRAND_OTHER_CONVERSIONS: 'GET_BRAND_OTHER_CONVERSIONS',
  SAVE_BRAND_OTHER_CONVERSIONS: 'SAVE_BRAND_OTHER_CONVERSIONS',
  RESET_BRAND_OTHER_CONVERSIONS: 'RESET_BRAND_OTHER_CONVERSIONS',

  IMPORT_BRAND_DEPOSIT_CONVERSION: 'IMPORT_BRAND_DEPOSIT_CONVERSION',
  IMPORT_BRAND_WITHDRAWAL_CONVERSION: 'IMPORT_BRAND_WITHDRAWAL_CONVERSION',
  IMPORT_BRAND_OTHER_CONVERSION: 'IMPORT_BRAND_OTHER_CONVERSION',

  EXPORT_BRAND_DEPOSIT_CONVERSION: 'EXPORT_BRAND_DEPOSIT_CONVERSION',
  EXPORT_BRAND_WITHDRAWAL_CONVERSION: 'EXPORT_BRAND_WITHDRAWAL_CONVERSION',
  EXPORT_BRAND_OTHER_CONVERSION: 'EXPORT_BRAND_OTHER_CONVERSION',

  GET_BRAND_DEPOSIT_CONVERSION_LOGS: 'GET_BRAND_DEPOSIT_CONVERSION_LOGS',
  GET_BRAND_WITHDRAWAL_CONVERSION_LOGS: 'GET_BRAND_WITHDRAWAL_CONVERSION_LOGS',
  GET_BRAND_OTHER_CONVERSION_LOGS: 'GET_BRAND_OTHER_CONVERSION_LOGS',
  SAVE_BRAND_CONVERSION_LOGS: 'SAVE_BRAND_CONVERSION_LOGS',
  RESET_BRAND_CONVERSION_LOGS: 'RESET_BRAND_CONVERSION_LOGS',

  GET_BRAND_STANDARD_SETTINGS: 'GET_BRAND_STANDARD_SETTINGS',
  SAVE_BRAND_STANDARD_SETTINGS: 'SAVE_BRAND_STANDARD_SETTINGS',
  EDIT_BRAND_STANDARD_SETTINGS: 'EDIT_BRAND_STANDARD_SETTINGS',
  RESET_BRAND_STANDARD_SETTINGS: 'RESET_BRAND_STANDARD_SETTINGS',

  GET_BRAND_BLOCKED_COUNTRIES_RESOURCE: 'GET_BRAND_BLOCKED_COUNTRIES_RESOURCE',
  SAVE_BRAND_BLOCKED_COUNTRIES_RESOURCE: 'SAVE_BRAND_BLOCKED_COUNTRIES_RESOURCE',
  RESET_BRAND_BLOCKED_COUNTRIES_RESOURCE: 'RESET_BRAND_BLOCKED_COUNTRIES_RESOURCE',
  ADD_BRAND_BLOCKED_COUNTRIES: 'ADD_BRAND_BLOCKED_COUNTRIES',
  DELETE_BRAND_BLOCKED_COUNTRY: 'DELETE_BRAND_BLOCKED_COUNTRY',

  GET_BRAND_BLOCKED_IPS: 'GET_BRAND_BLOCKED_IPS',
  SAVE_BRAND_BLOCKED_IPS: 'SAVE_BRAND_BLOCKED_IPS',
  RESET_BRAND_BLOCKED_IPS: 'RESET_BRAND_BLOCKED_IPS',
  ADD_BRAND_BLOCKED_IPS: 'ADD_BRAND_BLOCKED_IPS',
  DELETE_BRAND_BLOCKED_IP: 'DELETE_BRAND_BLOCKED_IP',

  RESET_BRAND_SETTINGS: 'RESET_BRAND_SETTINGS',

  ADD_DOMAIN_REDIRECT_URL: 'ADD_DOMAIN_REDIRECT_URL',
  DELETE_DOMAIN_REDIRECT_URL: 'DELETE_DOMAIN_REDIRECT_URL',

  GET_BRAND_SPORTSBOOK_LAYOUTS: 'GET_BRAND_SPORTSBOOK_LAYOUTS',
  SAVE_BRAND_SPORTSBOOK_LAYOUTS: 'SAVE_BRAND_SPORTSBOOK_LAYOUTS',
  RESET_BRAND_SPORTSBOOK_LAYOUTS: 'RESET_BRAND_SPORTSBOOK_LAYOUTS',
  CHANGE_BRAND_SPORTSBOOK_LAYOUTS_CALL_ENDED_STATUS: 'CHANGE_BRAND_SPORTSBOOK_LAYOUTS_CALL_ENDED_STATUS',

  ACTIVATE_BRAND_SPORTSBOOK_PROVIDER: 'ACTIVATE_BRAND_SPORTSBOOK_PROVIDER',

  SET_BRAND_SPORTSBOOK_DEFAULT_TEMPLATE: 'SET_BRAND_SPORTSBOOK_DEFAULT_TEMPLATE',

  ACTIVATE_BRAND_RGS: 'ACTIVATE_BRAND_RGS',

  GET_BRAND_SPORTSBOOK_SETTINGS: 'GET_BRAND_SPORTSBOOK_SETTINGS',
  SAVE_BRAND_SPORTSBOOK_SETTINGS: 'SAVE_BRAND_SPORTSBOOK_SETTINGS',
  RESET_BRAND_SPORTSBOOK_SETTINGS: 'RESET_BRAND_SPORTSBOOK_SETTINGS',

  ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOP_MATCHES: 'ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOP_MATCHES',
  ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOTAL_TOP_MATCHES: 'ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOTAL_TOP_MATCHES',
  ENABLE_BRAND_SPORTSBOOK_LIVE_PREMATCH_TOP_MATCHES: 'ENABLE_BRAND_SPORTSBOOK_LIVE_PREMATCH_TOP_MATCHES',
  ENABLE_BRAND_SPORTSBOOK_LIVE_TOP_MATCHES: 'ENABLE_BRAND_SPORTSBOOK_LIVE_TOP_MATCHES',
  ENABLE_BRAND_SPORTSBOOK_JACKPOT: 'ENABLE_BRAND_SPORTSBOOK_JACKPOT',
  ENABLE_BRAND_SPORTSBOOK_SECTION_SCROLLING: 'ENABLE_BRAND_SPORTSBOOK_SECTION_SCROLLING',
  ENABLE_BRAND_SPORTSBOOK_TOURNAMENT_BALANCE: 'ENABLE_BRAND_SPORTSBOOK_TOURNAMENT_BALANCE',
  ENABLE_BRAND_SPORTSBOOK_COLOR_INHERITANCE: 'ENABLE_BRAND_SPORTSBOOK_COLOR_INHERITANCE',
  CHANGE_BRAND_SPORTSBOOK_MOBILE_INTEGRATION_TYPE: 'CHANGE_BRAND_SPORTSBOOK_MOBILE_INTEGRATION_TYPE',

  GET_BRAND_PREFERENCES_SETTINGS: 'GET_BRAND_PREFERENCES_SETTINGS',
  SAVE_BRAND_PREFERENCES_SETTINGS: 'SAVE_BRAND_PREFERENCES_SETTINGS',
  RESET_BRAND_PREFERENCES_SETTINGS: 'RESET_BRAND_PREFERENCES_SETTINGS',

  EDIT_BRAND_PREFERENCES_DEPOSIT_SETTINGS: 'EDIT_BRAND_PREFERENCES_DEPOSIT_SETTINGS',
  EDIT_BRAND_PREFERENCES_WITHDRAWAL_SETTINGS: 'EDIT_BRAND_PREFERENCES_WITHDRAWAL_SETTINGS',
  EDIT_BRAND_PREFERENCES_PROMOTION_SETTINGS: 'EDIT_BRAND_PREFERENCES_PROMOTION_SETTINGS',
  EDIT_BRAND_PREFERENCES_BONUSES_SETTINGS: 'EDIT_BRAND_PREFERENCES_BONUSES_SETTINGS',

  GET_BRAND_SPORTSBOOK_WALLETS_SETTINGS: 'GET_BRAND_SPORTSBOOK_WALLETS_SETTINGS',
  SAVE_BRAND_SPORTSBOOK_WALLETS_SETTINGS: 'SAVE_BRAND_SPORTSBOOK_WALLETS_SETTINGS',
  RESET_BRAND_SPORTSBOOK_WALLETS_SETTINGS: 'RESET_BRAND_SPORTSBOOK_WALLETS_SETTINGS',
  EDIT_BRAND_SPORTSBOOK_WALLETS_SETTINGS: 'EDIT_BRAND_SPORTSBOOK_WALLETS_SETTINGS',

  GET_BRAND_CASINO_PRODUCT_SETTINGS: 'GET_BRAND_CASINO_PRODUCT_SETTINGS',
  SAVE_BRAND_CASINO_PRODUCT_SETTINGS: 'SAVE_BRAND_CASINO_PRODUCT_SETTINGS',
  RESET_BRAND_CASINO_PRODUCT_SETTINGS: 'RESET_BRAND_CASINO_PRODUCT_SETTINGS',
  EDIT_BRAND_CASINO_PRODUCT_SETTINGS: 'EDIT_BRAND_CASINO_PRODUCT_SETTINGS',

  GET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS: 'GET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS',
  SAVE_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS: 'SAVE_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS',
  RESET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS: 'RESET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS',
  EDIT_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS: 'EDIT_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS',

  GET_BRAND_CURRENCY_FORMAT: 'GET_BRAND_CURRENCY_FORMAT',
  SAVE_BRAND_CURRENCY_FORMAT: 'SAVE_BRAND_CURRENCY_FORMAT',
  RESET_BRAND_CURRENCY_FORMAT: 'RESET_BRAND_CURRENCY_FORMAT',
  EDIT_BRAND_CURRENCY_FORMAT: 'EDIT_BRAND_CURRENCY_FORMAT',

  GET_BRAND_INACTIVITY_SETTINGS: 'GET_BRAND_INACTIVITY_SETTINGS',
  SAVE_BRAND_INACTIVITY_SETTINGS: 'SAVE_BRAND_INACTIVITY_SETTINGS',
  RESET_BRAND_INACTIVITY_SETTINGS: 'RESET_BRAND_INACTIVITY_SETTINGS',
  EDIT_BRAND_INACTIVITY_SETTINGS: 'EDIT_BRAND_INACTIVITY_SETTINGS',

  GET_BRAND_SPORT_STATUS_SETTINGS: 'GET_BRAND_SPORT_STATUS_SETTINGS',
  SAVE_BRAND_SPORT_STATUS_SETTINGS: 'SAVE_BRAND_SPORT_STATUS_SETTINGS',
  RESET_BRAND_SPORT_STATUS_SETTINGS: 'RESET_BRAND_SPORT_STATUS_SETTINGS',

  EDIT_BRAND_SPORT_STATUS_SETTINGS: 'EDIT_BRAND_SPORT_STATUS_SETTINGS',
};

export default BrandSettingActionType;
