const tableKeys = {
  playerAdjustments: 'playerAdjustments',
  bonusTemplates: 'bonusTemplates',
  promoCodes: 'promoCodes',
  bonuses: 'bonuses',
  sportBonus: 'sportBonus',
  casinoBonus: 'casinoBonus',
  spinBonus: 'spinBonus',
  specialBonus: 'specialBonus',
  playerSportBonus: 'playerSportBonus',
  playerCasinoBonus: 'playerCasinoBonus',
  playerSpinBonus: 'playerSpinBonus',
  playerSpecialBonus: 'playerSpecialBonus',
  playersReport: 'playersReport',
  cashBack: 'cashBack',
  playerCashBack: 'playerCashBack',
  testPlayers: 'testPlayers',
  testPlayerUsers: 'testPlayerUsers',
  similarAccounts: 'similarAccounts',
  playerSimilarAccounts: 'playerSimilarAccounts',
  referralManagement: 'referralManagement',
  users: 'users',
  playerReferralBonus: 'playerReferralBonus',
  userLogs: 'userLogs',
  playerLogs: 'playerLogs',
  userManagementLogs: 'userManagementLogs',
  playerLogsAccounts: 'playerLogsAccounts',
  financialWithdrawals: 'financialWithdrawals',
  financialDeposits: 'financialDeposits',
  playerFinancialDeposits: 'playerFinancialDeposits',
  playerFinancialWithdrawals: 'playerFinancialWithdrawals',
  userLogsAccounts: 'userLogsAccounts',
  closedAccounts: 'closedAccounts',
  playersTotals: 'playersTotals',
  casinoBets: 'casinoBets',
  sportBets: 'sportBets',
  playerCasinoBets: 'playerCasinoBets',
  playerSportBets: 'playerSportBets',
  transactions: 'transactions',
  transactionsByPlayer: 'transactionsByPlayer',
  transactionsByBrand: 'transactionsByBrand',
  negativeBalance: 'negativeBalance',
  brandAutomationRules: 'brandAutomationRules',
  balanceAdjustmentsReport: 'balanceAdjustmentsReport',
  playersWallets: 'playersWallets',
  betAggregationsByPlayer: 'betAggregationsByPlayer',
  betAggregationsByCurrency: 'betAggregationsByCurrency',
  kycReport: 'kycReport',
  financialAggregationsByPlayer: 'financialAggregationsByPlayer',
  financialAggregationsByCurrency: 'financialAggregationsByCurrency',
  cashierInventory: 'cashierInventory',
  gameManagementGames: 'gameManagementGames',
  gameManagementGameGroups: 'gameManagementGameGroups',
  brandPaymentMethods: 'brandPaymentMethods',
  playerLimitLogs: 'playerLimitLogs',
  reasons: 'reasons',
  campaigns: 'campaigns',
  campaignDepositStatistics: 'campaignDepositStatistics',
  campaignFailedDepositStatistics: 'campaignFailedDepositStatistics',
  campaignVerificationStatistics: 'campaignVerificationStatistics',
  campaignFailedVerificationStatistics: 'campaignFailedVerificationStatistics',
  campaignSignUpStatistics: 'campaignSignUpStatistics',
  campaignFailedSignUpStatistics: 'campaignFailedSignUpStatistics',
  campaignNoTriggerStatistics: 'campaignNoTriggerStatistics',
  campaignFailedNoTriggerStatistics: 'campaignFailedNoTriggerStatistics',
  externalSoftwareChanges: 'externalSoftwareChanges',
  externalGameRatio: 'externalGameRatio',
  externalPlayerExclusion: 'externalPlayerExclusion',
  externalGameTypes: 'externalGameTypes',
  externalBalanceAdjustment: 'externalBalanceAdjustment',
  externalSessions: 'externalSessions',
  externalSafeServer: 'externalSafeServer',
  externalMirroringServer: 'externalMirroringServer',
  externalJackPotWinRatio: 'externalJackPotWinRatio',
  externalJackPotConfiguration: 'externalJackPotConfiguration',
  externalPromotionalItems: 'externalPromotionalItems',
  externalSignificantEvents: 'externalSignificantEvents',
  externalServerInaccessibility: 'externalServerInaccessibility',
  externalLargeTransactions: 'externalLargeTransactions',
  externalRestrictions: 'externalRestrictions',
  previousAccountsGroups: 'previousAccountsGroups',
  previousAccounts: 'previousAccounts',
  playerPreviousAccounts: 'playerPreviousAccounts',
  lobbyProviders: 'lobbyProviders',
  crossPlatformCampaigns: 'crossPlatformCampaigns',
  promotions: 'promotions',
  promotionTransactions: 'promotionTransactions',
  promotionPrizes: 'promotionPrizes',
  promotionPlayers: 'promotionPlayers',
  changeNotification: 'changeNotification',
  promoSpinsAdjustments: 'promoSpinsAdjustments',
  exportHistory: 'exportHistory',
  playerSpins: 'playerSpins',
  playerTransactions: 'playerTransactions',
  promotionMachines: 'promotionMachines',
  providersByCalculatedBets: 'providersByCalculatedBets',
  providersByMixedBets: 'providersByMixedBets',
  firstDeposits: 'firstDeposits',
  taxationDaily: 'taxationDaily',
  taxationMonthly: 'taxationMonthly',
  bonusTotalsByPlayer: 'bonusTotalsByPlayer',
  bonusTotalsByType: 'bonusTotalsByType',
  bonusTotalsByStatus: 'bonusTotalsByStatus',
  singleBets: 'singleBets',
  bulkBets: 'bulkBets',
  bulkBetsStatistics: 'bulkBetsStatistics',
  inactivePlayers: 'inactivePlayers',
  promotionSpins: 'promotionSpins',
  promotionSpinStatistics: 'promotionSpinStatistics',
  topPlayersByGgr: 'topPlayersByGgr',
  topPlayersByDeposits: 'topPlayersByDeposits',
  topPlayersByWithdrawals: 'topPlayersByWithdrawals',
  releaseNotes: 'releaseNotes',
  gameTypes: 'gameTypes',
  campaignSignInStatistics: 'campaignSignInStatistics',
  campaignFailedSignInStatistics: 'campaignFailedSignInStatistics',
  bonusTotalsByProvider: 'bonusTotalsByProvider',
  applicationSessions: 'applicationSessions',
  webSessions: 'webSessions',
  promotionExchanges: 'promotionExchanges',
  promotionChangeLogs: 'promotionChangeLogs',
  lobbyGameGroups: 'lobbyGameGroups',
  sentMessages: 'sentMessages',
  scheduledMessages: 'scheduledMessages',
  draftMessages: 'draftMessages',
};

const settingKeys = {
  menuCollapse: 'menuCollapse',
  pageSize: 'pageSize',
  timeZone: 'timeZone',
  brandSettingsExpand: 'brandSettingsExpand',
  playerPerformance: 'playerPerformance',
  betAggregationsByPlayerCriteria: 'betAggregationsByPlayerCriteria',
  betAggregationsByCurrencyCriteria: 'betAggregationsByCurrencyCriteria',
};

const pageSizeKeys = {
  campaignsPageSize: 'campaignsPageSize',
  playersPageSize: 'playersPageSize',
};

const filterKeys = {
  playersFilter: 'playersFilter',
  depositsFilter: 'depositsFilter',
  withdrawalsFilter: 'withdrawalsFilter',
  casinoFilter: 'casinoFilter',
  sportFilter: 'sportFilter',
  playerCasinoFilter: 'playerCasinoFilter',
  playerSportFilter: 'playerSportFilter',
};

const tabKeys = {
  playerGeneralTab: 'playerGeneralTab',
  accountDetailsTab: 'accountDetailsTab',
  playerPaymentsTab: 'playerPaymentsTab',
  playerBetsTab: 'playerBetsTab',
  playerBonusesTab: 'playerBonusesTab',
  playerCommsTab: 'playerCommsTab',
  playerLimitsTab: 'playerLimitsTab',
  playerPromotionToolTab: 'playerPromotionToolTab',
  playerTagsTab: 'playerTagsTab',
  playerMonitoringTab: 'playerMonitoringTab',
  playerAttachmentTabs: 'playerAttachmentTabs',
  regulatoryTaxationTab: 'regulatoryTaxationTab',
  regulatorySignificantEventsTab: 'regulatorySignificantEventsTab',
  complianceLargeTransactionsTab: 'complianceLargeTransactionsTab',
  biReportsTabs: 'biReportsTabs',
  biBetAggregationsTab: 'biBetAggregationsTab',
  financialReportsTabs: 'financialReportsTabs',
  biFinancialAggregationsTab: 'biFinancialAggregationsTab',
  romanianReportsTabs: 'romanianReportsTabs',
  complianceReportsTabs: 'complianceReportsTabs',
  brandLocationTab: 'brandLocationTab',
  campaignsDepositStatisticsTabs: 'campaignsDepositStatisticsTabs',
  campaignsSignUpStatisticsTabs: 'campaignsSignUpStatisticsTabs',
  campaignsVerificationStatisticsTabs: 'campaignsVerificationStatisticsTabs',
  campaignsNoTriggerStatisticsTabs: 'campaignsNoTriggerStatisticsTabs',
  campaignsSignInStatisticsTabs: 'campaignsSignInStatisticsTabs',
  casinoProductTabs: 'casinoProductTabs',
  auditLogTabs: 'auditLogTabs',
  betStatusCorrectionsTabs: 'betStatusCorrectionsTabs',
  cashBackStatisticsTabs: 'cashBackStatisticsTabs',
  betTabs: 'betTabs',
  bonusReportsTabs: 'bonusReportsTabs',
  devToolTabs: 'devToolTabs',
  bonusesTabs: 'bonusesTabs',
  paymentMethodViewTabs: 'paymentMethodViewTabs',
  reasonsSystemTabs: 'reasonsSystemTabs',
  referralStatisticsTabs: 'referralStatisticsTabs',
  tagCategoriesTabs: 'tagCategoriesTabs',
  translationTabs: 'translationTabs',
  operatorLimitsTabs: 'operatorLimitsTabs',
  notificationTabs: 'notificationTabs',
  conversionTabs: 'conversionTabs',
  brandMainSettingsTabs: 'brandMainSettingsTabs',
  pushNotifications: 'pushNotifications',
  promotionStatisticsTabs: 'promotionStatisticsTabs',
  playerReportsTabs: 'playerReportsTabs',
  massMessagesTabs: 'massMessagesTabs',
  gameManagementTabs: 'gameManagementTabs',
  brandTaxesTabs: 'brandTaxesTabs',
  sportsBookTabs: 'sportsBookTabs',
  brandSeoSettingsTabs: 'brandSeoSettingsTabs',
  brandPaymentsTabs: 'brandPaymentsTabs',
  brandPaymentMethodTabs: 'brandPaymentMethodTabs',
  brandThirdPartyServicesTabs: 'brandThirdPartyServicesTabs',
  bulkActionsTabs: 'bulkActionsTabs',
  campaignsTabs: 'campaignsTabs',
  cashierTabs: 'cashierTabs',
  paymentMethodConfigurationTabs: 'paymentMethodConfigurationTabs',
  cypriotReportsTabs: 'cypriotReportsTabs',
  playerTagTabs: 'playerTagTabs',
  reportingTopPlayersTabs: 'reportingTopPlayersTabs',
  europeanSignificantEventsTabs: 'europeanSignificantEventsTabs',
  regulatoryTaxationTabs: 'regulatoryTaxationTabs',
  europeanReportsTabs: 'europeanReportsTabs',
  deviceSessionsTabs: 'deviceSessionsTabs',
  blockAccessTabs: 'blockAccessTabs',
  lobbyWidgetsTabs: 'lobbyWidgetsTabs',
};

export {
  tableKeys, settingKeys, pageSizeKeys, filterKeys, tabKeys,
};
