import enumTypes from 'constants/enumTypes';

const playerActions = [
  enumTypes.playerActions.BlockSportsbook,
  enumTypes.playerActions.UnblockSportsbook,
  enumTypes.playerActions.BlockCasino,
  enumTypes.playerActions.UnblockCasino,
  enumTypes.playerActions.BlockTransactions,
  enumTypes.playerActions.UnblockTransactions,
  enumTypes.playerActions.BlockWithdrawals,
  enumTypes.playerActions.UnblockWithdrawals,
  enumTypes.playerActions.BlockDeposits,
  enumTypes.playerActions.UnblockDeposits,
  enumTypes.playerActions.BlockMessages,
  enumTypes.playerActions.UnblockMessages,
  enumTypes.playerActions.BlockAccount,
  enumTypes.playerActions.UnblockAccount,
  enumTypes.playerActions.Unblock,
  enumTypes.playerActions.MarkAttention,
  enumTypes.playerActions.RemoveAttention,
  enumTypes.playerActions.Unlock,
  enumTypes.playerActions.AccountClosure,
  enumTypes.playerActions.Inactive,
  enumTypes.playerActions.Activate,
  enumTypes.playerActions.Lock,
  enumTypes.playerActions.Blacklist,
  enumTypes.playerActions.Whitelist,
  enumTypes.playerActions.VerifyEmail,
  enumTypes.playerActions.UnVerifyEmail,
  enumTypes.playerActions.VerifyIdentity,
  enumTypes.playerActions.UnVerifyIdentity,
  enumTypes.playerActions.VerifyMobile,
  enumTypes.playerActions.UnVerifyMobile,
  enumTypes.playerActions.VerifyAddress,
  enumTypes.playerActions.UnVerifyAddress,
  enumTypes.playerActions.VerifyCitizenship,
  enumTypes.playerActions.UnVerifyCitizenship,
  enumTypes.playerActions.CompletePhysicalVerification,
  enumTypes.playerActions.IncompletePhysicalVerification,
  enumTypes.playerActions.DisableLogin2FA,
  enumTypes.playerActions.EnableLogin2FA,
];

const cashbackTransferType = [enumTypes.cashbackTransferType.Manual, enumTypes.cashbackTransferType.Automatic];

const accountStatuses = [
  enumTypes.playerStatuses.Active,
  enumTypes.playerStatuses.BlockedForSport,
  enumTypes.playerStatuses.BlockedForCasino,
  enumTypes.playerStatuses.BlockedForWithdrawals,
  enumTypes.playerStatuses.BlockedForDeposits,
  enumTypes.playerStatuses.BlockedForTransactions,
  enumTypes.playerStatuses.Blocked,
];

const messageCategories = [
  enumTypes.messageCategories.Promotion,
  enumTypes.messageCategories.Newsletter,
  enumTypes.messageCategories.Notification,
  enumTypes.messageCategories.Bonus,
  enumTypes.messageCategories.Other,
];

const allDeviceTypes = [
  enumTypes.allDeviceTypes.Betshop,
  enumTypes.allDeviceTypes.WebDesktop,
  enumTypes.allDeviceTypes.WebMobile,
  enumTypes.allDeviceTypes.Wap,
  enumTypes.allDeviceTypes.Terminal,
  enumTypes.allDeviceTypes.Ios,
  enumTypes.allDeviceTypes.Android,
  enumTypes.allDeviceTypes.POS,
  enumTypes.allDeviceTypes.LiteBet,
  enumTypes.allDeviceTypes.WebOldMobile,
  enumTypes.allDeviceTypes.PwaApp,
  enumTypes.allDeviceTypes.Unknown,
];

const deviceTypes = [
  enumTypes.deviceTypes.WebDesktop,
  enumTypes.deviceTypes.WebMobile,
  enumTypes.deviceTypes.Ios,
  enumTypes.deviceTypes.Android,
  enumTypes.deviceTypes.PwaApp,
  enumTypes.deviceTypes.Other,
];

const bonuses = [enumTypes.bonuses.Casino, enumTypes.bonuses.Spin, enumTypes.bonuses.Sport, enumTypes.bonuses.Special];

const bonusTypes = {
  [enumTypes.bonuses.Casino]: [enumTypes.allBonusTypes.FreeBet, enumTypes.allBonusTypes.WagerStandard, enumTypes.allBonusTypes.WagerReal],
  [enumTypes.bonuses.Spin]: [enumTypes.allBonusTypes.FreeSpin, enumTypes.allBonusTypes.FreeAmount],
  [enumTypes.bonuses.Sport]: [enumTypes.allBonusTypes.SportFreeBet, enumTypes.allBonusTypes.SportWagering, enumTypes.allBonusTypes.SportRealWagering],
  [enumTypes.bonuses.Special]: [enumTypes.allBonusTypes.RealMoney],
};

const campaignTriggerTypes = [
  enumTypes.campaignTriggerTypes.NoTrigger,
  enumTypes.campaignTriggerTypes.SignUp,
  enumTypes.campaignTriggerTypes.SignIn,
  enumTypes.campaignTriggerTypes.Verify,
  enumTypes.campaignTriggerTypes.Deposit,
];

export {
  playerActions, cashbackTransferType, accountStatuses, messageCategories, allDeviceTypes, deviceTypes, bonuses, bonusTypes, campaignTriggerTypes,
};
