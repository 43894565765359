import _ from 'lodash';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MessageService } from 'services';
import { AppConstants } from 'constants/index';
import NavigationService from 'services/navigationService';

const Notification = (props) => {
  const { resetMessage, errorMessage, successMessage } = props;

  useEffect(() => {
    if (successMessage || errorMessage?.errors?.length) {
      if (AppConstants.redirectStatusCodes.includes(errorMessage.status)) {
        resetMessage();
        NavigationService('/noAccess');
      } else {
        if (_.isArray(errorMessage.errors) && errorMessage.errors.length) {
          errorMessage.errors.map((err) => MessageService.error({
            content: err.errorMessage,
            onClose: resetMessage,
          }));
        }

        if (successMessage) {
          MessageService.success({
            content: successMessage,
            onClose: resetMessage,
          });
        }
        resetMessage();
      }
    }
  }, [errorMessage, successMessage, resetMessage]);
};

Notification.propTypes = {
  resetMessage: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.object,
};

Notification.defaultProps = {
  resetMessage: () => {},
  successMessage: '',
  errorMessage: {},
};
export default Notification;
