import styled from 'styled-components';
import _ from 'lodash';
import { media, flexContainer } from 'utils/styledHelpers';

const StyledSvgIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path,
    circle {
      transition: fill 0.3s;
      fill: ${(props) => (_.isNull(props.color) && 'initial') || (props.color ?? props.theme.colors.secondaryColor)};
    }

    g {
      rect,
      path,
      circle {
        fill: ${(props) => (_.isNull(props.color) && 'initial') || (props.color ?? props.theme.colors.secondaryColor)};
      }
    }
  }
`;

const StyledSvgIconHover = styled.div`
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed!important' : 'pointer')};

  ${media.sm`
    display: ${(props) => (props.hidemobile ? 'none' : 'inline-block')};
  `}

  svg g path,
  svg path {
    fill: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : '')};
  }
  &:hover svg {
    path,
    circle {
      fill: ${(props) => (props.disabled ? '' : props.theme.colors.hover)};
    }

    g {
      path,
      circle {
        fill: ${(props) => (props.disabled ? '' : props.theme.colors.hover)};
      }
    }
  }
  &:hover {
    > a > div:last-of-type:not(${StyledSvgIcon}) {
      transition: background-color 0.3s;
      background-color: ${(props) => props.theme.colors.hover};
    }
  }
  ${(props) => props.isActive
    && `
    svg g path,
    svg path {
      fill: ${props.theme.colors.blue};
    }
  `}
`;

const StyledIconDefault = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  svg g path,
  svg path {
    fill: ${(props) => (props.isActive ? props.theme.colors.white : '')};
  }
  > div {
    padding: ${(props) => props.padding || '0'};
  }
  ${flexContainer({ align: 'center', justify: 'center', direction: 'row' })};
  align-self: ${(props) => (props.flexEnd ? 'flex-end' : 'initial')};
  background-color: transparent;
  border: ${(props) => (props.redBorder ? `2px solid ${props.theme.colors.danger}` : 'initial')};
  display: ${(props) => props.redBorder && 'inline-block'};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  justify-content: ${(props) => props.logo && 'center'};
  width: ${(props) => props.logo && '4.43rem'};
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'initial')};
  transition: 0.3s transform;
  & > div {
    display: flex;
  }
`;

const StyledIconFilter = styled(StyledIconDefault)`
  transform: ${(props) => (props.isExpand ? 'rotate(180deg)' : 'initial')};

  svg {
    width: 0.7rem;
    height: 0.9rem;
  }
`;
const StyledMoreIcon = styled.div`
  cursor: initial;
`;

const StyledIconsWrapper = styled.div`
  ${flexContainer({
    align: 'center',
    justify: 'space-between',
    direction: 'column',
  })};
  display: ${(props) => (props.isHidden ? 'none' : 'initial')};
  background-color: ${(props) => props.theme.colors.tableTrHoverColor};
  width: 2.14rem;
  border-radius: 15px;
  height: auto;
  padding: 0 0.42rem;
  position: relative;

  ${media.md`
    width: 3rem;
  `}
`;

const StyledIconHover = styled.div`
  ${flexContainer({ align: 'center', justify: 'center', direction: 'row' })};
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  & > div {
    ${flexContainer({ align: 'center', justify: 'center', direction: 'row' })};
  }
  &:last-child {
    border: none;
  }

  &:hover svg path,
  &:hover svg circle,
  &:hover svg g path,
  &:hover svg g circle {
    fill: ${(props) => props.theme.colors.hover};
  }
`;

const StyledIconHoverBrand = styled(StyledIconHover)`
  padding: 0.86rem 0;
  border-bottom: none;
`;

const StyledSelectIcon = styled(StyledIconDefault)`
  margin-right: 0;
`;

const StyledInfoIcon = styled(StyledIconDefault)`
  margin-right: 8px;
`;
const StyledStar = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed!important' : 'pointer')};
  div {
    pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  }
  &:hover {
    svg path {
      fill: ${(props) => (props.disabled ? '' : props.theme.colors.hover)};
    }
  }
`;
const StyledAction = styled(StyledStar)`
  position: relative;
  margin-left: auto;
  > a {
    display: block;
    width: 1.71rem;
    position: relative;
    > div:nth-child(2) {
      justify-content: flex-start;
    }
  }
`;

const StyledUnlinkedIcon = styled(StyledInfoIcon)`
  cursor: not-allowed;
  margin-right: 0;
`;

const StyledDeleteIcon = styled(StyledIconDefault)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding-right: ${(props) => (props.paddingRight ? '0.7rem' : '0')};
  svg g path,
  svg path {
    fill: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : '')};
  }
`;

const StyledUploadIcon = styled(StyledIconDefault)`
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  width: 100%;
  justify-content: ${(props) => (props.isTextCentred ? 'center' : 'flex-start')};
  align-items: center;
  border-color: transparent;
  & > div {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > span {
      line-height: 1.5rem;
    }
    & > span {
      &:first-child {
        display: block;
        font-size: 1rem;
      }
      color: ${(props) => props.theme.colors.filterLabelColor};
      font-size: 1rem;
    }
  }
  & > div:last-child {
    margin-left: 1.71rem;
  }
  > span {
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    margin-bottom: 1px;
  }
`;
const StyledUploadWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  padding: 1rem 1.71rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
`;

const StyledIconMenu = styled(StyledIconDefault)`
  margin-top: auto;
  background-color: ${(props) => props.theme.colors.grayButton};
  border-radius: initial;
  overflow: hidden;
  height: 2.85rem;
  min-height: 2.85rem;
  justify-content: flex-end;
  padding: 0 1.71rem;
  & > div {
    display: flex;
  }

  ${media.sm`
    display: none;
  `}
`;

const StyledCollapseMenuMobile = styled.div`
  display: none;
  position: fixed;
  width: 4.6rem;
  height: 3.43rem;
  padding: 0;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.tertiaryBg};
  border-right: 1px solid #4f5763;
  transition: 0.3s all;
  cursor: pointer;

  & > div {
    position: relative;
    width: 22px;
    height: 14px;

    &:before,
    &:after,
    span:before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 2px;
      width: 100%;
      background-color: #fff;
      border-radius: 3px;
      transition: 0.3s linear;
      transform-origin: center;
    }
    &:before {
      top: ${(props) => (props.collapsed ? '0' : '6px')};
      transform: ${(props) => (props.collapsed ? 'none' : 'rotate(45deg)')};
    }
    &:after {
      bottom: ${(props) => (props.collapsed ? '6px' : '0')};
      transform: ${(props) => (props.collapsed ? 'none' : 'rotate(-45deg)')};
      top: ${(props) => (props.collapsed ? 'unset' : '6px')};
    }
    span:before {
      width: 14px;
      bottom: 0;
      opacity: ${(props) => (props.collapsed ? '1' : '0')};
      left: ${(props) => (props.collapsed ? '0' : '-10px')};
    }
  }

  ${media.sm`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const StyledFlag = styled.div`
  margin-right: ${(props) => (props.isPreview ? '0.57rem' : '1.71rem')};
`;

const StyledActionsWrapper = styled.div`
  position: relative;
  display: inline-block;

  ${media.sm`
    &:hover > div:nth-child(2) {
      right: 85%;
      opacity: 1;
      pointer-events: auto;
    }
  `}
`;
const StyledActions = styled.div`
  position: relative;
  display: flex;
  gap: 1.45rem;
  align-items: center;
  justify-content: flex-end;

  ${media.sm`
    gap: 0.71rem;
    position: absolute;
    z-index: 10000;
    right: 70%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 6px;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    background: ${(props) => props.theme.colors.secondaryBg};
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    
    & > div {
      padding: 8px;
    }
  `}
`;

const StyledActionsToggle = styled.div`
  display: none;

  ${media.sm`
    display: block;
    padding: 2px 8px;
    cursor: pointer;
  `}
`;

const StyledFlagWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isRed || props.isOrange ? 'center' : 'flex-start')};
  align-items: center;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: ${(props) => (props.isRed || props.isOrange ? '0 1rem' : '')};
  background-color: ${(props) => (props.isRed && `${props.theme.colors.red}20`) || (props.isOrange ? `${props.theme.colors.yellow}20` : 'none')};
  color: ${(props) => (props.isRed && `${props.theme.colors.red}`) || (props.isOrange ? props.theme.colors.yellow : 0)};
  position: relative;
  //z-index: 1000;
  font-size: 1rem;
  height: 100%;
  & > div {
    margin-left: 0 !important;
  }
  > span {
    margin-right: 0.5rem;
    overflow: hidden;
  }
`;
const StyledLanguageName = styled.span`
  color: ${(props) => (props.isMain || props.isBlue ? `${props.theme.colors.blue}` : `${props.theme.colors.primaryText}`)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  text-transform: capitalize;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  max-width: ${(props) => (props.isMaxWidth ? '7rem' : 'unset')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLabelText = styled.div`
  margin-right: 0.57rem;
`;

const StyledCollapseIcon = styled.div`
  position: absolute;
  right: ${(props) => (props.right ? props.right : '0.57rem')};
  top: ${(props) => (props.top ? props.top : '0.57rem')};
  z-index: 11;
  width: 1.71rem;
  height: 1.71rem;
  background: ${(props) => props.theme.colors.secondaryBg};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.hover};
    }
  }
`;

const StyledIconWithText = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;

  svg:hover {
    cursor: pointer;
  }
`;

const StyledRowExpandIcon = styled.div`
  padding: 8px 0;
  min-width: 32px;
  cursor: pointer;
`;

export {
  StyledSvgIcon,
  StyledSvgIconHover,
  StyledIconDefault,
  StyledIconFilter,
  StyledSelectIcon,
  StyledIconMenu,
  StyledCollapseMenuMobile,
  StyledUploadIcon,
  StyledIconsWrapper,
  StyledIconHover,
  StyledIconHoverBrand,
  StyledInfoIcon,
  StyledUnlinkedIcon,
  StyledDeleteIcon,
  StyledActionsWrapper,
  StyledActions,
  StyledActionsToggle,
  StyledFlagWrapper,
  StyledAction,
  StyledFlag,
  StyledStar,
  StyledLanguageName,
  StyledLabelText,
  StyledUploadWrapper,
  StyledMoreIcon,
  StyledCollapseIcon,
  StyledIconWithText,
  StyledRowExpandIcon,
};
