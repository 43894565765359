export default {
  en: {
    messageId: 'Message ID',
    messageName: 'Message Name',
    lMessage: 'message',
    message: 'Message',
    sentDate: 'Sent Date',
    template: 'Template',
    lTemplate: 'template',
    viewMessage: 'View Message',
    conversationId: 'Conversation ID',
    conversationSubject: 'Conversation Subject',
    lastResponseFrom: 'Last Response From',
    noReply: 'No Reply',
    reply: 'Reply',
    seen: 'Seen',
    unSeen: 'Unseen',
    recall: 'Recall',
    channels: 'Channels',
    sentFrom: 'Sent From',
    scheduledFor: 'Scheduled For',
    moveToDraft: 'Move to draft',
    replyTo: 'Reply To',
    scheduledDate: 'Scheduled Date',
    noSubject: 'No Subject',
    chooseTemplate: 'Choose Template',
    chooseTemplateInfo:
      'Choose Template, \n  edit the code of your entire email, \n  and review content in the visual preview tab. \n    The code editor does not offer any drag & \n drop or WYSIWYG functionality.',
    customEditor: 'Custom Editor',
    customEditorInfo: 'Add HTML, edit the code of your entire email, and review \n content in the visual preview tab. The code editor does not \n offer any drag & drop or WYSIWYG functionality.',
    or: 'Or',
    messageNameInternal: 'Message Name(internal)',
    playersGettingSms: 'Players getting SMS',
    playersGettingEmail: 'Players getting Email',
    playersGettingInbox: ' Players getting Inbox',
    playersGettingMessageFromMultipleChannels: 'Players getting the message from multiple channels',
    subjectLine: 'Subject line',
    missingInfo: '{{title}} is missing',
    saveDraft: 'Save Draft',
    playersWithTransactionInfo: 'Players with transaction, account block and exclusion will not be considered',
    defaultLanguagesInfo: "Player's preferences are marked  with <0></0> symbol",
    inbox: 'Inbox',
    playerSelected: 'player(s) selected',
    startedBy: 'Started By',
    lastResponseDate: 'Last Response Date',
    playerChangedNotificationPreferences: 'Player changed notification preferences',
    notificationName: 'Notification Name',
    selectCategory: 'Select Category',
    areMissing: 'Subject line and Body are missing',
    isMissing: '{{title}} is missing',
    continue: 'Continue',
    hasPreference: 'These players prefer receiving a message via {{channelName}}.',
    onlyPreference: "These players won't receive the message if the channel is not configured.",
    seenBy: 'Seen By',
    seenDate: 'Seen Date',
    selectedTemplate: 'Selected template',
    symbols: 'Symbols',
    totalCountForDevices: 'Total count for Devices',
    androidMaxSymbolCounts: 'Android Max Symbol Counts - {{count}}',
    iosMaxSymbolCounts: 'iOS Max Symbol Counts - {{count}}',
    androidRecipients: 'Android Recipients',
    iosRecipients: 'iOS Recipients',
    androidGuestRecipients: 'Android Guest Recipients',
    iosGuestRecipients: 'iOS Guest Recipients',
    playersWithoutSpecifiedDevices: 'Players Without Specified Devices',
    lPushNotification: 'push notification',
    lNotification: 'notification',
    deviceTypes: 'Device Types',
    totalPlayersInfo: 'The total count of the unique players to which the message was sent',
    playersWithoutSpecifiedDevicesInfo: 'The players count who will not receive the message because of not having an active session in the selected device',
    pushNotificationsRecipients: 'Push Notifications Recipients',
    dynamic: 'Dynamic',
    msgPreview: 'Message Preview',
    msgPreviewDescription:
      'This preview provides a general idea of how your message will appear on a mobile device. Actual message rendering will vary depending on the device. Test with a real device for actual results.',
    initial: 'Initial',
    expanded: 'Expanded',
    apple: 'Apple',
    android: 'Android',
    recallPeriod: 'Recall Period',
    recallDate: 'Recall Date',
    sendFromRequired: 'Send From field is required',
    isInProcess: 'Is In Process',
  },
  es: {
    messageId: 'Id. del mensaje',
    messageName: 'Nombre del mensaje',
    lMessage: 'mensaje',
    message: 'Mensaje',
    sentDate: 'Fecha de envío',
    template: 'Plantilla',
    lTemplate: 'plantilla',
    viewMessage: 'Ver mensaje',
    conversationId: 'ID de la conversación',
    conversationSubject: 'Asunto de la conversación',
    lastResponseFrom: 'Última respuesta de',
    noReply: 'Sin respuesta',
    reply: 'Responder',
    seen: 'Visto',
    unSeen: 'No visto',
    recall: 'Retirar',
    channels: 'Canales',
    sentFrom: 'Enviado desde',
    scheduledFor: 'Programado para',
    moveToDraft: 'Pasar al borrador',
    replyTo: 'Responder a',
    scheduledDate: 'Fecha programada',
    noSubject: 'Sin asunto',
    chooseTemplate: 'Escoja plantilla',
    chooseTemplateInfo:
      'Elija la plantilla, \r\n  edite el código de todo su correo electrónico, \r\n  y revise el contenido en la pestaña de vista previa visual. \r\n    El editor de código no ofrece ninguna funcionalidad de arrastrar y \r\n soltar o WYSIWYG.',
    customEditor: 'Editor personalizado',
    customEditorInfo:
      'Añada el HTML, edite el código de todo su correo electrónico, y revise \r\n el contenido en la pestaña de vista previa visual. El editor de código \r\n no ofrece ninguna funcionalidad de arrastrar y  soltar o WYSIWYG.',
    or: 'O',
    messageNameInternal: 'Nombre(interno) del mensaje',
    playersGettingSms: 'Jugadores que reciben SMS',
    playersGettingEmail: 'Jugadores que reciben emails',
    playersGettingInbox: ' Jugadores que reciben bandeja de entrada',
    playersGettingMessageFromMultipleChannels: 'Jugadores que reciben el mensaje desde múltiples canales',
    subjectLine: 'Línea de asunto',
    missingInfo: 'Falta {{title}}',
    saveDraft: 'Guardar borrador',
    playersWithTransactionInfo: 'No se tendrán en cuenta los jugadores con transacción, bloqueo de cuenta y exclusión',
    defaultLanguagesInfo: 'Las preferencias del jugador están marcadas con el símbolo <0></0>',
    inbox: 'Bandeja de entrada',
    playerSelected: 'jugador(es) seleccionado(s)',
    startedBy: 'Iniciado por',
    lastResponseDate: 'Fecha de la última respuesta',
    playerChangedNotificationPreferences: 'El jugador ha cambiado las preferencias de notificación',
    notificationName: 'Nombre de notificación',
    selectCategory: 'Seleccione categoría',
    areMissing: 'Falta la línea de asunto y el cuerpo',
    isMissing: 'Falta {{title}}',
    continue: 'Continuar',
    hasPreference: 'Estos jugadores prefieren recibir un mensaje a través de {{channelName}}.',
    onlyPreference: 'Estos jugadores no recibirán el mensaje si el canal no está configurado.',
    seenBy: 'Visto por',
    seenDate: 'Fecha de la Visita',
    selectedTemplate: 'Plantilla Seleccionada',
    symbols: 'Símbolos',
    totalCountForDevices: 'Recuento total de Dispositivos',
    androidMaxSymbolCounts: 'Recuento Máximo de Símbolos en Android - {{count}}',
    iosMaxSymbolCounts: 'Recuento Máximo de Símbolos en iOS - {{count}}',
    androidRecipients: 'Destinatarios de Android',
    iosRecipients: 'Destinatarios de iOS',
    androidGuestRecipients: 'Destinatarios Invitados de Android',
    iosGuestRecipients: 'Destinatarios Invitados de iOS',
    playersWithoutSpecifiedDevices: 'Jugadores sin Dispositivos Especificados',
    lPushNotification: 'Notificación push',
    lNotification: 'Notificación',
    deviceTypes: 'Tipos de Dispositivos',
    totalPlayersInfo: 'El recuento total de jugadores únicos a los cuales se envió el mensaje',
    playersWithoutSpecifiedDevicesInfo: 'El recuento de jugadores que no recibirán el mensaje porque no tienen una sesión activa en el dispositivo seleccionado.',
    pushNotificationsRecipients: 'Destinatarios de Notificaciones Push',
  },
  tr: {
    messageId: 'Mesaj ID',
    messageName: 'Mesaj Adı',
    lMessage: 'mesaj',
    message: 'Mesaj',
    sentDate: 'Gönderilme Tarihi',
    template: 'Şablon',
    lTemplate: 'şablon',
    viewMessage: 'Mesajı Görüntüle',
    conversationId: 'Konuşma ID',
    conversationSubject: 'Konuşma Konusu',
    lastResponseFrom: 'Son Cevabı Gönderen',
    noReply: 'Cevap Yok',
    reply: 'Cevapla',
    seen: 'Görüldü',
    unSeen: 'Görülmedi',
    recall: 'Geri çağır',
    channels: 'Kanallar',
    sentFrom: 'Gönderen',
    scheduledFor: 'Planlanan Tarih:',
    moveToDraft: 'Taslağa taşı',
    replyTo: 'Yanıtla',
    scheduledDate: 'Planlanan Tarih',
    noSubject: 'Konu Yok',
    chooseTemplate: 'Şablon seçin',
    chooseTemplateInfo:
      'Şablon seçin, \r\n  bütün e-postanızın kodunu düzenleyin \r\n  ve görsel önizleme sekmesinde içeriği inceleyin. \r\n    Kod editörü, sürükle ve \r\n bırak veya WYSIWYG işlevlerini sunmaz.',
    customEditor: 'Özel Editör',
    customEditorInfo: 'HTML ekleyin, bütün e-postanızın kodunu düzenleyin ve \r\n görsel önizleme sekmesinde içeriği inceleyin. Kod editörü, sürükle ve \r\n bırak veya WYSIWYG işlevlerini sunmaz.',
    or: 'Veya',
    messageNameInternal: 'Mesaj Adı(dahili)',
    playersGettingSms: 'SMS alan oyuncular',
    playersGettingEmail: 'E-posta alan oyuncular',
    playersGettingInbox: ' Gelen kutusu alan oyuncular',
    playersGettingMessageFromMultipleChannels: 'Birden fazla kanaldan mesaj alan oyuncular',
    subjectLine: 'Konu satırı',
    missingInfo: '{{title}} eksik',
    saveDraft: 'Taslağı kaydet',
    playersWithTransactionInfo: 'İşlemi, hesap engeli veya kısıtlaması bulunan oyuncular göz önünde bulundurulmayacak',
    defaultLanguagesInfo: 'Oyuncuların tercihleri <0></0> sembolüyle işaretlenir',
    inbox: 'Gelen Kutusu',
    playerSelected: 'oyuncu seçildi',
    startedBy: 'Başlatan',
    lastResponseDate: 'Son Cevap Tarihi',
    playerChangedNotificationPreferences: 'Oyuncu bildirim tercihlerini değiştirdi',
    notificationName: 'Bildirim Adı',
    selectCategory: 'Kategori Seçin',
    areMissing: 'Konu satırı ve Gövde eksik',
    isMissing: '{{title}} eksik',
    continue: 'Devam Et',
    hasPreference: ' Bu oyuncular {{channelName}} yoluyla mesaj almayı tercih ediyor.',
    onlyPreference: 'Kanal yapılandırılmazsa bu oyuncular mesaj almayacak.',
    seenBy: 'Tarafından Görüldü',
    seenDate: 'Görülme Tarihi',
    selectedTemplate: 'Seçili şablon',
    symbols: 'Semboller',
    totalCountForDevices: 'Cihazlar için toplam sayı',
    androidMaxSymbolCounts: 'Android Maksimum Sembol Sayısı - {{count}}',
    iosMaxSymbolCounts: 'iOS Maksimum Sembol Sayısı - {{count}}',
    androidRecipients: 'Android Alıcıları',
    iosRecipients: 'iOS Alıcıları',
    androidGuestRecipients: 'Android Misafir Alıcıları',
    iosGuestRecipients: 'iOS Misafir Alıcıları',
    playersWithoutSpecifiedDevices: 'Belirtilmiş Cihazları Olmayan Oyuncular',
    lPushNotification: 'anlık bildirim',
    lNotification: 'bildirim',
    deviceTypes: 'Cihaz Türleri',
    totalPlayersInfo: 'Mesajın gönderildiği özel oyuncuların toplam sayısı',
    playersWithoutSpecifiedDevicesInfo: 'Seçilen cihazda aktif bir oturumu olmadığı için mesajı alamayacak oyuncu sayısı',
    pushNotificationsRecipients: 'Anlık Bildirim Alıcıları',
  },
  ru: {
    messageId: 'ID сообщения',
    messageName: 'Название сообщения',
    lMessage: 'сообщение',
    message: 'Сообщение',
    sentDate: 'Дата отправки',
    template: 'Шаблон',
    lTemplate: 'шаблон',
    viewMessage: 'Просмотреть сообщение',
    conversationId: 'ID разговора',
    conversationSubject: 'Тема разговора',
    lastResponseFrom: 'Последний ответ от',
    noReply: 'Нет ответа',
    reply: 'Ответ',
    seen: 'Просмотрено',
    unSeen: 'Не просмотрено',
    recall: 'Отзыв',
    channels: 'Каналы',
    sentFrom: 'Отправлено с',
    scheduledFor: 'Запланировано на',
    moveToDraft: 'Переместить в черновики',
    replyTo: 'Ответить',
    scheduledDate: 'Запланированная дата',
    noSubject: 'Без темы',
    chooseTemplate: 'Выбрать шаблон',
    chooseTemplateInfo:
      'Выбрать шаблон, \r\n отредактировать код всего электронного письма \r\n и просмотреть содержимое во вкладке предварительного визуального просмотра. \r\n Функция \r\n перетаскивания и режим WYSIWYG в редакторе кода не предусмотрены.',
    customEditor: 'Специализированный редактор',
    customEditorInfo:
      'Добавить HTML, редактировать код всего электронного письма и просмотреть \r\n содержимое во вкладке визуального предварительного просмотра. Функция перетаскивания и режим WYSIWYG в редакторе кода не \r\n предусмотрены.',
    or: 'Или',
    messageNameInternal: 'Название сообщения(внутреннее)',
    playersGettingSms: 'Игроки получают SMS',
    playersGettingEmail: 'Игроки получают электронные письма',
    playersGettingInbox: ' Игроки получают письма во «Входящие»',
    playersGettingMessageFromMultipleChannels: 'Игроки получают сообщения из различных каналов',
    subjectLine: 'Тема',
    missingInfo: '‎{{title}} отсутствует',
    saveDraft: 'Сохранить черновик',
    playersWithTransactionInfo: 'Игроки с заблокированными транзакциями, учетными записями или исключением не будут рассматриваться',
    defaultLanguagesInfo: 'Предпочтения игрока помечены символом <0></0>',
    inbox: 'Входящие',
    playerSelected: 'выбранный(-е) игрок(-и)',
    startedBy: 'Начато (кем)',
    lastResponseDate: 'Дата последнего ответа',
    playerChangedNotificationPreferences: 'Игрок изменил параметры уведомлений',
    notificationName: 'Название уведомления',
    selectCategory: 'Выбрать категорию',
    areMissing: 'Тема и основной текст отсутствуют',
    isMissing: '‎{{title}} отсутствует',
    continue: 'Продолжить',
    hasPreference: 'Эти игроки предпочитают получать сообщения через {{channelName}}.',
    onlyPreference: 'Эти игроки не получат сообщение, если канал не настроен.',
    seenBy: 'Просмотрено',
    seenDate: 'Дата просмотра',
    selectedTemplate: 'Выбранный шаблон',
    symbols: 'Символы',
    totalCountForDevices: 'Общее количество для устройств',
    androidMaxSymbolCounts: 'Максимальное количество символов Android — {{count}}',
    iosMaxSymbolCounts: 'Максимальное количество символов iOS — {{count}}',
    androidRecipients: 'Получатели Android',
    iosRecipients: 'Получатели iOS',
    androidGuestRecipients: 'Гостевые получатели Android',
    iosGuestRecipients: 'Гостевые получатели iOS',
    playersWithoutSpecifiedDevices: 'Игроки без указанных устройств',
    lPushNotification: 'push-уведомление',
    lNotification: 'уведомление',
    deviceTypes: 'Типы устройств',
    totalPlayersInfo: 'Общее количество уникальных игроков, которым было отправлено сообщение',
    playersWithoutSpecifiedDevicesInfo: 'Количество игроков, которые не получат сообщение из-за отсутствия активной сессии на выбранном устройстве',
    pushNotificationsRecipients: 'Получатели Push-уведомлений',
  },
  pt: {
    messageId: 'ID da Mensagem',
    messageName: 'Nome da Mensagem',
    lMessage: 'mensagem',
    message: 'Mensagem',
    sentDate: 'Data de Envio',
    template: 'Modelo',
    lTemplate: 'modelo',
    viewMessage: 'Ver Messagem',
    conversationId: 'ID da Conversa',
    conversationSubject: 'Assunto da Conversa',
    lastResponseFrom: 'Última Resposta de',
    noReply: 'Sem Resposta',
    reply: 'Responder',
    seen: 'Visto',
    unSeen: 'Não Visto',
    recall: 'Recuperar',
    channels: 'Canais',
    sentFrom: 'Enviado de',
    scheduledFor: 'Agendado para',
    moveToDraft: 'Mover para rascunho',
    replyTo: 'Responder para',
    scheduledDate: 'Data de Agendamento',
    noSubject: 'Sem Assunto',
    chooseTemplate: 'Escolha o Modelo',
    chooseTemplateInfo:
      'Escolha o Modelo, \n  edite o código de todo o seu e-mail, \n  e revise o conteúdo na aba de visualização prévia. \n    O editor de código não oferece nenhuma funcionalidade de arrastar e \n soltar ou WYSIWYG.',
    customEditor: 'Editor Personalizado',
    customEditorInfo:
      'Adicione HTML, edite o código de todo o seu e-mail e revise o conteúdo na aba de visualização prévia. O editor de código não \n oferece nenhuma funcionalidade de arrastar e soltar ou WYSIWYG.',
    or: 'Ou',
    messageNameInternal: 'Nome da Mensagem(interno)',
    playersGettingSms: 'Jogadores recebendo SMS',
    playersGettingEmail: 'Jogadores recebendo E-mail',
    playersGettingInbox: ' Jogadores recebendo Caixa de entrada',
    playersGettingMessageFromMultipleChannels: 'Jogadores recebendo a mensagem de múltiplos canais',
    subjectLine: 'Linha de assunto',
    missingInfo: '{{title}} está faltando',
    saveDraft: 'Salvar Rascunho',
    playersWithTransactionInfo: 'Jogadores com transação, bloqueio de conta e exclusão não serão considerados',
    defaultLanguagesInfo: 'As preferências do jogador são marcadas com o símbolo <0></0>',
    inbox: 'Caixa de entrada',
    playerSelected: 'jogador(es) selecionado(s)',
    startedBy: 'Iniciado por',
    lastResponseDate: 'Data da Última Resposta',
    playerChangedNotificationPreferences: 'O jogador mudou as preferências de notificação',
    notificationName: 'Nome da Notificação',
    selectCategory: 'Selecione Categoria',
    areMissing: 'Faltando Linha de assunto e corpo',
    isMissing: '{{title}} está faltando',
    continue: 'Continuar',
    hasPreference: 'Esses jogadores preferem receber uma mensagem via {{channelName}}.',
    onlyPreference: 'Esses jogadores não receberão a mensagem se o canal não estiver configurado.',
    seenBy: 'Visto por',
    seenDate: 'Data de visualização',
    selectedTemplate: 'Modelo selecionado',
    symbols: 'Símbolos',
    totalCountForDevices: 'Contagem total para Dispositivos',
    androidMaxSymbolCounts: 'Contagem máxima de símbolos para Android - {{contagem}}',
    iosMaxSymbolCounts: 'Contagem máxima de símbolos no iOS - {{contagem}}',
    androidRecipients: 'Destinatários Android',
    iosRecipients: 'Destinatários iOS',
    androidGuestRecipients: 'Destinatários convidados Android',
    iosGuestRecipients: 'Destinatários convidados iOS',
    playersWithoutSpecifiedDevices: 'Jogadores sem dispositivos especificados',
    lPushNotification: 'notificação push',
    lNotification: 'notificação',
    deviceTypes: 'Tipos de dispositivos',
    totalPlayersInfo: 'A contagem total dos jogadores únicos para os quais a mensagem foi enviada',
    playersWithoutSpecifiedDevicesInfo: 'O número de jogadores que não receberão a mensagem por não terem uma sessão ativa no dispositivo selecionado',
    pushNotificationsRecipients: 'Destinatários das notificações push',
  },
  zh: {
    messageId: '信息ID',
    messageName: '信息名称',
    lMessage: '信息',
    message: '信息',
    sentDate: '发送日期',
    template: '模板',
    lTemplate: '模板',
    viewMessage: '查看信息',
    conversationId: '对话ID',
    conversationSubject: '对话主题',
    lastResponseFrom: '最近回复来自',
    noReply: '无回复',
    reply: '回复',
    seen: '查看的',
    unSeen: '未查看的',
    recall: '撤回',
    channels: '频道',
    sentFrom: '发送自',
    scheduledFor: '发送时间定于',
    moveToDraft: '移至草稿',
    replyTo: '回复',
    scheduledDate: '计划日期',
    noSubject: '无主题',
    chooseTemplate: '选择模板',
    chooseTemplateInfo: '选择模板，\n 编辑您整封电子邮件的代码，\n 并在视觉预览选项卡中审查内容。\n 代码编辑器不提供任何拖放\n 或所见即所得（WYSIWYG ）功能。',
    customEditor: '自定义编辑器',
    customEditorInfo: '添加HTML，编辑您整封电子邮件的代码，并在视觉预览选项卡中审查\n 内容。代码编辑器不\n 提供任何拖放或所见即所得（WYSIWYG ）功能。',
    or: '或',
    messageNameInternal: '信息名称（内部）',
    playersGettingSms: '玩家获取短信',
    playersGettingEmail: '玩家获取电子邮件',
    playersGettingInbox: ' 玩家获取收件箱',
    playersGettingMessageFromMultipleChannels: '玩家从多个频道获取信息',
    subjectLine: '主题栏',
    missingInfo: '{{title}} 丢失',
    saveDraft: '保存草稿',
    playersWithTransactionInfo: '如果玩家有交易、账户被封和被排除的情况，则恕不接受此类玩家',
    defaultLanguagesInfo: '玩家的偏好被标记为<0></0>符号',
    inbox: '收件箱',
    playerSelected: '已选中的玩家',
    startedBy: '开始于',
    lastResponseDate: '最近回复日期',
    playerChangedNotificationPreferences: '玩家更改了通知偏好',
    notificationName: '通知名称',
    selectCategory: '选择类别',
    areMissing: '主题栏和正文部分丢失',
    isMissing: '{{title}} 丢失',
    continue: '继续',
    hasPreference: '这些玩家喜欢通过 {{channelName}} 频道接收信息。',
    onlyPreference: '如果频道没有配置好，这些玩家就不会收到信息。',
    seenBy: '被查看',
    seenDate: '被查看日期',
    selectedTemplate: '所选模板',
    symbols: '符号',
    totalCountForDevices: '设备总数',
    androidMaxSymbolCounts: '安卓最大符号计数 - {{count}}',
    iosMaxSymbolCounts: 'iOS最大符号计数 - {{count}}',
    androidRecipients: '安卓接收者',
    iosRecipients: 'iOS接收者',
    androidGuestRecipients: '安卓访客接收者',
    iosGuestRecipients: 'iOS访客接收者',
    playersWithoutSpecifiedDevices: '无指定设备的玩家',
    lPushNotification: '推送通知',
    lNotification: '通知',
    deviceTypes: '设备类型',
    totalPlayersInfo: '发送信息的独特玩家总数',
    playersWithoutSpecifiedDevicesInfo: '由于所选设备中没有活跃会期而无法收到信息的玩家人数',
    pushNotificationsRecipients: '推送通知接收者',
  },
  ro: {
    messageId: 'ID-ul de mesaj',
    messageName: 'Numele de mesaj',
    lMessage: 'mesaj',
    message: 'Mesaj',
    sentDate: 'Data trimiterii',
    template: 'Șablon',
    lTemplate: 'șablon',
    viewMessage: 'Vizualizează mesajul',
    conversationId: 'ID-ul de conversație',
    conversationSubject: 'Subiect de conversație',
    lastResponseFrom: 'Ultimul răspuns de la',
    noReply: 'Niciun răspuns',
    reply: 'Răspunde',
    seen: 'Văzut',
    unSeen: 'Nevăzut',
    recall: 'Rechemat',
    channels: 'Canale',
    sentFrom: 'Trimis de la',
    scheduledFor: 'Programat pentru',
    moveToDraft: 'Muta la schiță',
    replyTo: 'Răspunde la',
    scheduledDate: 'Dată programată',
    noSubject: 'Nici un subiect',
    chooseTemplate: 'Alege un șablon',
    chooseTemplateInfo:
      'Alege un șablon, \n edită-ți codul întregului e-mail, \n și examinează conținutul din fila de previzualizare vizuală. \n Editorul de cod nu oferă nici o funcție de drag & \n drop sau WYSIWYG.',
    customEditor: 'Editor personalizat (specializat)',
    customEditorInfo:
      'Adaugă HTML, editează-ți codul întregului e-mail și examinează-ți \n conținutul din fila previzualizării vizuale. Editorul de cod \n nu oferă nicio funcție de drag & drop sau WYSIWYG.',
    or: 'Sau',
    messageNameInternal: 'Numele de mesaj (intern)',
    playersGettingSms: 'Jucătorii care primesc SMS',
    playersGettingEmail: 'Jucătorii primesc E-mail',
    playersGettingInbox: 'Jucătorii primesc care e-mailuri în căsuța inbox',
    playersGettingMessageFromMultipleChannels: 'Jucătorii primesc mesaj de pe mai multe canale',
    subjectLine: 'Linia de subiect',
    missingInfo: '{{title}} lipsește',
    saveDraft: 'Salva schița',
    playersWithTransactionInfo: 'Jucătorii cu tranzacție, blocarea de cont și excluderea nu vor fi luați în considerare',
    defaultLanguagesInfo: 'Preferințele jucătorilor sunt marcate cu simbolul <0></0>',
    inbox: 'Inbox',
    playerSelected: 'jucător(jucători) selectat(ți)',
    startedBy: 'Început de',
    lastResponseDate: 'Data de ultimul răspuns',
    playerChangedNotificationPreferences: 'Jucătorul a schimbat preferințele de notificare',
    notificationName: 'Numele de notificare',
    selectCategory: 'Selecta categoria',
    areMissing: 'Subiectul și corpul lipsesc',
    isMissing: '{{title}} lipsește',
    continue: 'Continuă',
    hasPreference: 'Acești jucători preferă să primească un mesaj prin intermediul {{channelName}}.',
    onlyPreference: 'Acești jucători nu vor primi mesajul dacă canalul nu este configurat.',
    seenBy: 'Văzut de',
    seenDate: 'Data văzută',
  },
  bg: {},
  el: {
    messageId: 'Αναγνωριστικό μηνύματος',
    messageName: 'Όνομα μηνύματος',
    lMessage: 'μήνυμα',
    message: 'Μήνυμα',
    sentDate: 'Ημερομηνία αποστολής',
    template: 'Πρότυπο',
    lTemplate: 'πρότυπο',
    viewMessage: 'Προβολή μηνύματος',
    conversationId: 'Αναγνωριστικό συζήτησης',
    conversationSubject: 'Θέμα συζήτησης',
    lastResponseFrom: 'Τελευταία απάντηση από',
    noReply: 'Χωρίς απάντηση',
    reply: 'Απάντηση',
    seen: 'Διαβάστηκε',
    unSeen: 'Δεν διαβάστηκε',
    recall: 'Ανάκληση',
    channels: 'Κανάλια',
    sentFrom: 'Αποστολή από',
    scheduledFor: 'Προγραμματισμένο για',
    moveToDraft: 'Μετακίνηση στο πρόχειρο',
    replyTo: 'Απάντηση σε',
    scheduledDate: 'Προγραμματισμένη ημερομηνία',
    noSubject: 'Χωρίς θέμα',
    chooseTemplate: 'Επιλογή προτύπου',
    chooseTemplateInfo:
      'Επιλογή προτύπου, \r\n  επεξεργασία κώδικα ολόκληρου του email, \r\n  και επισκόπηση του περιεχομένου στην καρτέλα οπτικής προεπισκόπησης \r\n    Η επεξεργασία κώδικα δεν παρέχει μεταφορά και \r\nαπόθεση ή λειτουργία WYSIWYG.',
    customEditor: 'Προσαρμοσμένη επεξεργασία',
    customEditorInfo:
      'Προσθήκη HTML, επεξεργασία κώδικα ολόκληρου του email και επισκόπηση \r\n περιεχομένου στην καρτέλα οπτικής προεπισκόπησης. Η επεξεργασία κώδικα δεν \r\nπαρέχει μεταφορά και απόθεση ή λειτουργία WYSIWYG.',
    or: 'Ή',
    messageNameInternal: 'Όνομα μηνύματος (εσωτερικό)',
    playersGettingSms: 'Παίκτες που λαμβάνουν SMS',
    playersGettingEmail: 'Παίκτες που λαμβάνουν email',
    playersGettingInbox: 'Παίκτες που λαμβάνουν μηνύματα στα Εισερχόμενα',
    playersGettingMessageFromMultipleChannels: 'Παίκτες που λαμβάνουν το μήνυμα από πολλαπλά κανάλια',
    subjectLine: 'Γραμμή θέματος',
    missingInfo: 'Λείπει {{title}}',
    saveDraft: 'Αποθήκευση προχείρου',
    playersWithTransactionInfo: 'Οι παίκτες με αποκλεισμό συναλλαγών και λογαριασμού, καθώς και εκείνοι που βρίσκονται σε καθεστώς γενικού αποκλεισμού δεν θα λαμβάνονται υπόψη',
    defaultLanguagesInfo: 'Οι προτιμήσεις του παίκτη επισημαίνονται με το σύμβολο <0></0>',
    inbox: 'Εισερχόμενα',
    playerSelected: 'παίκτης(-ες) επιλέχθηκε(-αν)',
    startedBy: 'Ξεκίνησε από',
    lastResponseDate: 'Ημερομηνία τελευταίας απάντησης',
    playerChangedNotificationPreferences: 'Ο παίκτης άλλαξε τις προτιμήσεις ειδοποιήσεων',
    notificationName: 'Όνομα ειδοποίησης',
    selectCategory: 'Επιλογή κατηγορίας',
    areMissing: 'Λείπουν η γραμμή θέματος και το σώμα κειμένου',
    isMissing: 'Λείπει {{title}}',
    continue: 'Συνέχεια',
    hasPreference: 'Αυτοί οι παίκτες προτιμούν να λαμβάνουν μήνυμα μέσω {{channelName}}.',
    onlyPreference: 'Αυτοί οι παίκτες δεν θα λάβουν το μήνυμα αν το κανάλι δεν έχει διαμορφωθεί.',
    seenBy: 'Διαβάστηκε από',
    seenDate: 'Ημερομηνία που διαβάστηκε',
    selectedTemplate: 'Επιλεγμένο πρότυπο',
    symbols: 'Σύμβολα',
    totalCountForDevices: 'Συνολικός αριθμός συσκευών',
    androidMaxSymbolCounts: 'Μέγιστος αριθμός συμβόλων Android - {{count}}',
    iosMaxSymbolCounts: 'Μέγιστος αριθμός συμβόλων iOS - {{count}}',
    androidRecipients: 'Αποδέκτες Android',
    iosRecipients: 'Αποδέκτες iOS',
    androidGuestRecipients: 'Επισκέπτες αποδέκτες Android',
    iosGuestRecipients: 'Επισκέπτες αποδέκτες iOS',
    playersWithoutSpecifiedDevices: 'Παίκτες χωρίς προσδιορισμένες συσκευές',
    lPushNotification: 'ειδοποίηση push',
    lNotification: 'ειδοποίηση',
    deviceTypes: 'Τύποι συσκευής',
    totalPlayersInfo: 'Ο συνολικός αριθμός μοναδικών παικτών στους οποίους στάλθηκε το μήνυμα',
    playersWithoutSpecifiedDevicesInfo: 'Ο αριθμός παικτών που θα λάβουν το μήνυμα επειδή δεν έχουν ενεργή συνεδρία στην επιλεγμένη συσκευή',
  },
};
