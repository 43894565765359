import { combineReducers } from 'redux';
import SettingActionType from 'actions/setting/settingActionType';

import player from './playerReducer';
import auth from './authReducer';
import settings from './settingReducer';
import brands from './brandReducer';
import brandSetting from './brandSettingReducer';
import partners from './partnerReducer';
import users from './userReducer';
import permission from './permissionReducer';
import permissionGroup from './permissionGroupReducer';
import staticCollection from './staticCollectionReducer';
import translation from './translationReducer';
import notification from './notificationReducer';
import template from './templateReducer';
import fileManager from './fileManagerReducer';
import game from './gameReducer';
import promoCode from './promoCodeReducer';
import bonus from './bonusReducer';
import thirdPartyServices from './thirdPartyServicesReducer';
import cashBack from './cashBackReducer';
import quickSearch from './quickSearchReducer';
import referral from './referralReducer';
import blacklist from './blacklistReducer';
import tag from './tagReducer';
import auditLog from './auditLogReducer';
import payment from './paymentReducer';
import brandPayment from './brandPaymentReducer';
import reporting from './reportingReducer';
import bet from './betReducer';
import operatorLimit from './operatorLimitReducer';
import ipWhitelist from './ipWhitelistReducer';
import legalAgreement from './legalAgreementReducer';
import brandAutomation from './brandAutomationReducer';
import accountUpdate from './accountUpdateReducer';
import kyc from './kycReducer';
import cashier from './cashierReducer';
import gameSegment from './gameSegmentReducer';
import playerSegment from './playerSegmentReducer';
import playerPerformance from './playerPerformanceReducer';
import locale from './localeReducer';
import dashboard from './dashboardReducer';
import seoSetting from './seoSettingReducer';
import systemConversion from './systemConversionReducer';
import gameManagement from './gameManagementReducer';
import similarAccount from './similarAccountReducer';
import blockedProvider from './blockedProviderReducer';
import paymentMethod from './paymentMethodReducer';
import overview from './overviewReducer';
import resource from './resourceReducer';
import campaign from './campaignReducer';
import previousAccount from './previousAccountReducer';
import reasons from './reasonsReducer';
import taxes from './taxesReducer';
import agentSystem from './agentSystemReducer';
import devTool from './devToolReducer';
import lobbyManagement from './lobbyManagementReducer';
import promotion from './promotionReducer';
import brandMarket from './brandMarketReducer';
import exportCenter from './exportCenterReducer';
import pushNotification from './pushNotificationReducer';
import playerWallet from './playerWalletReducer';
import regulatory from './regulatoryReducer';
import betStatusCorrection from './betStatusCorrectionReducer';
import releaseNote from './releaseNoteReducer';
import playerSession from './playerSessionReducer';

const appReducer = combineReducers({
  auth,
  player,
  settings,
  brands,
  brandSetting,
  brandMarket,
  partners,
  users,
  permission,
  permissionGroup,
  staticCollection,
  translation,
  notification,
  template,
  fileManager,
  game,
  promoCode,
  bonus,
  thirdPartyServices,
  cashBack,
  quickSearch,
  referral,
  blacklist,
  tag,
  auditLog,
  payment,
  brandPayment,
  reporting,
  bet,
  operatorLimit,
  ipWhitelist,
  legalAgreement,
  brandAutomation,
  accountUpdate,
  kyc,
  cashier,
  gameSegment,
  playerSegment,
  playerPerformance,
  dashboard,
  seoSetting,
  locale,
  systemConversion,
  gameManagement,
  similarAccount,
  blockedProvider,
  paymentMethod,
  overview,
  resource,
  previousAccount,
  reasons,
  campaign,
  taxes,
  agentSystem,
  devTool,
  lobbyManagement,
  promotion,
  exportCenter,
  pushNotification,
  playerWallet,
  regulatory,
  betStatusCorrection,
  releaseNote,
  playerSession,
});

export default (state, action) => {
  if (action.type === SettingActionType.RESET_STATE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
