import LobbyManagementActionType from './lobbyManagementActionType';

export const getLobbies = (params) => ({
  type: LobbyManagementActionType.GET_LOBBIES,
  payload: { params },
});

export const saveLobbies = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBIES,
  payload: { data },
});

export const resetLobbies = () => ({
  type: LobbyManagementActionType.RESET_LOBBIES,
});

export const getLobby = (id, params) => ({
  type: LobbyManagementActionType.GET_LOBBY,
  payload: { id, params },
});

export const saveLobby = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY,
  payload: { data },
});

export const resetLobby = () => ({
  type: LobbyManagementActionType.RESET_LOBBY,
});

export const editLobby = (id, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY,
  payload: { id, data, name },
});

export const changeLobbyStatus = (id, params, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_STATUS,
  payload: { id, params, name },
});

export const getLobbyProviders = (id, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDERS,
  payload: { id, params },
});

export const saveLobbyProviders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDERS,
  payload: { data },
});

export const resetLobbyProviders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDERS,
});

export const changeLobbyProviderStatus = (lobbyId, providerId, params, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_PROVIDER_STATUS,
  payload: {
    lobbyId,
    providerId,
    params,
    name,
  },
});

export const editLobbyProvider = (lobbyId, providerId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDER,
  payload: {
    lobbyId,
    providerId,
    data,
    name,
  },
});

export const getLobbyProvider = (lobbyId, providerId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDER,
  payload: { lobbyId, providerId, params },
});

export const saveLobbyProvider = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDER,
  payload: { data },
});

export const resetLobbyProvider = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDER,
});

export const getLobbyProvidersOrders = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDERS_ORDERS,
  payload: { lobbyId, params },
});

export const saveLobbyProvidersOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDERS_ORDERS,
  payload: { data },
});

export const resetLobbyProvidersOrders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDERS_ORDERS,
});

export const editLobbyProvidersOrders = (lobbyId, params, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDERS_ORDERS,
  payload: {
    lobbyId,
    params,
    data,
    name,
  },
});

export const getCsvLobbyProvidersOrders = (lobbyId, data) => ({
  type: LobbyManagementActionType.GET_CSV_LOBBY_PROVIDERS_ORDERS,
  payload: { lobbyId, data },
});

export const saveCsvLobbyProvidersOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDERS_ORDERS,
  payload: { data },
});

export const resetCsvLobbyProvidersOrders = () => ({
  type: LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDERS_ORDERS,
});

export const exportLobbyProviders = (lobbyId, params) => ({
  type: LobbyManagementActionType.EXPORT_LOBBY_PROVIDERS,
  payload: { lobbyId, params },
});

export const getLobbyProviderGamesOrders = (lobbyId, providerId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { lobbyId, providerId, params },
});

export const saveLobbyProviderGamesOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { data },
});

export const resetLobbyProviderGamesOrders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDER_GAMES_ORDERS,
});

export const editLobbyProviderGamesOrders = (lobbyId, providerId, params, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: {
    lobbyId,
    params,
    data,
    name,
    providerId,
  },
});

export const getCsvLobbyProviderGamesOrders = (lobbyId, providerId, data) => ({
  type: LobbyManagementActionType.GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { lobbyId, providerId, data },
});

export const saveCsvLobbyProviderGamesOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { data },
});

export const resetCsvLobbyProviderGamesOrders = () => ({
  type: LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
});

export const getLobbyLogs = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_LOGS,
  payload: { lobbyId, params },
});

export const saveLobbyLogs = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_LOGS,
  payload: { data },
});

export const resetLobbyLogs = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_LOGS,
});

export const getLobbyGameGroups = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GAME_GROUPS,
  payload: { lobbyId, params },
});

export const saveLobbyGameGroups = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GAME_GROUPS,
  payload: { data },
});

export const resetLobbyGameGroups = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GAME_GROUPS,
});

export const reorderLobbyGameGroup = (lobbyId, groupId, data) => ({
  type: LobbyManagementActionType.REORDER_LOBBY_GAME_GROUP,
  payload: { lobbyId, groupId, data },
});

export const changeLobbyGameGroupStatus = (lobbyId, groupId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_GAME_GROUP_STATUS,
  payload: {
    lobbyId,
    groupId,
    data,
    name,
  },
});

export const getLobbyGameGroup = (lobbyId, groupId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GAME_GROUP,
  payload: { lobbyId, params, groupId },
});

export const saveLobbyGameGroup = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GAME_GROUP,
  payload: { data },
});

export const resetLobbyGameGroup = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GAME_GROUP,
});

export const editLobbyGameGroup = (lobbyId, groupId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_GAME_GROUP,
  payload: {
    lobbyId,
    groupId,
    data,
    name,
  },
});

export const getLobbyGeneralWidgets = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGETS,
  payload: { lobbyId, params },
});

export const saveLobbyGeneralWidgets = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGETS,
  payload: { data },
});

export const resetLobbyGeneralWidgets = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGETS,
});

export const getLobbyJackpotWidgets = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGETS,
  payload: { lobbyId, params },
});

export const saveLobbyJackpotWidgets = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGETS,
  payload: { data },
});

export const resetLobbyJackpotWidgets = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGETS,
});

export const getLobbyGeneralWidget = (lobbyId, widgetId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGET,
  payload: { lobbyId, widgetId, params },
});

export const saveLobbyGeneralWidget = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGET,
  payload: { data },
});

export const resetLobbyGeneralWidget = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGET,
});

export const getLobbyJackpotWidget = (lobbyId, widgetId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGET,
  payload: { lobbyId, widgetId, params },
});

export const saveLobbyJackpotWidget = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGET,
  payload: { data },
});

export const resetLobbyJackpotWidget = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGET,
});

export const changeLobbyGeneralWidgetStatus = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_GENERAL_WIDGET_STATUS,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const changeLobbyJackpotWidgetStatus = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_JACKPOT_WIDGET_STATUS,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const manageLobbyGeneralWidget = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.MANAGE_LOBBY_GENERAL_WIDGET,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const manageLobbyJackpotWidget = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.MANAGE_LOBBY_JACKPOT_WIDGET,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});
