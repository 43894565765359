import BrandSettingActionType from './brandSettingActionType';

export const brandSettingLoader = (loading) => ({
  type: BrandSettingActionType.BRAND_SETTING_LOADER,
  payload: { loading },
});

export const getBrandCurrencies = (brandId, isLoading) => ({
  type: BrandSettingActionType.GET_BRAND_CURRENCIES,
  payload: { brandId, isLoading },
});

export const saveBrandCurrencies = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CURRENCIES,
  payload: { data },
});

export const resetBrandCurrencies = () => ({
  type: BrandSettingActionType.RESET_BRAND_CURRENCIES,
});

export const addBrandCurrency = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_CURRENCY,
  payload: { data },
});

export const enableBrandCurrency = (data) => ({
  type: BrandSettingActionType.ENABLE_BRAND_CURRENCY,
  payload: { data },
});

export const setDefaultCurrency = (data) => ({
  type: BrandSettingActionType.SET_DEFAULT_CURRENCY,
  payload: { data },
});

export const changeOrderCurrency = (data) => ({
  type: BrandSettingActionType.CHANGE_ORDER_CURRENCY,
  payload: { data },
});

export const getBrandCountries = (brandId, isLoading) => ({
  type: BrandSettingActionType.GET_BRAND_COUNTRIES,
  payload: { brandId, isLoading },
});

export const saveBrandCountries = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_COUNTRIES,
  payload: { data },
});

export const resetBrandCountries = () => ({
  type: BrandSettingActionType.RESET_BRAND_COUNTRIES,
});

export const addBrandCountry = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_COUNTRY,
  payload: { data },
});

export const enableBrandCountry = (data) => ({
  type: BrandSettingActionType.ENABLE_BRAND_COUNTRY,
  payload: { data },
});

export const setDefaultCountry = (data) => ({
  type: BrandSettingActionType.SET_DEFAULT_COUNTRY,
  payload: { data },
});

export const getBrandLanguages = (brandId, isLoading) => ({
  type: BrandSettingActionType.GET_BRAND_LANGUAGES,
  payload: { brandId, isLoading },
});

export const saveBrandLanguages = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_LANGUAGES,
  payload: { data },
});

export const resetBrandLanguages = () => ({
  type: BrandSettingActionType.RESET_BRAND_LANGUAGES,
});

export const addBrandLanguage = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_LANGUAGE,
  payload: { data },
});

export const enableBrandLanguage = (data) => ({
  type: BrandSettingActionType.ENABLE_BRAND_LANGUAGE,
  payload: { data },
});

export const setDefaultLanguage = (data) => ({
  type: BrandSettingActionType.SET_DEFAULT_LANGUAGE,
  payload: { data },
});

export const changeOrderLanguage = (data) => ({
  type: BrandSettingActionType.CHANGE_ORDER_LANGUAGE,
  payload: { data },
});

export const changeSuccessStatus = (status) => ({
  type: BrandSettingActionType.CHANGE_SUCCESS_STATUS,
  payload: { status },
});

export const setBrandMainSetting = (data) => ({
  type: BrandSettingActionType.SET_BRAND_MAIN_SETTING,
  payload: { data },
});

export const getDomains = (brandId) => ({
  type: BrandSettingActionType.GET_DOMAINS,
  payload: { brandId },
});

export const saveDomains = (data) => ({
  type: BrandSettingActionType.SAVE_DOMAINS,
  payload: { data },
});

export const addDomain = (data) => ({
  type: BrandSettingActionType.ADD_DOMAIN,
  payload: { data },
});

export const editDomain = (id, domainName) => ({
  type: BrandSettingActionType.EDIT_DOMAIN,
  payload: { id, domainName },
});

export const deleteDomain = (id) => ({
  type: BrandSettingActionType.DELETE_DOMAIN,
  payload: { id },
});

export const connectDomain = (id, name) => ({
  type: BrandSettingActionType.CONNECT_DOMAIN,
  payload: { id, name },
});

export const disconnectDomain = (id, name) => ({
  type: BrandSettingActionType.DISCONNECT_DOMAIN,
  payload: { id, name },
});

export const getBrandSmsSettings = (id) => ({
  type: BrandSettingActionType.GET_BRAND_SMS_SETTINGS,
  payload: { id },
});

export const editBrandSmsSettings = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_SMS_SETTINGS,
  payload: { brandId, data },
});

export const deleteBrandSmsSettings = (brandId) => ({
  type: BrandSettingActionType.DELETE_BRAND_SMS_SETTINGS,
  payload: { brandId },
});

export const saveBrandSmsSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SMS_SETTINGS,
  payload: { data },
});

export const resetBrandSmsSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SMS_SETTINGS,
});

export const editBrandSmtpSettings = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_SMTP_SETTINGS,
  payload: { brandId, data },
});

export const editBrandCustomSmtpSettings = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_CUSTOM_SMTP_SETTINGS,
  payload: { brandId, data },
});

export const getBrandSmtpSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_SMTP_SETTINGS,
  payload: { brandId },
});

export const saveBrandSmtpSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SMTP_SETTINGS,
  payload: { data },
});

export const resetBrandSmtpSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SMTP_SETTINGS,
});

export const getBrandGeneralSettings = (params) => ({
  type: BrandSettingActionType.GET_BRAND_GENERAL_SETTINGS,
  payload: { params },
});

export const saveBrandGeneralSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_GENERAL_SETTINGS,
  payload: { data },
});

export const addBrandGeneralSettings = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_GENERAL_SETTINGS,
  payload: { data },
});

export const getBrandCaptchaSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_CAPTCHA_SETTINGS,
  payload: { brandId },
});

export const saveBrandCaptchaSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CAPTCHA_SETTINGS,
  payload: { data },
});

export const editBrandCaptchaSettings = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_CAPTCHA_SETTINGS,
  payload: { brandId, data },
});

export const resetBrandCaptchaSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_CAPTCHA_SETTINGS,
});

export const getBrandEmailContacts = (params) => ({
  type: BrandSettingActionType.GET_BRAND_EMAIL_CONTACTS,
  payload: { params },
});

export const saveBrandEmailContacts = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_EMAIL_CONTACTS,
  payload: { data },
});

export const resetBrandEmailContacts = () => ({
  type: BrandSettingActionType.RESET_BRAND_EMAIL_CONTACTS,
});

export const addBrandEmailContact = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_EMAIL_CONTACT,
  payload: { data },
});

export const setBrandEmailContactAsDefault = (id, params, address) => ({
  type: BrandSettingActionType.SET_BRAND_EMAIL_CONTACT_AS_DEFAULT,
  payload: { id, params, address },
});

export const deleteBrandEmailContact = (id, params) => ({
  type: BrandSettingActionType.DELETE_BRAND_EMAIL_CONTACT,
  payload: { id, params },
});

export const addBrandDepositConversion = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_DEPOSIT_CONVERSION,
  payload: { data },
});

export const addBrandWithdrawalConversion = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_WITHDRAWAL_CONVERSION,
  payload: { data },
});

export const addBrandOtherConversion = (data) => ({
  type: BrandSettingActionType.ADD_BRAND_OTHER_CONVERSION,
  payload: { data },
});

export const getBrandDepositConversion = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSION,
  payload: { brandId, params },
});

export const getBrandWithdrawalConversion = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSION,
  payload: { brandId, params },
});

export const saveBrandConversion = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CONVERSION,
  payload: { data },
});

export const resetBrandConversion = () => ({
  type: BrandSettingActionType.RESET_BRAND_CONVERSION,
});

export const getBrandOtherConversion = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_OTHER_CONVERSION,
  payload: { brandId, params },
});

export const editBrandDepositConversion = (data) => ({
  type: BrandSettingActionType.EDIT_BRAND_DEPOSIT_CONVERSION,
  payload: { data },
});

export const editBrandWithdrawalConversion = (data) => ({
  type: BrandSettingActionType.EDIT_BRAND_WITHDRAWAL_CONVERSION,
  payload: { data },
});

export const editBrandOtherConversion = (data) => ({
  type: BrandSettingActionType.EDIT_BRAND_OTHER_CONVERSION,
  payload: { data },
});

export const deleteBrandDepositConversion = (brandId, params) => ({
  type: BrandSettingActionType.DELETE_BRAND_DEPOSIT_CONVERSION,
  payload: { brandId, params },
});

export const deleteBrandWithdrawalConversion = (brandId, params) => ({
  type: BrandSettingActionType.DELETE_BRAND_WITHDRAWAL_CONVERSION,
  payload: { brandId, params },
});

export const deleteBrandOtherConversion = (brandId, params) => ({
  type: BrandSettingActionType.DELETE_BRAND_OTHER_CONVERSION,
  payload: { brandId, params },
});

export const getBrandDepositConversions = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSIONS,
  payload: { brandId, params },
});

export const saveBrandDepositConversions = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_DEPOSIT_CONVERSIONS,
  payload: { data },
});

export const resetBrandDepositConversions = () => ({
  type: BrandSettingActionType.RESET_BRAND_DEPOSIT_CONVERSIONS,
});

export const getBrandWithdrawalConversions = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSIONS,
  payload: { brandId, params },
});

export const saveBrandWithdrawalConversions = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_WITHDRAWAL_CONVERSIONS,
  payload: { data },
});

export const resetBrandWithdrawalConversions = () => ({
  type: BrandSettingActionType.RESET_BRAND_WITHDRAWAL_CONVERSIONS,
});

export const getBrandOtherConversions = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_OTHER_CONVERSIONS,
  payload: { brandId, params },
});

export const saveBrandOtherConversions = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_OTHER_CONVERSIONS,
  payload: { data },
});

export const resetBrandOtherConversions = () => ({
  type: BrandSettingActionType.RESET_BRAND_OTHER_CONVERSIONS,
});

export const importBrandDepositConversion = (brandId, data) => ({
  type: BrandSettingActionType.IMPORT_BRAND_DEPOSIT_CONVERSION,
  payload: { brandId, data },
});

export const importBrandWithdrawalConversion = (brandId, data) => ({
  type: BrandSettingActionType.IMPORT_BRAND_WITHDRAWAL_CONVERSION,
  payload: { brandId, data },
});

export const importBrandOtherConversion = (brandId, data) => ({
  type: BrandSettingActionType.IMPORT_BRAND_OTHER_CONVERSION,
  payload: { brandId, data },
});

export const exportBrandDepositConversion = (brandId, params) => ({
  type: BrandSettingActionType.EXPORT_BRAND_DEPOSIT_CONVERSION,
  payload: { brandId, params },
});

export const exportBrandWithdrawalConversion = (brandId, params) => ({
  type: BrandSettingActionType.EXPORT_BRAND_WITHDRAWAL_CONVERSION,
  payload: { brandId, params },
});

export const exportBrandOtherConversion = (brandId, params) => ({
  type: BrandSettingActionType.EXPORT_BRAND_OTHER_CONVERSION,
  payload: { brandId, params },
});

export const getBrandDepositConversionLogs = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_DEPOSIT_CONVERSION_LOGS,
  payload: { brandId, params },
});

export const saveBrandConversionLogs = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CONVERSION_LOGS,
  payload: { data },
});

export const getBrandWithdrawalConversionLogs = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_WITHDRAWAL_CONVERSION_LOGS,
  payload: { brandId, params },
});

export const getBrandOtherConversionLogs = (brandId, params) => ({
  type: BrandSettingActionType.GET_BRAND_OTHER_CONVERSION_LOGS,
  payload: { brandId, params },
});

export const resetBrandConversionLogs = () => ({
  type: BrandSettingActionType.RESET_BRAND_CONVERSION_LOGS,
});

export const getBrandStandardSettings = (brandId, isLoading) => ({
  type: BrandSettingActionType.GET_BRAND_STANDARD_SETTINGS,
  payload: { brandId, isLoading },
});

export const saveBrandStandardSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_STANDARD_SETTINGS,
  payload: { data },
});

export const editBrandStandardSettings = (brandId, data, enabled, name) => ({
  type: BrandSettingActionType.EDIT_BRAND_STANDARD_SETTINGS,
  payload: {
    brandId,
    data,
    enabled,
    name,
  },
});

export const resetBrandStandardSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_STANDARD_SETTINGS,
});

export const getBrandBlockedCountriesResource = (brandId, isLoading) => ({
  type: BrandSettingActionType.GET_BRAND_BLOCKED_COUNTRIES_RESOURCE,
  payload: { brandId, isLoading },
});

export const saveBrandBlockedCountriesResource = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_BLOCKED_COUNTRIES_RESOURCE,
  payload: { data },
});

export const resetBrandBlockedCountriesResource = () => ({
  type: BrandSettingActionType.RESET_BRAND_BLOCKED_COUNTRIES_RESOURCE,
});

export const addBrandBlockedCountries = (brandId, data) => ({
  type: BrandSettingActionType.ADD_BRAND_BLOCKED_COUNTRIES,
  payload: { brandId, data },
});

export const deleteBrandBlockedCountry = (brandId, data, name) => ({
  type: BrandSettingActionType.DELETE_BRAND_BLOCKED_COUNTRY,
  payload: { brandId, data, name },
});

export const getBrandBlockedIPs = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_BLOCKED_IPS,
  payload: { brandId },
});

export const saveBrandBlockedIPs = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_BLOCKED_IPS,
  payload: { data },
});

export const resetBrandBlockedIps = () => ({
  type: BrandSettingActionType.RESET_BRAND_BLOCKED_IPS,
});

export const addBrandBlockedIPs = (brandId, data) => ({
  type: BrandSettingActionType.ADD_BRAND_BLOCKED_IPS,
  payload: { brandId, data },
});

export const deleteBrandBlockedIP = (brandId, data) => ({
  type: BrandSettingActionType.DELETE_BRAND_BLOCKED_IP,
  payload: { brandId, data },
});

export const resetBrandSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SETTINGS,
});

export const addDomainRedirectUrl = (brandId, data) => ({
  type: BrandSettingActionType.ADD_DOMAIN_REDIRECT_URL,
  payload: { brandId, data },
});

export const deleteDomainRedirectUrl = (brandId, params) => ({
  type: BrandSettingActionType.DELETE_DOMAIN_REDIRECT_URL,
  payload: { brandId, params },
});

export const getBrandSportsBookLayouts = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_SPORTSBOOK_LAYOUTS,
  payload: { brandId },
});

export const saveBrandSportsBookLayouts = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_LAYOUTS,
  payload: { data },
});

export const resetBrandSportsBookLayouts = () => ({
  type: BrandSettingActionType.RESET_BRAND_SPORTSBOOK_LAYOUTS,
});

export const changeSportsBookTemplatesCallEndedStatus = (status) => ({
  type: BrandSettingActionType.CHANGE_BRAND_SPORTSBOOK_LAYOUTS_CALL_ENDED_STATUS,
  payload: { status },
});

export const activateBrandSportsBookProvider = (brandId) => ({
  type: BrandSettingActionType.ACTIVATE_BRAND_SPORTSBOOK_PROVIDER,
  payload: { brandId },
});

export const setBrandSportsBookDefaultTemplate = (brandId, templateId) => ({
  type: BrandSettingActionType.SET_BRAND_SPORTSBOOK_DEFAULT_TEMPLATE,
  payload: { brandId, templateId },
});

export const activateBrandRgs = (brandId) => ({
  type: BrandSettingActionType.ACTIVATE_BRAND_RGS,
  payload: { brandId },
});

export const getBrandSportsBookSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_SPORTSBOOK_SETTINGS,
  payload: { brandId },
});

export const saveBrandSportsBookSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_SETTINGS,
  payload: { data },
});

export const resetBrandSportsBookSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SPORTSBOOK_SETTINGS,
});

export const enableBrandSportsBookPrematchTopMatches = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOP_MATCHES,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookPrematchTotalTopMatches = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_PREMATCH_TOTAL_TOP_MATCHES,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookLivePrematchTopMatches = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_LIVE_PREMATCH_TOP_MATCHES,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookLiveTopMatches = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_LIVE_TOP_MATCHES,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookJackpot = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_JACKPOT,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookSectionScrolling = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_SECTION_SCROLLING,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookTournamentBalance = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_TOURNAMENT_BALANCE,
  payload: { brandId, params, name },
});

export const changeBrandSportsBookMobileIntegrationType = (brandId, params, name) => ({
  type: BrandSettingActionType.CHANGE_BRAND_SPORTSBOOK_MOBILE_INTEGRATION_TYPE,
  payload: { brandId, params, name },
});

export const enableBrandSportsBookColorInheritance = (brandId, params, name) => ({
  type: BrandSettingActionType.ENABLE_BRAND_SPORTSBOOK_COLOR_INHERITANCE,
  payload: { brandId, params, name },
});

export const getBrandPreferencesSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_PREFERENCES_SETTINGS,
  payload: { brandId },
});

export const saveBrandPreferencesSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_PREFERENCES_SETTINGS,
  payload: { data },
});

export const resetBrandPreferencesSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_PREFERENCES_SETTINGS,
});

export const editBrandPreferencesDepositSettings = (brandId, data, name, enabled) => ({
  type: BrandSettingActionType.EDIT_BRAND_PREFERENCES_DEPOSIT_SETTINGS,
  payload: {
    brandId,
    data,
    name,
    enabled,
  },
});

export const editBrandPreferencesWithdrawalSettings = (brandId, data, name, enabled) => ({
  type: BrandSettingActionType.EDIT_BRAND_PREFERENCES_WITHDRAWAL_SETTINGS,
  payload: {
    brandId,
    data,
    name,
    enabled,
  },
});

export const editBrandPreferencesPromotionSettings = (brandId, data, name, enabled) => ({
  type: BrandSettingActionType.EDIT_BRAND_PREFERENCES_PROMOTION_SETTINGS,
  payload: {
    brandId,
    data,
    name,
    enabled,
  },
});

export const getBrandSportsBookWalletsSettings = (params) => ({
  type: BrandSettingActionType.GET_BRAND_SPORTSBOOK_WALLETS_SETTINGS,
  payload: { params },
});
export const saveBrandSportsBookWalletsSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SPORTSBOOK_WALLETS_SETTINGS,
  payload: { data },
});
export const resetBrandSportsBookWalletsSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SPORTSBOOK_WALLETS_SETTINGS,
});
export const editBrandSportsBookWalletsSettings = (data, brandId) => ({
  type: BrandSettingActionType.EDIT_BRAND_SPORTSBOOK_WALLETS_SETTINGS,
  payload: { data, brandId },
});

export const getBrandCasinoProductSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_CASINO_PRODUCT_SETTINGS,
  payload: { brandId },
});

export const saveBrandCasinoProductSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CASINO_PRODUCT_SETTINGS,
  payload: { data },
});

export const resetBrandCasinoProductSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_CASINO_PRODUCT_SETTINGS,
});

export const editBrandCasinoProductSettings = (brandId, data, param, name) => ({
  type: BrandSettingActionType.EDIT_BRAND_CASINO_PRODUCT_SETTINGS,
  payload: {
    brandId,
    data,
    param,
    name,
  },
});

export const getBrandCasinoProductWalletsSettings = (params) => ({
  type: BrandSettingActionType.GET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS,
  payload: { params },
});
export const saveBrandCasinoProductWalletsSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS,
  payload: { data },
});
export const resetBrandCasinoProductWalletsSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS,
});
export const editBrandCasinoProductWalletsSettings = (data, brandId) => ({
  type: BrandSettingActionType.EDIT_BRAND_CASINO_PRODUCT_WALLETS_SETTINGS,
  payload: { data, brandId },
});

export const editBrandPreferencesBonusesSettings = (brandId, data, name, enabled) => ({
  type: BrandSettingActionType.EDIT_BRAND_PREFERENCES_BONUSES_SETTINGS,
  payload: {
    brandId,
    data,
    name,
    enabled,
  },
});

export const getBrandCurrencyFormat = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_CURRENCY_FORMAT,
  payload: { brandId },
});

export const saveBrandCurrencyFormat = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_CURRENCY_FORMAT,
  payload: { data },
});

export const resetBrandCurrencyFormat = () => ({
  type: BrandSettingActionType.RESET_BRAND_CURRENCY_FORMAT,
});

export const editBrandCurrencyFormat = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_CURRENCY_FORMAT,
  payload: {
    brandId,
    data,
  },
});

export const getBrandInactivitySettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_INACTIVITY_SETTINGS,
  payload: { brandId },
});

export const saveBrandInactivitySettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_INACTIVITY_SETTINGS,
  payload: { data },
});

export const resetBrandInactivitySettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_INACTIVITY_SETTINGS,
});

export const editBrandInactivitySettings = (brandId, data) => ({
  type: BrandSettingActionType.EDIT_BRAND_INACTIVITY_SETTINGS,
  payload: {
    brandId,
    data,
  },
});

export const getBrandSportStatusSettings = (brandId) => ({
  type: BrandSettingActionType.GET_BRAND_SPORT_STATUS_SETTINGS,
  payload: { brandId },
});

export const saveBrandSportStatusSettings = (data) => ({
  type: BrandSettingActionType.SAVE_BRAND_SPORT_STATUS_SETTINGS,
  payload: { data },
});

export const resetBrandSportStatusSettings = () => ({
  type: BrandSettingActionType.RESET_BRAND_SPORT_STATUS_SETTINGS,
});

export const editBrandSportStatusSettings = (brandId, id, data, name) => ({
  type: BrandSettingActionType.EDIT_BRAND_SPORT_STATUS_SETTINGS,
  payload: {
    brandId,
    id,
    data,
    name,
  },
});
