import qs from 'qs';
import {
  Tooltip, Form, Popover, Radio, Button, Input,
} from 'antd';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { FormItemErrorMessage, IconSvg } from 'components/common';
import { AppConstants } from 'constants/index';
import { StyledSwitchSmall } from 'components/styledComponents/switch/Switch.styled';
import {
  StyledUnlinkedIcon, StyledDeleteIcon, StyledIconDefault, StyledFlagWrapper, StyledFlag, StyledStar, StyledSvgIconHover, StyledAction,
} from 'components/styledComponents/icon/Icon.styled';
import { StyledTheadWrapper } from 'components/brandSetting/seo/seo/Seo.styled';
import { StyledDeleteWrapper } from 'components/brandSetting/BrandSettings.styled';
import enumTypes from 'constants/enumTypes';
import More from 'components/common/More';
import {
  legalAgreementStatusesColors, domainsStateColors, sportsBookLayoutTypesIconMapping, sportsBookFeaturesWallets,
} from 'constants/mapping';
import { ActionsTextItems, StyledRangeWrapper } from 'components/bonusManagement/BonusManagement.styled';
import { StyledTagsPointer, StyledTagsWrapper } from 'components/styledComponents/tag/Tag.styled';
import { colors } from 'constants/colors';
import { toFormatNumber } from 'utils/utils';
import {
  handleNavigate, getAllowedActionsCount, getResourceValue, toFormatNumberByCurrency,
} from 'utils/helpers';
import {
  BlueBackgroundItem, CellAlign, HighlightedText, StyledCurrencyInfo,
} from 'components/styledComponents/table/Table.styled';
import { StyledRadioButtons } from 'components/styledComponents/radio/Radio.styled';
import { TooltipIconWrapper, StyledTruncate } from 'Global.styled';
import { StyledVerificationText, StyledAndContainer } from 'components/brandSetting/main/verification/VerificationSettings.styled';
import { StyledStandardFeature } from 'components/brandSetting/standard/Standard.styled';
import Can from 'components/common/Can';
import { resource, types } from 'constants/accessControl';
import i18n from 'services/i18n';
import { AdminSelect } from 'components/common/form';
import Description from 'components/brandSetting/Description';
import actionsPermissions from 'constants/actionsPermissions';
import { StyledNameWithIcon } from 'components/brandSetting/sportsBook/SportsBook.styled';
import { LabelInfo } from 'components/common/info';
import { FilterService, DateService } from 'services';
import Config from 'config';
import TableActions from 'components/common/table/TableActions';
import { StyledTagCategoryTypeIcon } from 'components/tools/brandAutomation/BrandAutomation.styled';
import { StyledSelectOption } from 'components/styledComponents/select/Select.styled';
import { Link } from 'react-router-dom';
import { lightModeColors } from 'theme/theme';

const { domainStatuses: domainStatusesEnum } = enumTypes;
const activationState = ['inactive', 'active'];
const depositWithdrawalState = ['disabled', 'enabled'];
const { TextArea } = Input;
const { getFormattedDate } = DateService;
const { getQuery } = FilterService;

const { isBrRegulationAudit } = Config;
const enableDisableState = ['disabled', 'enabled'];

const currency = (actions) => [
  {
    title: 'currencyId',
    name: 'Currency',
    width: 200,
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    render: (text, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'currencyName',
    key: 'currencyName',
    dataIndex: 'currencyName',
    align: 'left',
  },
  {
    title: 'brand:currencySymbol',
    key: 'currencySymbol',
    dataIndex: 'currencySymbol',
    align: 'left',
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => {
      const setMainAction = (
        <StyledStar disabled={record.isActive && !record.isMain ? 0 : 1}>
          <div
            onClick={() => {
              actions.setRowState({
                visible: true,
                type: 'setMain',
                row: record,
              });
            }}
          >
            <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isMain ? lightModeColors.blue : ''} />
          </div>
        </StyledStar>
      );

      const activate = (
        <div
          onClick={() => (!record.isMain
            ? actions.setRowState({
              visible: true,
              type: 'enable',
              row: record,
            })
            : '')}
        >
          <StyledSwitchSmall disabled={record.isMain} checked={record.isActive} size="small" />
        </div>
      );
      return (
        <TableActions>
          <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.isActive && !record.isMain ? 'brand:setMainCurrency' : 'brand:mainCurrency'}</Trans>} arrow={{ pointAtCenter: true }}>
                {setMainAction}
              </Tooltip>
            )}
          </Can>
          {!isBrRegulationAudit
            && (record.isMain ? (
              <Can type={types.statusChange} rule={`${resource.brands}.${resource.mainSettings}`}>
                {activate}
              </Can>
            ) : (
              <Can type={types.statusChange} rule={`${resource.brands}.${resource.mainSettings}`}>
                <Tooltip title={<Trans>{record.isActive ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
                  {activate}
                </Tooltip>
              </Can>
            ))}
        </TableActions>
      );
    },
  },
];

const addCurrency = [
  {
    title: 'currencyId',
    dataIndex: 'id',
    key: 'id',
    render: (actions, record) => (
      <StyledFlagWrapper>
        <StyledFlag className="f24">
          <div className={`${record.id.toLowerCase()} flag`} />
        </StyledFlag>
        <strong>{record.id.toUpperCase()}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'currencyName',
    key: 'name',
    dataIndex: 'name',
    align: 'left',
    render: (actions, record) => (
      <Popover placement="bottomLeft" content={record.name} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.name}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'brand:currencySymbol',
    key: 'symbol',
    dataIndex: 'symbol',
    align: 'left',
    render: (actions, record) => (
      <Popover placement="bottomLeft" content={record.symbol} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.symbol}</StyledTruncate>
      </Popover>
    ),
  },
];

const country = (actions) => [
  {
    title: 'country',
    dataIndex: 'countryName',
    key: 'countryName',
    align: 'left',
    width: '10rem',
    render: (text, record) => (
      <StyledFlagWrapper>
        {record.countryCode && (
          <StyledFlag className="f24">
            <div className={`${record.countryCode.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.countryName}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    render: (text, record) => {
      const setMainAction = (
        <StyledStar disabled={record.isActive && !record.isMain ? 0 : 1}>
          <div
            onClick={() => !record.isMain
              && actions.setRowState({
                visible: true,
                type: 'setMain',
                row: record,
              })}
          >
            <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isMain ? lightModeColors.blue : ''} />
          </div>
        </StyledStar>
      );

      const activate = (
        <div
          onClick={() => (!record.isMain
            ? actions.setRowState({
              visible: true,
              type: 'enable',
              row: record,
            })
            : '')}
        >
          <StyledSwitchSmall disabled={record.isMain} checked={record.isActive} size="small" />
        </div>
      );
      return (
        <TableActions>
          <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.isActive && !record.isMain ? 'brand:setMainCountry' : 'brand:mainCountry'}</Trans>} arrow={{ pointAtCenter: true }}>
                {setMainAction}
              </Tooltip>
            )}
          </Can>
          <Can type={types.statusChange} rule={`${resource.brands}.${resource.mainSettings}`}>
            {!isBrRegulationAudit
              && (record.isMain ? (
                <>{activate}</>
              ) : (
                <Tooltip title={<Trans>{record.isActive ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
                  {activate}
                </Tooltip>
              ))}
          </Can>
        </TableActions>
      );
    },
  },
];

const addCountry = [
  {
    title: 'country',
    dataIndex: 'country',
    key: 'country',
    render: (actions, record) => (
      <StyledFlagWrapper>
        <StyledFlag className="f24">
          <div className={`${record.code.toLowerCase()} flag`} />
        </StyledFlag>
        <span>{record.name}</span>
      </StyledFlagWrapper>
    ),
  },
];

const language = (actions) => [
  {
    title: 'languageId',
    width: 200,
    dataIndex: 'languageId',
    key: 'languageId',
    align: 'left',
    render: (text, record) => (
      <StyledFlagWrapper>
        {record.languageId && (
          <StyledFlag className="f24">
            <div className={`${record.languageId.toLowerCase()} lang flag`} />
          </StyledFlag>
        )}
        <strong>{record.languageId && record.languageId.toUpperCase()}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'languageName',
    align: 'left',
    dataIndex: 'languageName',
    key: 'languageName',
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => {
      const setMainAction = (
        <StyledStar disabled={record.isActive && !record.isMain ? 0 : 1}>
          <div
            onClick={() => !record.isMain
              && actions.setRowState({
                visible: true,
                type: 'setMain',
                row: record,
              })}
          >
            <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isMain ? lightModeColors.blue : ''} />
          </div>
        </StyledStar>
      );

      const activate = (
        <div
          onClick={() => (!record.isMain
            ? actions.setRowState({
              visible: true,
              type: 'enable',
              row: record,
            })
            : '')}
        >
          <StyledSwitchSmall disabled={record.isMain} checked={record.isActive} size="small" />
        </div>
      );
      return (
        <TableActions>
          <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.isActive && !record.isMain ? 'brand:setMainLanguage' : 'brand:mainLanguage'}</Trans>} arrow={{ pointAtCenter: true }}>
                {setMainAction}
              </Tooltip>
            )}
          </Can>
          <Can type={types.statusChange} rule={`${resource.brands}.${resource.mainSettings}`}>
            {!isBrRegulationAudit
              && (record.isMain ? (
                <>{activate}</>
              ) : (
                <Tooltip title={<Trans>{record.isActive ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
                  {activate}
                </Tooltip>
              ))}
          </Can>
        </TableActions>
      );
    },
  },
];

const addLanguage = [
  {
    title: 'languageId',
    width: '16rem',
    dataIndex: 'id',
    key: 'id',
    render: (actions, record) => (
      <StyledFlagWrapper>
        {record.id && (
          <StyledFlag className="f24">
            <div className={`${record.id.toLowerCase()} lang flag`} />
          </StyledFlag>
        )}
        <strong>{record.id && record.id.toUpperCase()}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'languageName',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  },
];

const domain = () => [
  {
    title: 'brand:domainName',
    width: 320,
    dataIndex: 'domainName',
    key: 'domainName',
    className: 'domain',
    editable: !Can({
      type: types.edit,
      rule: `${resource.brands}.${resource.domainConfigurations}`,
      children: false,
      fallback: true,
    }),
    align: 'left',
    rules: [
      {
        required: true,
        message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('brand:domainName') })} />,
      },
      {
        pattern: AppConstants.regex.domain,
        message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:domain')} />,
      },
    ],
  },
  {
    title: 'brand:redirectedTo',
    dataIndex: 'redirectedDomainName',
    key: 'redirectedDomainName',
    align: 'left',
    render: (actions, record) => (record.redirectedDomainName ? (
      <StyledDeleteWrapper width="true" pointer="true" margin closable onClose={() => actions.setRowState({ visible: true, row: record, type: 'deleteRedirect' })}>
        {record.redirectedDomainName}
      </StyledDeleteWrapper>
    ) : (
      <CellAlign type="left">-</CellAlign>
    )),
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    render: (actions, record) => (
      <StyledTagsWrapper width="true" color={colors[domainsStateColors[record.status]] && colors[domainsStateColors[record.status]].background}>
        <StyledTagsPointer color={domainsStateColors[record.status]} />
        {getResourceValue('domainStatuses', record.status, enumTypes.domainStatuses)}
      </StyledTagsWrapper>
    ),
  },

  {
    title: 'brand:nameServer1',
    dataIndex: 'nameServer1',
    key: 'nameServer1',
    align: 'left',
  },
  {
    title: 'brand:nameServer2',
    dataIndex: 'nameServer2',
    key: 'nameServer2',
    align: 'left',
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (actions, record) => (
      <TableActions>
        <Can type={types.manage} rule={`${resource.brands}.${resource.domainConfigurations}`}>
          {!isBrRegulationAudit
            && (+record.status !== domainStatusesEnum.Active ? (
              <>
                <Tooltip title={<Trans>brand:onlyVerifiedDomainInfo</Trans>} arrow={{ pointAtCenter: true }}>
                  <StyledUnlinkedIcon>
                    <IconSvg icon="UnlinkedIcon" color="#e1e1e1" />
                  </StyledUnlinkedIcon>
                </Tooltip>
              </>
            ) : null)}
        </Can>
        <Can type={types.manage} rule={`${resource.brands}.${resource.domainConfigurations}`}>
          {!isBrRegulationAudit
            && +record.status === domainStatusesEnum.Active
            && (!record.isPaused ? (
              <Tooltip title={<Trans>brand:disconnectDomain</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledIconDefault onClick={() => actions.disconnectDomain(record.id, record.domainName)}>
                  <IconSvg icon="LinkedIcon" />
                </StyledIconDefault>
              </Tooltip>
            ) : (
              <Tooltip title={<Trans>brand:connectDomain</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledIconDefault onClick={() => actions.connectDomain(record.id, record.domainName)}>
                  <IconSvg icon="UnlinkedIcon" />
                </StyledIconDefault>
              </Tooltip>
            ))}
        </Can>
        <Can type={types.delete} rule={`${resource.brands}.${resource.domainConfigurations}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>brand:deleteDomain</Trans>} arrow={{ pointAtCenter: true }}>
              {record.isUsed ? (
                <StyledDeleteIcon disabled>
                  <IconSvg icon="DeleteIcon" />
                </StyledDeleteIcon>
              ) : (
                <StyledDeleteIcon
                  onClick={() => {
                    actions.setRowState({ visible: true, row: record, type: 'delete' });
                  }}
                >
                  <IconSvg icon="DeleteIcon" />
                </StyledDeleteIcon>
              )}
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const customScripts = (actions) => [
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  },
  {
    title: 'creationDate',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'left',
    render: (text, record) => (record.createdDate ? getFormattedDate(record.createdDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'createdBy',
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: (text, record) => record.createdBy ?? <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'updatedDate',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    render: (text, record) => (record.updatedDate ? getFormattedDate(record.updatedDate, AppConstants.format.dateTime) : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'updatedBy',
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    render: (text, record) => record.updatedBy ?? <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.edit} rule={`${resource.brands}.${resource.thirdPartyServices}`}>
          {!isBrRegulationAudit && (
            <Tooltip placement="top" title={<Trans>edit</Trans>}>
              <StyledSvgIconHover
                onClick={() => {
                  actions.setAction({
                    data: record,
                    type: 'addEdit',
                    visible: true,
                  });
                }}
              >
                <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
              </StyledSvgIconHover>
            </Tooltip>
          )}
        </Can>
        <Can type={types.delete} rule={`${resource.brands}.${resource.thirdPartyServices}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>brand:deleteScript</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover
                onClick={() => actions.setAction({
                  data: record,
                  type: 'delete',
                  visible: true,
                })}
              >
                <IconSvg icon="DeleteIcon" />
              </StyledSvgIconHover>
            </Tooltip>
          )}
        </Can>
        <Can type={types.statusChange} rule={`${resource.brands}.${resource.thirdPartyServices}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>{record.isActive ? 'brand:deactivateScript' : 'brand:activateScript'}</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledAction>
                <StyledSwitchSmall onClick={() => actions.changeCustomScriptStatus(record)} checked={record.isActive} size="small" />
              </StyledAction>
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const casino = (actions) => [
  {
    title: 'providerId',
    dataIndex: 'providerId',
    key: 'providerId',
    align: 'left',
    width: 120,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.providerId} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.providerId}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'providerName',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 160,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.name} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.name}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'aggregator',
    dataIndex: 'providerAggregatorName',
    key: 'providerAggregatorName',
    align: 'left',
    width: 140,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.providerAggregatorName} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.providerAggregatorName}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'state',
    key: 'isActive',
    dataIndex: 'isActive',
    align: 'left',
    width: 100,
    render: (text, record) => <Trans>{activationState[+record.isActive]}</Trans>,
  },
  {
    title: 'games',
    dataIndex: 'gameCount',
    key: 'gameCount',
    width: 100,
    render: (text, record) => {
      const { providerId } = record;
      const { params, brandId } = actions.getFilters();
      const url = {
        ...params,
        inside: JSON.stringify({
          providerId,
        }),
      };
      return (
        <BlueBackgroundItem to={`/brands/${brandId}/providerGames?${qs.stringify(_.pickBy(url, _.identity))}`}>
          <HighlightedText>{record.gameCount}</HighlightedText>
        </BlueBackgroundItem>
      );
    },
  },
  {
    title: 'creationDate',
    key: 'createdDate',
    dataIndex: 'createdDate',
    align: 'left',
    sorter: true,
    width: 160,
    render: (text, record) => (record.createdDate ? getFormattedDate(record.createdDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'lastUpdateDate',
    key: 'updatedDate',
    dataIndex: 'updatedDate',
    align: 'left',
    width: 160,
    render: (text, record) => (record.updatedDate ? getFormattedDate(record.updatedDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
];

const generalVerificationSettings = () => [
  {
    title: 'brand:activity',
    dataIndex: 'type',
    key: 'type',
    width: '70%',
    align: 'left',
  },
  {
    title: 'brand:verificationAnd',
    dataIndex: 'verificationAnd',
    key: 'type',
    width: 200,
    align: 'left',
    render: (actions, record) => {
      const allVerifications = Object.values(enumTypes.playerVerification).map((el) => +el);
      return (
        <Form.Item shouldUpdate={(prev, next) => !_.isEqual(prev[record.fieldOr], next[record.fieldOr])}>
          {({ getFieldValue }) => {
            const fieldOr = getFieldValue(record.fieldOr);
            const options = _.difference(allVerifications, fieldOr);
            return (
              <Form.Item noStyle name={record.fieldAnd}>
                <AdminSelect
                  isMultiple
                  placeholder={<Trans>select</Trans>}
                  maxTagCount={2}
                  disabled={!options.length}
                  data={options.map((el) => ({
                    id: +el,
                    name: getResourceValue('playerVerification', el, enumTypes.playerVerification),
                  }))}
                  isToString={false}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    },
  },
  {
    title: '',
    dataIndex: 'field',
    key: 'field',
    align: 'left',
    render: () => (
      <StyledAndContainer>
        <StyledVerificationText>
          <Trans>brand:andDelimiter</Trans>
        </StyledVerificationText>
      </StyledAndContainer>
    ),
  },
  {
    title: 'brand:verificationOr',
    dataIndex: 'verificationOr',
    key: 'type',
    width: 200,
    align: 'left',
    render: (actions, record) => {
      const allVerifications = Object.values(enumTypes.playerVerification).map((el) => +el);
      return (
        <Form.Item shouldUpdate={(prev, next) => !_.isEqual(prev[record.fieldAnd], next[record.fieldAnd])}>
          {({ getFieldValue }) => {
            const fieldAnd = getFieldValue(record.fieldAnd);
            const options = _.difference(allVerifications, fieldAnd);
            return (
              <Form.Item noStyle name={record.fieldOr}>
                <AdminSelect
                  isMultiple
                  placeholder={<Trans>select</Trans>}
                  maxTagCount={2}
                  disabled={!options.length}
                  data={options.map((el) => ({
                    id: el,
                    name: getResourceValue('playerVerification', el, enumTypes.playerVerification),
                  }))}
                  isToString={false}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    },
  },
];

const viewGeneralVerificationSettings = () => [
  {
    title: 'brand:activity',
    dataIndex: 'type',
    key: 'type',
    width: '70%',
    align: 'left',
  },
  {
    title: 'brand:verificationAnd',
    dataIndex: 'verificationAnd',
    key: 'type',
    width: 200,
    align: 'left',
    render: (verificationOr) => {
      const data = verificationOr.map((el) => ({ id: el, name: getResourceValue('playerVerification', el, enumTypes.playerVerification) }));
      return data.length ? <More data={data} /> : <CellAlign>-</CellAlign>;
    },
  },
  {
    title: '',
    dataIndex: 'field',
    key: 'field',
    align: 'left',
    render: () => (
      <StyledAndContainer>
        <StyledVerificationText>
          <Trans>brand:andDelimiter</Trans>
        </StyledVerificationText>
      </StyledAndContainer>
    ),
  },
  {
    title: 'brand:verificationOr',
    dataIndex: 'verificationOr',
    key: 'type',
    width: 200,
    align: 'left',
    render: (verificationAnd) => {
      const data = verificationAnd.map((el) => ({ id: el, name: getResourceValue('playerVerification', el, enumTypes.playerVerification) }));
      return data.length ? <More data={data} /> : <CellAlign>-</CellAlign>;
    },
  },
];

const sportsBook = (actions) => [
  {
    title: 'brand:sportsBookViews',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 240,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>
          <IconSvg icon={sportsBookLayoutTypesIconMapping[record.id]} height="1.14rem" width={null} />
        </div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    className: 'no-truncate no-header-separator',
    render: (text, record) => (record.description ? record.description : <CellAlign>-</CellAlign>),
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    width: 100,
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <div>
          <Tooltip placement="top" title={<Trans>view</Trans>}>
            <Link to={record.link} target="_blank" rel="noreferrer">
              <IconSvg icon="PreviewIcon" width="1.43rem" height="1.43rem" />
            </Link>
          </Tooltip>
        </div>
        <Can type={types.edit} rule={`${resource.brands}.${resource.sportsBook}`}>
          {!isBrRegulationAudit && (
            <StyledStar disabled={record.isMain}>
              <div
                onClick={() => {
                  actions.setAction({
                    visible: true,
                    type: 'setDefault',
                    data: record,
                  });
                }}
              >
                <Tooltip title={<Trans>{i18n.t('brand:setMainView')}</Trans>} arrow={{ pointAtCenter: true }}>
                  <div>
                    <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isMain ? '#00a1af' : ''} />
                  </div>
                </Tooltip>
              </div>
            </StyledStar>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const casinoProviderGames = () => [
  {
    title: 'gameId',
    dataIndex: 'id',
    key: 'id',
    width: 120,
  },
  {
    title: 'gameName',
    dataIndex: 'name',
    key: 'name',
    width: 160,
  },
  {
    title: 'systemCategory',
    key: 'category',
    dataIndex: 'category',
    width: 160,
    render: (text, record) => record.category && record.category.name,
  },
  {
    title: 'gameGlobalState',
    key: 'enabled',
    dataIndex: 'enabled',
    align: 'left',
    width: 160,
    render: (text, record) => <Trans>{activationState[+record.enabled]}</Trans>,
  },
  {
    title: 'gameBrandState',
    key: 'isActive',
    dataIndex: 'isActive',
    align: 'left',
    width: 160,
    render: (text, record) => <Trans>{activationState[+record.isActive]}</Trans>,
  },
  {
    title: 'gameState',
    key: 'isLaunchable',
    dataIndex: 'isLaunchable',
    align: 'left',
    width: 160,
    render: (text, record) => <Trans>{activationState[+record.isLaunchable]}</Trans>,
  },
  {
    title: 'demoVersion',
    key: 'hasDemo',
    dataIndex: 'hasDemo',
    align: 'left',
    width: 140,
    render: (text, record) => <Trans>{activationState[+record.hasDemo]}</Trans>,
  },
];

const email = (actions) => [
  {
    title: 'email',
    width: 300,
    dataIndex: 'address',
    key: 'address',
    align: 'left',
  },
  {
    title: 'senderName',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    render: (text, record) => {
      const setDefault = (
        <StyledStar disabled={!record.isDefault ? 0 : 1}>
          <div
            onClick={() => !record.isDefault
              && actions.setAction({
                visible: true,
                type: 'setDefault',
                row: record,
              })}
          >
            <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isDefault ? '#00a1af' : ''} />
          </div>
        </StyledStar>
      );

      const deleteEmail = (
        <StyledDeleteIcon
          disabled={!record.isDefault ? 0 : 1}
          onClick={() => (!record.isDefault
            ? actions.setAction({
              visible: true,
              type: 'delete',
              row: record,
            })
            : '')}
        >
          <IconSvg icon="DeleteIcon" width="1.29rem" height="1.29rem" />
        </StyledDeleteIcon>
      );
      return (
        <TableActions>
          <Can type={types.edit} rule={`${resource.brands}.${resource.notificationSettings}`}>
            {!isBrRegulationAudit
              && (!record.isDefault ? (
                <Tooltip title={<Trans>default</Trans>} arrow={{ pointAtCenter: true }}>
                  {setDefault}
                </Tooltip>
              ) : (
                <>{setDefault}</>
              ))}
          </Can>
          <Can type={types.delete} rule={`${resource.brands}.${resource.notificationSettings}`}>
            {!isBrRegulationAudit
              && (record.isDefault ? (
                <>{deleteEmail}</>
              ) : (
                <Tooltip title={<Trans>delete</Trans>} arrow={{ pointAtCenter: true }}>
                  {deleteEmail}
                </Tooltip>
              ))}
          </Can>
        </TableActions>
      );
    },
  },
];

const conversion = (actions) => [
  {
    title: 'brand:currencyFrom',
    width: 200,
    dataIndex: 'currencyFromId',
    key: 'currencyFromId',
    align: 'left',
  },
  {
    title: 'brand:currencyTo',
    width: 200,
    dataIndex: 'currencyToId',
    key: 'currencyToId',
    align: 'left',
  },
  {
    title: 'rate',
    width: 200,
    dataIndex: 'rate',
    key: 'rate',
    align: 'left',
  },
  {
    title: 'logs',
    className: 'no-truncate',
    width: 200,
    key: 'logs',
    dataIndex: 'logs',
    align: 'left',
    render: (text, record) => {
      const { brandId, tab, routeKey } = actions.getRouterData();

      const query = {
        ...getQuery(window.location.search),
        tab,
        inside: JSON.stringify({
          from: record.currencyFromId,
          to: record.currencyToId,
        }),
      };

      return (
        <BlueBackgroundItem to={`/brands/${brandId}/${routeKey}?${qs.stringify(query)}`}>
          <HighlightedText>
            <Trans>view</Trans>
          </HighlightedText>
        </BlueBackgroundItem>
      );
    },
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.manage} rule={`${resource.brands}.${resource.conversionSettingsDepositAndWithdrawals}`}>
          {!isBrRegulationAudit && (
            <>
              <Tooltip title={<Trans>edit</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledAction
                  onClick={() => actions.setAction({
                    visible: true,
                    type: 'edit',
                    row: record,
                  })}
                >
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </StyledAction>
              </Tooltip>
              <Tooltip title={<Trans>delete</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledAction
                  onClick={() => actions.setAction({
                    visible: true,
                    type: 'delete',
                    row: record,
                  })}
                >
                  <IconSvg icon="DeleteIcon" width="1.14rem" height="1.14rem" />
                </StyledAction>
              </Tooltip>
            </>
          )}
        </Can>
      </TableActions>
    ),
  },
];
const othersConversion = (actions) => [
  {
    title: 'brand:currencyFrom',
    width: 200,
    dataIndex: 'currencyFromId',
    key: 'currencyFromId',
    align: 'left',
  },
  {
    title: 'brand:currencyTo',
    width: 200,
    dataIndex: 'currencyToId',
    key: 'currencyToId',
    align: 'left',
  },
  {
    title: 'rate',
    width: 200,
    dataIndex: 'rate',
    key: 'rate',
    align: 'left',
  },
  {
    title: 'logs',
    dataIndex: 'logs',
    className: 'no-truncate',
    width: 200,
    key: 'logs',
    align: 'left',
    render: (text, record) => {
      const { brandId, tab, routeKey } = actions.getRouterData();

      const query = {
        ...getQuery(window.location.search),
        tab,
        inside: JSON.stringify({
          from: record.currencyFromId,
          to: record.currencyToId,
        }),
      };

      return (
        <BlueBackgroundItem to={`/brands/${brandId}/${routeKey}?${qs.stringify(query)}`}>
          <HighlightedText>
            <Trans>view</Trans>
          </HighlightedText>
        </BlueBackgroundItem>
      );
    },
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.manage} rule={`${resource.brands}.${resource.conversionSettingsOthers}`}>
          {!isBrRegulationAudit && (
            <>
              <Tooltip title={<Trans>edit</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledAction
                  onClick={() => actions.setAction({
                    visible: true,
                    type: 'edit',
                    row: record,
                  })}
                >
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </StyledAction>
              </Tooltip>
              <Tooltip title={<Trans>delete</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledAction
                  onClick={() => actions.setAction({
                    visible: true,
                    type: 'delete',
                    row: record,
                  })}
                >
                  <IconSvg icon="DeleteIcon" width="1.14rem" height="1.14rem" />
                </StyledAction>
              </Tooltip>
            </>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const conversionLogs = () => [
  {
    title: 'logId',
    width: 300,
    dataIndex: 'id',
    key: 'id',
    align: 'left',
  },
  {
    title: 'rate',
    width: 300,
    dataIndex: 'rate',
    key: 'rate',
  },
  {
    title: 'dateUpdated',
    width: 300,
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    align: 'left',
    render: (updatedDate) => getFormattedDate(updatedDate, AppConstants.format.dateTime),
  },
  {
    title: 'updatedBy',
    width: 300,
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    align: 'right',
    render: (updatedBy) => (updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : null),
  },
];

const standards = (actions) => [
  {
    title: 'brand:feature',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 280,
    render: (text, record) => (
      <StyledStandardFeature>
        <div>
          <IconSvg icon={record.icon} width="1.44rem" height="1.44rem" />
        </div>
        <p>{record.name}</p>
      </StyledStandardFeature>
    ),
  },
  {
    title: 'description',
    key: 'description',
    dataIndex: 'description',
    align: 'left',
    render: (text, record) => <Description description={record.description} changes={record.changes} />,
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    width: 100,
    render: (text, record) => (
      <TableActions>
        <StyledSvgIconHover disabled={record.locked}>
          <Can type={types.statusChange} rule={`${resource.brands}.${resource.standards}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.enabled ? 'deactivate' : 'activate'}</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledSwitchSmall onClick={() => actions.setRowState({ row: record, type: 'activate', visible: true })} checked={record.enabled} size="small" disabled={record.locked} />
              </Tooltip>
            )}
          </Can>
        </StyledSvgIconHover>
      </TableActions>
    ),
  },
];

const blockedCountry = (actions) => [
  {
    title: 'country',
    dataIndex: 'country',
    key: 'id',
    align: 'left',
    render: (text, record) => (
      <StyledFlagWrapper>
        {record.id && (
          <StyledFlag className="f24">
            <div className={`${record.id.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.name}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'action',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.delete} rule={`${resource.brands}.${resource.locationServices}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>delete</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div
                  onClick={() => actions.setRowState({
                    visible: true,
                    type: 'delete',
                    row: record,
                  })}
                >
                  <IconSvg icon="DeleteIcon" color={record.isMain ? '#00a1af' : ''} />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const blockedIP = (actions) => [
  {
    title: 'ipAddress',
    key: 'id',
    dataIndex: 'id',
    align: 'left',
    render: (text, record) => record.id,
  },
  {
    title: 'action',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.delete} rule={`${resource.brands}.${resource.locationServices}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>delete</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div
                  onClick={() => actions.setRowState({
                    visible: true,
                    type: 'delete',
                    row: record,
                  })}
                >
                  <IconSvg icon="DeleteIcon" color={record.isMain ? '#00a1af' : ''} />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const largeTransactions = [
  {
    title: 'currency',
    key: 'currencyId',
    dataIndex: 'currencyId',
    align: 'left',
    width: '15rem',
    render: (actions, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'bet',
    key: 'bet',
    align: 'left',
    dataIndex: 'bet',
    edit: true,
    width: '15rem',
    isDouble: true,
    rules: [
      () => ({
        validator(l, value) {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          return Promise.resolve();
        },
      }),
    ],
    render: (actions, record) => (record.bet ? toFormatNumber(record.bet) : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'win',
    key: 'win',
    align: 'left',
    dataIndex: 'win',
    edit: true,
    width: '15rem',
    isDouble: true,
    rules: [
      () => ({
        validator(l, value) {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          return Promise.resolve();
        },
      }),
    ],
    render: (actions, record) => (record.win ? toFormatNumber(record.win) : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'deposit',
    key: 'deposit',
    align: 'left',
    dataIndex: 'deposit',
    edit: true,
    width: '15rem',
    isDouble: true,
    render: (actions, record) => (record.deposit ? toFormatNumber(record.deposit) : <CellAlign type="left">-</CellAlign>),
    rules: [
      () => ({
        validator(l, value) {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    title: 'withdrawal',
    key: 'withdrawal',
    align: 'left',
    dataIndex: 'withdrawal',
    edit: true,
    width: '15rem',
    isDouble: true,
    render: (actions, record) => (record.withdrawal ? toFormatNumber(record.withdrawal) : <CellAlign type="left">-</CellAlign>),
    rules: [
      () => ({
        validator(l, value) {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    title: 'brand:otherTransactions',
    key: 'other',
    align: 'left',
    dataIndex: 'other',
    edit: true,
    width: '15rem',
    isDouble: true,
    render: (actions, record) => (record.other ? toFormatNumber(record.other) : <CellAlign type="left">-</CellAlign>),
    rules: [
      () => ({
        validator(l, value) {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    className: 'edit-action',
    align: 'right',
    fixed: 'right',
    render: (actions, record) => {
      const {
        bet, deposit, withdrawal, other, win,
      } = record;

      const isResetDisabled = bet || deposit || withdrawal || other || win;

      return (
        <Can type={types.edit} rule={`${resource.brands}.${resource.largeTransactions}`}>
          {!isBrRegulationAudit && (
            <TableActions>
              <Tooltip placement="top" title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
                <StyledSvgIconHover
                  onClick={(e) => {
                    e.preventDefault();
                    actions.edit(record);
                  }}
                >
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </StyledSvgIconHover>
              </Tooltip>
              <Tooltip placement="top" title={i18n.t('reset')} arrow={{ pointAtCenter: true }}>
                <StyledSvgIconHover
                  disabled={!isResetDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    actions.setRowState({
                      visible: true,
                      row: record,
                      type: 'reset',
                    });
                  }}
                >
                  <div>
                    <IconSvg icon="ResetIcon" width="1.14rem" height="1.14rem" />
                  </div>
                </StyledSvgIconHover>
              </Tooltip>
            </TableActions>
          )}
        </Can>
      );
    },
  },
];

const largeTransactionLogs = () => [
  {
    title: 'logId',
    key: 'id',
    dataIndex: 'id',
    align: 'left',
  },
  {
    title: 'currency',
    key: 'currencyId',
    dataIndex: 'currencyId',
    align: 'left',
  },
  {
    title: 'transaction',
    key: 'type',
    dataIndex: 'type',
    align: 'left',
    render: (type) => getResourceValue('largeTransactionTypes', type, enumTypes.largeTransactionTypes),
  },
  {
    title: 'valueBefore',
    key: 'oldAmount',
    dataIndex: 'oldAmount',
    align: 'left',
    render: (oldAmount) => (!_.isNil(oldAmount) ? toFormatNumber(oldAmount) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'valueAfter',
    key: 'amount',
    dataIndex: 'amount',
    align: 'left',
    render: (amount) => (!_.isNil(amount) ? toFormatNumber(amount) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'dateUpdated',
    width: '10rem',
    key: 'createdDate',
    dataIndex: 'createdDate',
    align: 'left',
    render: (createdDate) => getFormattedDate(createdDate, AppConstants.format.dateTime),
  },
  {
    title: 'updatedBy',
    key: 'createdBy',
    dataIndex: 'createdBy',
    align: 'right',
  },
];

const playerLimits = () => [
  {
    title: 'brand:limitType',
    key: 'type',
    dataIndex: 'type',
    align: 'left',
    width: '100%',
  },
  {
    title: 'period',
    key: 'period',
    dataIndex: 'period',
    align: 'left',
    render: (actions, record) => {
      const can = Can({
        type: types.edit,
        rule: `${resource.brands}.${resource.playerLimits}`,
        children: false,
        fallback: true,
      }) || isBrRegulationAudit;
      return (
        <Form.Item name={record.id}>
          <Radio.Group disabled={can}>
            {Object.values(enumTypes.playerLimitsPeriods).map((el) => (
              <StyledRadioButtons key={+el}>
                <Radio.Button value={+el}>{getResourceValue('playerLimitsPeriods', el, enumTypes.playerLimitsPeriods)}</Radio.Button>
              </StyledRadioButtons>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    },
  },
];

const legalAgreements = (actions) => [
  {
    title: 'policy',
    key: 'policyName',
    dataIndex: 'policyName',
    align: 'left',
    disabled: true,
    fixed: 'left',
  },
  {
    title: 'version',
    key: 'versionText',
    dataIndex: 'versionText',
    align: 'left',
  },
  {
    title: 'status',
    key: 'status',
    dataIndex: 'status',
    align: 'left',
    render: (text, record) => (
      <StyledTagsWrapper width={1} color={colors[legalAgreementStatusesColors[record.status]].background}>
        <StyledTagsPointer color={legalAgreementStatusesColors[record.status]} />
        <Popover
          placement="bottomLeft"
          content={getResourceValue('legalAgreementVersionStatuses', record.status, enumTypes.legalAgreementVersionStatuses)}
          title=""
          overlayClassName="description-popover"
        >
          <StyledTruncate>{getResourceValue('legalAgreementVersionStatuses', record.status, enumTypes.legalAgreementVersionStatuses)}</StyledTruncate>
        </Popover>
      </StyledTagsWrapper>
    ),
  },
  {
    title: 'brand:playersNotifiedDate',
    key: 'playersNotifiedDate',
    dataIndex: 'playersNotifiedDate',
    align: 'left',
    render: (text, record) => (record.playersNotifiedDate ? getFormattedDate(record.playersNotifiedDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'effectiveDate',
    key: 'effectiveStartDate',
    dataIndex: 'effectiveStartDate',
    align: 'left',
    sorter: true,
    render: (text, record) => (record.effectiveStartDate ? getFormattedDate(record.effectiveStartDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'endDate',
    key: 'effectiveEndDate',
    dataIndex: 'effectiveEndDate',
    align: 'left',
    render: (text, record) => (record.effectiveEndDate ? getFormattedDate(record.effectiveEndDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'brand:mandatoryNotification',
    key: 'mandatoryNotification',
    dataIndex: 'mandatoryNotification',
    align: 'left',
    render: (mandatoryNotification) => i18n.t(enableDisableState[+mandatoryNotification]),
  },
  {
    title: 'creationDate',
    key: 'createdDate',
    dataIndex: 'createdDate',
    align: 'left',
    sorter: true,
    render: (text, record) => (record.createdDate ? getFormattedDate(record.createdDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'createdBy',
    key: 'createdBy',
    dataIndex: 'createdBy',
    align: 'left',
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.edit} rule={`${resource.brands}.${resource.legal}`}>
          {!isBrRegulationAudit && (
            <Tooltip placement="top" title={<Trans>edit</Trans>}>
              {record.status === enumTypes.legalAgreementVersionStatuses.Pending ? (
                <StyledDeleteIcon
                  onClick={() => {
                    actions.setRowState({
                      row: record,
                      type: 'edit',
                      visible: true,
                    });
                  }}
                >
                  <StyledSvgIconHover>
                    <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                  </StyledSvgIconHover>
                </StyledDeleteIcon>
              ) : (
                <StyledSvgIconHover disabled>
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </StyledSvgIconHover>
              )}
            </Tooltip>
          )}
        </Can>
        <Can type={types.delete} rule={`${resource.brands}.${resource.legal}`}>
          {!isBrRegulationAudit && (
            <Tooltip placement="top" title={<Trans>delete</Trans>}>
              {record.status === enumTypes.legalAgreementVersionStatuses.Pending ? (
                <StyledDeleteIcon
                  onClick={() => {
                    actions.setRowState({
                      row: record,
                      type: 'delete',
                      visible: true,
                    });
                  }}
                >
                  <StyledSvgIconHover>
                    <IconSvg icon="DeleteIcon" />
                  </StyledSvgIconHover>
                </StyledDeleteIcon>
              ) : (
                <StyledSvgIconHover disabled>
                  <IconSvg icon="DeleteIcon" />
                </StyledSvgIconHover>
              )}
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const paymentMethodsActionsCount = getAllowedActionsCount(actionsPermissions.brandPaymentMethods);

const paymentMethods = () => [
  {
    title: 'paymentMethod',
    key: 'paymentMethodName',
    dataIndex: 'paymentMethodName',
    align: 'left',
    width: 140,
  },
  {
    title: 'methodType',
    key: 'paymentType',
    dataIndex: 'paymentType',
    align: 'left',
    width: 80,
    render: (type) => getResourceValue('paymentType', type, enumTypes.paymentType) ?? <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'cms:depositState',
    dataIndex: 'isDepositEnabled',
    key: 'isDepositEnabled',
    width: 120,
    render: (actions, record) => i18n.t(depositWithdrawalState[+record.isDepositActive]),
  },
  {
    title: 'cms:withdrawalState',
    dataIndex: 'isWithdrawalEnabled',
    key: 'isWithdrawalEnabled',
    width: 140,
    render: (actions, record) => i18n.t(depositWithdrawalState[+record.isWithdrawalActive]),
  },
  {
    title: 'brand:depositProcessingType',
    key: 'depositProcessingType',
    dataIndex: 'depositProcessingType',
    align: 'left',
    width: 190,
    render: (type) => getResourceValue('processingTypes', type, enumTypes.processingTypes) ?? <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'brand:withdrawalProcessingType',
    key: 'withdrawalProcessingType',
    dataIndex: 'withdrawalProcessingType',
    align: 'left',
    width: 210,
    render: (type) => getResourceValue('processingTypes', type, enumTypes.processingTypes) ?? <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'systemState',
    key: 'isPaymentMethodActive',
    dataIndex: 'isPaymentMethodActive',
    align: 'left',
    width: 120,
    render: (isPaymentMethodActive) => i18n.t(isPaymentMethodActive ? 'enabled' : 'disabled'),
  },
  {
    title: 'brand:allowedActivities',
    key: 'activities',
    dataIndex: 'activities',
    align: 'left',
    width: 150,
    render: (activities) => (activities === enumTypes.paymentActivities.Both
      ? [
        getResourceValue('paymentActivities', enumTypes.paymentActivities.Withdrawal, enumTypes.paymentActivities),
        getResourceValue('paymentActivities', enumTypes.paymentActivities.Deposit, enumTypes.paymentActivities),
      ].join(', ')
      : getResourceValue('paymentActivities', activities, enumTypes.paymentActivities)),
  },
  {
    title: 'brand:withdrawalSupportedCurrencies',
    key: 'withdrawalSupportedCurrencies',
    dataIndex: 'withdrawalSupportedCurrencies',
    align: 'left',
    width: 240,
    render: (supportedCurrencies) => {
      const data = supportedCurrencies && supportedCurrencies.map((el) => ({ id: el, name: el }));
      return data?.length ? <More data={data} /> : <CellAlign type="left">-</CellAlign>;
    },
  },
  {
    title: 'brand:depositSupportedCurrencies',
    key: 'depositSupportedCurrencies',
    dataIndex: 'depositSupportedCurrencies',
    align: 'left',
    width: 220,
    render: (supportedCurrencies) => {
      const data = supportedCurrencies && supportedCurrencies.map((el) => ({ id: el, name: el }));
      return data?.length ? <More data={data} /> : <CellAlign type="left">-</CellAlign>;
    },
  },
  {
    title: 'brand:supportedCountries',
    key: 'countries',
    dataIndex: 'countries',
    align: 'left',
    width: 160,
    render: (countries) => {
      const data = countries && countries.map((el) => ({ id: el, name: el }));
      return data?.length ? <More data={data} /> : <CellAlign type="left">-</CellAlign>;
    },
  },
  {
    title: 'brand:depositPaymentCategory',
    key: 'depositSystemCategory',
    dataIndex: 'depositSystemCategory',
    align: 'left',
    width: 200,
    render: (depositSystemCategory) => (!_.isNull(depositSystemCategory) ? depositSystemCategory.name : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'brand:withdrawalPaymentCategory',
    key: 'withdrawalSystemCategory',
    dataIndex: 'withdrawalSystemCategory',
    align: 'left',
    width: 220,
    render: (withdrawalSystemCategory) => (!_.isNull(withdrawalSystemCategory) ? withdrawalSystemCategory.name : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'logs',
    key: 'logs',
    dataIndex: 'logs',
    align: 'left',
    className: 'no-truncate',
    width: 70,
    render: (actions, record) => (
      <BlueBackgroundItem to={`/brands/${record.brandId}/paymentMethodLogs?paymentMethodId=${record.paymentMethodId}`}>
        <HighlightedText>
          <Trans>view</Trans>
        </HighlightedText>
      </BlueBackgroundItem>
    ),
  },
  {
    title: 'dateAdded',
    key: 'createdDate',
    dataIndex: 'createdDate',
    align: 'left',
    width: 110,
    render: (actions, record) => (record.createdDate ? getFormattedDate(record.createdDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'lastUpdateDate',
    key: 'updatedDate',
    dataIndex: 'updatedDate',
    align: 'left',
    width: 140,
    render: (actions, record) => (record.updatedDate ? getFormattedDate(record.updatedDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'lastUpdateBy',
    key: 'updatedBy',
    dataIndex: 'updatedBy',
    align: 'left',
    width: 130,
    render: (updatedBy) => (updatedBy ? (
      <Popover placement="bottomLeft" content={`${updatedBy ? updatedBy.firstName : ''} ${updatedBy ? updatedBy.lastName : ''}`} title="" overlayClassName="description-popover">
        <StyledTruncate>{`${updatedBy ? updatedBy.firstName : ''} ${updatedBy ? updatedBy.lastName : ''}`}</StyledTruncate>
      </Popover>
    ) : (
      <CellAlign>-</CellAlign>
    )),
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    align: 'right',
    fixed: 'right',
    width: AppConstants.actionsWidth.defaultIcon * paymentMethodsActionsCount,
    setInitialWidth: true,
    render: (actions, record) => {
      const query = {
        ...getQuery(window.location.search),
        paymentMethodId: record.paymentMethodId,
        tab: undefined,
      };
      return (
        <TableActions>
          <Can type={types.view} rule={`${resource.brands}.${resource.payments}`}>
            <Tooltip placement="top" title={<Trans>view</Trans>}>
              <StyledDeleteIcon onMouseDown={(e) => handleNavigate(e, `/brands/${record.brandId}/paymentMethod?${qs.stringify(query)}`)}>
                <StyledSvgIconHover>
                  <IconSvg icon="PreviewIcon" width="1.4rem" />
                </StyledSvgIconHover>
              </StyledDeleteIcon>
            </Tooltip>
          </Can>
          <Can type={types.edit} rule={`${resource.brands}.${resource.payments}`}>
            {!isBrRegulationAudit && (
              <Tooltip placement="top" title={<Trans>configure</Trans>}>
                <StyledSvgIconHover onMouseDown={(e) => handleNavigate(e, `/brands/${record.brandId}/configurePayment?${qs.stringify({ ...query, paymentType: record.paymentType })}`)}>
                  <IconSvg icon="ConfigureIcon" width="1.14rem" height="1.14rem" />
                </StyledSvgIconHover>
              </Tooltip>
            )}
          </Can>
        </TableActions>
      );
    },
  },
];

const paymentMethodsFees = () => [
  {
    title: 'fee',
    key: 'feesType',
    dataIndex: 'feesType',
    align: 'left',
    sorter: true,
    render: (feesType) => getResourceValue('paymentMethodsFeesTypes', feesType, enumTypes.paymentMethodsFeesTypes),
  },
  {
    title: 'brand:feeBefore',
    key: 'feeBefore',
    dataIndex: 'feeBefore',
    align: 'left',
    render: (feeBefore) => (!_.isNull(feeBefore) ? feeBefore : <CellAlign>-</CellAlign>),
  },
  {
    title: 'brand:newFee',
    key: 'newFee',
    dataIndex: 'newFee',
    align: 'left',
    render: (newFee) => (!_.isNull(newFee) ? newFee : <CellAlign>-</CellAlign>),
  },
  {
    title: 'dateUpdated',
    width: 300,
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    align: 'left',
    render: (updatedDate) => getFormattedDate(updatedDate, AppConstants.format.dateTime),
  },
  {
    title: 'updatedBy',
    width: 300,
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    align: 'right',
    render: (updatedBy) => (updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : null),
  },
];

const paymentMethodsLimits = () => [
  {
    title: 'limit',
    key: 'limitType',
    dataIndex: 'limitType',
    align: 'left',
    sorter: true,
    render: (limitType) => getResourceValue('paymentMethodLimitTypes', limitType, enumTypes.paymentMethodLimitTypes),
  },
  {
    title: 'currency',
    key: 'currencyId',
    dataIndex: 'currencyId',
    align: 'left',
  },
  {
    title: 'limitBefore',
    key: 'limitBefore',
    dataIndex: 'limitBefore',
    align: 'left',
    render: (limitBefore) => (!_.isNil(limitBefore) ? toFormatNumber(limitBefore) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'brand:newLimit',
    key: 'newLimit',
    dataIndex: 'newLimit',
    align: 'left',
    render: (newLimit) => (!_.isNil(newLimit) ? toFormatNumber(newLimit) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'dateUpdated',
    width: 300,
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    align: 'left',
    render: (updatedDate) => getFormattedDate(updatedDate, AppConstants.format.dateTime),
  },
  {
    title: 'updatedBy',
    width: 300,
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    align: 'right',
    render: (updatedBy) => (updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : i18n.t('system')),
  },
];

const validator = (value, fieldName, min, max) => {
  if (!_.isEmpty(value)) {
    if (_.isNumber(max) && _.isNumber(min) && (value > max || value < min)) {
      return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:minMax', { fieldName: translate(fieldName), min, max })} />);
    }
    if (_.isNumber(max) && _.isNull(min) && value > max) {
      return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:mustBeLowerThan', { fieldName: translate(fieldName), max })} />);
    }
    if (_.isNumber(min) && _.isNull(max) && value < min) {
      return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:mustBeGreaterThan', { fieldName: translate(fieldName), min })} />);
    }
  }
  return Promise.resolve();
};

const getCurrencyValue = (value) => (!_.isNil(value) ? toFormatNumber(value) : '-');

const replacePaymentCurrencySettings = {
  [enumTypes.paymentActivities.Withdrawal]: [
    {
      title: 'brand:minMaxWithdrawalAmount',
      dataIndex: 'systemMinMaxAmount',
      key: 'systemMinMaxAmount',
      align: 'left',
      width: '20em',
      render: (actions, record) => <CellAlign type="left">{`${getCurrencyValue(record.systemMinAmount)} ~ ${getCurrencyValue(record.systemMaxAmount)}`}</CellAlign>,
    },
    {
      title: 'brand:minWithdrawalAmount',
      dataIndex: 'minAmount',
      key: 'minAmount',
      edit: true,
      align: 'left',
      width: '20em',
      isDouble: true,
      rules: (record) => [
        ({ getFieldValue }) => ({
          validator: (rule, value) => {
            if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
              return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
            }
            if (value && getFieldValue('maxAmount') && value > +getFieldValue('maxAmount')) {
              return Promise.reject(
                <FormItemErrorMessage
                  errorMassage={(translate) => translate('validate:mustBeLowerOrEqual', { fieldMin: translate('brand:minWithdrawalAmount'), fieldMax: translate('brand:maxWithdrawalAmount') })}
                />,
              );
            }
            return validator(value, 'brand:minWithdrawalAmount', record.systemMinAmount, record.systemMaxAmount);
          },
        }),
      ],
      render: (actions, record) => {
        const isMisconfigured = !_.isNull(record.systemMinAmount) && !_.isNull(record.minAmount) ? record.minAmount < record.systemMinAmount : false;
        return (
          <>
            {!_.isNull(record.minAmount) ? (
              <StyledCurrencyInfo isMisconfigured={isMisconfigured ? 1 : 0}>
                {isMisconfigured && (
                  <Tooltip title={i18n.t('brand:conversionSettingsInfo')} arrow={{ pointAtCenter: true }}>
                    <TooltipIconWrapper>
                      <IconSvg icon="InfoIcon" width="16" height="16" />
                    </TooltipIconWrapper>
                  </Tooltip>
                )}
                <span>{record.minAmount}</span>
              </StyledCurrencyInfo>
            ) : (
              <CellAlign type="left">-</CellAlign>
            )}
          </>
        );
      },
    },
    {
      title: 'brand:maxWithdrawalAmount',
      dataIndex: 'maxAmount',
      key: 'maxAmount',
      edit: true,
      align: 'left',
      width: '20rem',
      isDouble: true,
      rules: (record) => [
        ({ getFieldValue }) => ({
          validator: (rule, value) => {
            if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
              return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
            }
            if (value && getFieldValue('minAmount') && value < +getFieldValue('minAmount')) {
              return Promise.reject(
                <FormItemErrorMessage
                  errorMassage={(translate) => translate('validate:mustBeGreaterOrEqual', { fieldMin: translate('brand:minWithdrawalAmount'), fieldMax: translate('brand:maxWithdrawalAmount') })}
                />,
              );
            }

            return validator(value, 'brand:maxWithdrawalAmount', record.systemMinAmount, record.systemMaxAmount);
          },
        }),
      ],
      render: (actions, record) => {
        const isMisconfigured = !_.isNull(record.systemMaxAmount) && !_.isNull(record.maxAmount) ? record.maxAmount > record.systemMaxAmount : false;
        return (
          <>
            {!_.isNull(record.maxAmount) ? (
              <StyledCurrencyInfo isMisconfigured={isMisconfigured ? 1 : 0}>
                {isMisconfigured && (
                  <Tooltip title={i18n.t('brand:conversionSettingsInfo')} arrow={{ pointAtCenter: true }}>
                    <TooltipIconWrapper>
                      <IconSvg icon="InfoIcon" width="16" height="16" />
                    </TooltipIconWrapper>
                  </Tooltip>
                )}
                <span>{record.maxAmount}</span>
              </StyledCurrencyInfo>
            ) : (
              <CellAlign type="left">-</CellAlign>
            )}
          </>
        );
      },
    },
  ],
  [enumTypes.paymentActivities.Deposit]: [
    {
      title: 'brand:minMaxDepositAmount',
      dataIndex: 'systemMinMaxAmount',
      key: 'systemMinMaxAmount',
      align: 'left',
      width: '20em',
      render: (actions, record) => <CellAlign type="left">{`${getCurrencyValue(record.systemMinAmount)} ~ ${getCurrencyValue(record.systemMaxAmount)}`}</CellAlign>,
    },
    {
      title: 'brand:minDepositAmount',
      dataIndex: 'minAmount',
      key: 'minAmount',
      edit: true,
      align: 'left',
      width: '20em',
      isDouble: true,
      render: (actions, record) => {
        const isMisconfigured = !_.isNull(record.systemMinAmount) && !_.isNull(record.minAmount) ? record.minAmount < record.systemMinAmount : false;
        return (
          <>
            {!_.isNull(record.minAmount) ? (
              <StyledCurrencyInfo isMisconfigured={isMisconfigured ? 1 : 0}>
                {isMisconfigured && (
                  <Tooltip title={i18n.t('brand:conversionSettingsInfo')} arrow={{ pointAtCenter: true }}>
                    <TooltipIconWrapper>
                      <IconSvg icon="InfoIcon" width="16" height="16" />
                    </TooltipIconWrapper>
                  </Tooltip>
                )}
                <span>{record.minAmount}</span>
              </StyledCurrencyInfo>
            ) : (
              <CellAlign type="left">-</CellAlign>
            )}
          </>
        );
      },
      rules: (record) => [
        ({ getFieldValue }) => ({
          validator: (rule, value) => {
            if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
              return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
            }
            if (value && getFieldValue('maxAmount') && value > +getFieldValue('maxAmount')) {
              return Promise.reject(
                <FormItemErrorMessage
                  errorMassage={(translate) => translate('validate:mustBeLowerOrEqual', { fieldMin: translate('brand:minDepositAmount'), fieldMax: translate('brand:maxDepositAmount') })}
                />,
              );
            }
            return validator(value, 'brand:minDepositAmount', record.systemMinAmount, record.systemMaxAmount);
          },
        }),
      ],
    },
    {
      title: 'brand:maxDepositAmount',
      dataIndex: 'maxAmount',
      key: 'maxAmount',
      edit: true,
      align: 'left',
      width: '20rem',
      isDouble: true,
      render: (actions, record) => {
        const isMisconfigured = !_.isNull(record.systemMaxAmount) && !_.isNull(record.maxAmount) ? record.maxAmount > record.systemMaxAmount : false;
        return (
          <>
            {!_.isNull(record.maxAmount) ? (
              <StyledCurrencyInfo isMisconfigured={isMisconfigured ? 1 : 0}>
                {isMisconfigured && (
                  <Tooltip title={i18n.t('brand:conversionSettingsInfo')} arrow={{ pointAtCenter: true }}>
                    <TooltipIconWrapper>
                      <IconSvg icon="InfoIcon" width="16" height="16" />
                    </TooltipIconWrapper>
                  </Tooltip>
                )}
                <span>{record.maxAmount}</span>
              </StyledCurrencyInfo>
            ) : (
              <CellAlign type="left">-</CellAlign>
            )}
          </>
        );
      },
      rules: (record) => [
        ({ getFieldValue }) => ({
          validator: (rule, value) => {
            if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
              return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
            }
            if (value && getFieldValue('minAmount') && value < +getFieldValue('minAmount')) {
              return Promise.reject(
                <FormItemErrorMessage
                  errorMassage={(translate) => translate('validate:mustBeGreaterOrEqual', { fieldMin: translate('brand:minDepositAmount'), fieldMax: translate('brand:maxDepositAmount') })}
                />,
              );
            }
            return validator(value, 'brand:maxDepositAmount', record.systemMinAmount, record.systemMaxAmount);
          },
        }),
      ],
    },
  ],
};

const paymentCurrencySettings = [
  {
    title: 'currency',
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    width: '10rem',
    render: (actions, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    className: 'edit-action',
    align: 'right',
    render: (actions, record) => {
      const { minAmount, maxAmount, isDisabled } = record;

      const isResetActive = minAmount || maxAmount;
      return (
        <ActionsTextItems>
          <Button
            disabled={isDisabled}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              actions.edit(record);
            }}
          >
            <Tooltip placement="top" title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div>
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          </Button>
          <Button
            disabled={isDisabled || !isResetActive}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              actions.reset(record);
            }}
          >
            <Tooltip placement="top" title={i18n.t('reset')} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div>
                  <IconSvg icon="ResetIcon" width="1.14rem" height="1.14rem" />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          </Button>
        </ActionsTextItems>
      );
    },
  },
];

const paymentMethodCurrencySettings = [
  {
    title: 'currency',
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    width: '10rem',
    render: (actions, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'brand:minMaxDepositAmount',
    key: 'systemMinMaxDepositAmount',
    dataIndex: 'systemMinMaxDepositAmount',
    align: 'left',
    width: '20em',
    render: (actions, record) => <CellAlign type="left">{`${getCurrencyValue(record.systemMinDepositAmount)} ~ ${getCurrencyValue(record.systemMaxDepositAmount)}`}</CellAlign>,
  },
  {
    title: 'brand:minMaxWithdrawalAmount',
    key: 'systemMinMaxWithdrawalAmount',
    dataIndex: 'systemMinMaxWithdrawalAmount',
    align: 'left',
    width: '20em',
    render: (actions, record) => <CellAlign type="left">{`${getCurrencyValue(record.systemMinWithdrawalAmount)} ~ ${getCurrencyValue(record.systemMaxWithdrawalAmount)}`}</CellAlign>,
  },
  {
    title: 'brand:minDepositAmount',
    dataIndex: 'minDepositAmount',
    key: 'minDepositAmount',
    edit: true,
    align: 'left',
    width: '20em',
    render: (actions, record) => (!_.isNil(record.minDepositAmount) ? record.minDepositAmount : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'brand:maxDepositAmount',
    dataIndex: 'maxDepositAmount',
    key: 'maxDepositAmount',
    edit: true,
    align: 'left',
    width: '20rem',
    render: (actions, record) => (!_.isNil(record.maxDepositAmount) ? record.maxDepositAmount : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'brand:minWithdrawalAmount',
    dataIndex: 'minWithdrawalAmount',
    key: 'minWithdrawalAmount',
    edit: true,
    align: 'left',
    width: '20em',
    render: (actions, record) => (!_.isNil(record.minWithdrawalAmount) ? record.minWithdrawalAmount : <CellAlign type="left">-</CellAlign>),
  },
  {
    title: 'brand:maxWithdrawalAmount',
    dataIndex: 'maxWithdrawalAmount',
    key: 'maxWithdrawalAmount',
    edit: true,
    align: 'right',
    width: '20rem',
    render: (actions, record) => (!_.isNil(record.maxWithdrawalAmount) ? record.maxWithdrawalAmount : <CellAlign type="left">-</CellAlign>),
  },
];

const seo = () => [
  {
    title: 'languages',
    key: 'languageId',
    dataIndex: 'languageId',
    align: 'left',
    width: 200,
    render: (actions, record) => (
      <StyledFlagWrapper>
        <StyledFlag className="f24">
          <div className={`${record.languageId && record.languageId.toLowerCase()} lang flag`} />
        </StyledFlag>
        <strong>{actions.getLanguageName(record.languageId)}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: (
      <StyledTheadWrapper>
        <span>{i18n.t('title')}</span>
        <Tooltip title={i18n.t('brand:pageTitleInfo')} arrow={{ pointAtCenter: true }}>
          <TooltipIconWrapper>
            <IconSvg icon="InfoIcon" width="16" height="16" />
          </TooltipIconWrapper>
        </Tooltip>
      </StyledTheadWrapper>
    ),
    key: 'title',
    align: 'left',
    dataIndex: 'title',
    edit: true,
    width: 140,
    render: (actions, record) => (record.title ? (
      <Popover placement="bottomLeft" content={record.title} title="" overlayClassName="description-popover">
        {record.title}
      </Popover>
    ) : (
      <CellAlign>-</CellAlign>
    )),
    rules: [
      {
        pattern: AppConstants.regex.notOnlySpaces1To200,
        message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:notOnlySpaces1toMax', { fieldName: translate('title'), max: 200 })} />,
      },
    ],
  },
  {
    title: (
      <StyledTheadWrapper>
        <span>{i18n.t('description')}</span>
        <Tooltip title={i18n.t('brand:pageDescriptionInfo')} arrow={{ pointAtCenter: true }}>
          <TooltipIconWrapper>
            <IconSvg icon="InfoIcon" width="16" height="16" />
          </TooltipIconWrapper>
        </Tooltip>
      </StyledTheadWrapper>
    ),
    key: 'description',
    align: 'left',
    dataIndex: 'description',
    edit: true,
    width: 140,
    rules: [
      {
        pattern: AppConstants.regex.notOnlySpaces1To4000,
        message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:notOnlySpaces1toMax', { fieldName: translate('description'), max: 4000 })} />,
      },
    ],
    component: TextArea,
    props: { autoSize: { minRows: 4, maxRows: 3 } },
    render: (actions, record) => (record.description ? (
      <Popover placement="bottomLeft" content={record.description} title="" overlayClassName="description-popover">
        {record.description}
      </Popover>
    ) : (
      <CellAlign>-</CellAlign>
    )),
  },
  {
    title: (
      <StyledTheadWrapper>
        <span>{i18n.t('brand:keywords')}</span>
        <Tooltip title={i18n.t('brand:seoKeywordsInfo')} arrow={{ pointAtCenter: true }}>
          <TooltipIconWrapper>
            <IconSvg icon="InfoIcon" width="16" height="16" />
          </TooltipIconWrapper>
        </Tooltip>
      </StyledTheadWrapper>
    ),
    key: 'keywords',
    align: 'left',
    dataIndex: 'keywords',
    edit: true,
    width: 140,
    rules: [
      {
        validator: (rule, value) => {
          if (value && value.split(',').some((el) => el && !new RegExp(AppConstants.regex.onlyEnter).test(el) && !new RegExp(AppConstants.regex.notOnlySpaces1To100).test(el))) {
            return Promise.reject(
              <FormItemErrorMessage errorMassage={(translate) => translate('validate:notOnlySpaces1toMaxSeparateByComma', { fieldName: translate('brand:eachKeyword'), max: 100 })} />,
            );
          }
          return Promise.resolve();
        },
      },
    ],
    component: TextArea,
    props: { autoSize: { minRows: 4, maxRows: 3 } },
    render: (actions, record) => {
      const data = record.keywords && record.keywords.split(',').map((el) => ({ id: el, name: el }));
      return data?.length ? <More data={data} /> : <CellAlign type="left">-</CellAlign>;
    },
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    className: 'edit-action',
    align: 'right',
    width: 100,
    fixed: 'right',
    render: (actions, record, index) => (
      <Can type={types.edit} rule={`${resource.brands}.${resource.seoSettings}`}>
        {!isBrRegulationAudit && (
          <TableActions>
            <StyledSvgIconHover
              type="button"
              onClick={(e) => {
                e.preventDefault();
                actions.edit(record);
              }}
            >
              <Tooltip placement="top" title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
                <div>
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </div>
              </Tooltip>
            </StyledSvgIconHover>
            <StyledSvgIconHover
              type="button"
              onClick={(e) => {
                e.preventDefault();
                actions.reset(index);
              }}
            >
              <Tooltip placement="top" title={i18n.t('reset')} arrow={{ pointAtCenter: true }}>
                <div>
                  <IconSvg icon="ResetIcon" width="1.14rem" height="1.14rem" />
                </div>
              </Tooltip>
            </StyledSvgIconHover>
          </TableActions>
        )}
      </Can>
    ),
  },
];

const taxes = (actions) => [
  {
    title: 'currency',
    width: 200,
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    render: (text, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'brand:ranges',
    key: 'count',
    dataIndex: 'count',
    align: 'left',
    render: (text, record) => record.count || <CellAlign type="left">-</CellAlign>,
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    width: 50,
    align: 'right',
    fixed: 'right',
    render: (text, record) => {
      const rule = actions.getIsDeposit() ? `${resource.brands}.${resource.taxOnDeposit}` : `${resource.brands}.${resource.taxOnWithdrawal}`;
      return (
        <TableActions>
          <Can type={types.view} rule={rule}>
            <Tooltip placement="top" title={<Trans>view</Trans>}>
              <StyledSvgIconHover
                disabled={!record.count}
                onClick={() => (!record.count
                  ? null
                  : actions.setAction({
                    visible: true,
                    row: record,
                    type: 'view',
                  }))}
              >
                <IconSvg icon="PreviewIcon" width="1.4rem" />
              </StyledSvgIconHover>
            </Tooltip>
          </Can>
          {record.isEditable && (
            <>
              <Can type={types.edit} rule={rule}>
                {!isBrRegulationAudit && (
                  <Tooltip title={i18n.t('reset')} arrow={{ pointAtCenter: true }}>
                    <StyledSvgIconHover
                      disabled={!record.count}
                      onClick={() => (!record.count
                        ? null
                        : actions.setAction({
                          visible: true,
                          row: record,
                          type: 'reset',
                        }))}
                    >
                      <IconSvg icon="ResetIcon" width="1.14rem" height="1.14rem" />
                    </StyledSvgIconHover>
                  </Tooltip>
                )}
              </Can>
              <Can type={types.edit} rule={rule}>
                {!isBrRegulationAudit && (
                  <Tooltip title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
                    <StyledSvgIconHover
                      onClick={() => {
                        actions.setAction({
                          visible: true,
                          row: record,
                          type: 'edit',
                        });
                      }}
                    >
                      <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                    </StyledSvgIconHover>
                  </Tooltip>
                )}
              </Can>
            </>
          )}
        </TableActions>
      );
    },
  },
];

const taxDepositView = () => [
  {
    title: <LabelInfo label={i18n.t('brand:depositAmountRanges')} title={i18n.t('brand:depositAmountRangesHint')} />,
    dataIndex: 'amount',
    key: 'amount',
    align: 'left',
    render: (text, record) => (
      <StyledRangeWrapper>
        <span>{`${record.amount} - `}</span>
        {record.amountEnd || <IconSvg icon="InfinityIcon" />}
      </StyledRangeWrapper>
    ),
  },
  {
    title: <LabelInfo label={i18n.t('bonus:fixedAmount')} title={i18n.t('brand:fixedAmountHint')} />,
    dataIndex: 'fixedAmount',
    key: 'fixedAmount',
    align: 'left',
  },
  {
    title: 'brand:taxPercentage',
    dataIndex: 'percent',
    key: 'percent',
    align: 'left',
  },
];

const taxWithdrawalView = (brandTaxDda) => [
  {
    title: <LabelInfo label={i18n.t('brand:withdrawalAmountRanges')} title={i18n.t('brand:withdrawalAmountRangesHint')} />,
    dataIndex: 'amount',
    key: 'amount',
    align: 'left',
    render: (text, record) => (
      <StyledRangeWrapper>
        <span>{`${record.amount} - `}</span>
        {record.amountEnd || <IconSvg icon="InfinityIcon" />}
      </StyledRangeWrapper>
    ),
  },
  {
    title: <LabelInfo label={i18n.t('bonus:fixedAmount')} title={i18n.t('brand:fixedAmountHint')} />,
    dataIndex: 'fixedAmount',
    key: 'fixedAmount',
    align: 'left',
    hidden: !!brandTaxDda,
  },
  {
    title: 'brand:taxPercentage',
    dataIndex: 'percent',
    key: 'percent',
    align: 'left',
  },
];

const sportsBookWidgets = (actions) => [
  {
    title: 'widgets',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 370,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>{record.icon}</div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    key: 'description',
    align: 'left',
    dataIndex: 'description',
    className: 'no-truncate no-header-separator',
  },
  {
    title: 'action',
    key: 'actions',
    align: 'right',
    fixed: 'right',
    dataIndex: 'actions',
    width: 80,
    render: (text, record) => (
      <TableActions>
        <Can type={types.edit} rule={`${resource.brands}.${resource.sportsBook}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>{record.enabled ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledSwitchSmall onClick={() => actions.setAction({ data: record, type: record.actionKey, visible: true })} checked={record.enabled} />
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const sportsBookSettings = (actions) => [
  {
    title: 'brand:configs',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 370,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>{record.icon}</div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    key: 'description',
    dataIndex: 'description',
    align: 'left',
    className: 'no-truncate no-header-separator',
  },
  {
    title: '',
    key: 'actions',
    align: 'right',
    fixed: 'right',
    dataIndex: 'actions',
    width: 80,
    render: (text, record) => (
      <TableActions>
        <div>
          <Can type={types.edit} rule={`${resource.brands}.${resource.sportsBook}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.enabled ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledSwitchSmall onClick={() => actions.setAction({ data: record, type: record.actionKey, visible: true })} checked={record.enabled} />
              </Tooltip>
            )}
          </Can>
        </div>
      </TableActions>
    ),
  },
];

const sportsBookMobileIntegration = (actions) => [
  {
    title: 'brand:sportsBookMobileIntegrationType',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 370,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>{record.icon}</div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    key: 'description',
    align: 'left',
    dataIndex: 'description',
    className: 'no-truncate no-header-separator',
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    width: 80,
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <Can type={types.edit} rule={`${resource.brands}.${resource.sportsBook}`}>
        {!isBrRegulationAudit && (
          <TableActions>
            <StyledStar disabled={record.isActive}>
              <div
                onClick={() => {
                  actions.setAction({
                    visible: true,
                    type: 'integrationTypeChange',
                    data: record,
                  });
                }}
              >
                <Tooltip title={<Trans>{i18n.t('brand:setAsDefault')}</Trans>} arrow={{ pointAtCenter: true }}>
                  <div>
                    <IconSvg icon="StarIcon" width="1.28rem" height="1.28rem" color={record.isActive ? '#00a1af' : ''} />
                  </div>
                </Tooltip>
              </div>
            </StyledStar>
          </TableActions>
        )}
      </Can>
    ),
  },
];

const seoPages = [
  {
    title: '',
    key: 'name',
    align: 'left',
    dataIndex: 'name',
  },
];

const unverifiedPlayersDepositLimits = [
  {
    title: 'currencyId',
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    render: (currencyId) => (
      <StyledFlagWrapper>
        {currencyId && (
          <StyledFlag className="f24">
            <div className={`${currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'maxAmount',
    key: 'maximumAmount',
    dataIndex: 'maximumAmount',
    align: 'left',
    width: 240,
    render: (maximumAmount, record) => (!_.isNil(maximumAmount) ? <span title={toFormatNumber(maximumAmount)}>{toFormatNumberByCurrency(maximumAmount, record.currencyId)}</span> : <CellAlign type="left">-</CellAlign>),
  },
];

const preferences = (actions) => [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 280,
    render: (text, record) => (
      <StyledStandardFeature>
        <div>
          <IconSvg icon={record.icon} />
        </div>
        <p>{i18n.t(record.name)}</p>
      </StyledStandardFeature>
    ),
  },
  {
    title: '',
    key: 'description',
    dataIndex: 'description',
    className: 'no-header-separator',
    align: 'left',
    render: (text, record) => <Description description={i18n.t(record.description)} />,
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    width: 100,
    render: (text, record) => (
      <Can type={types.edit} rule={`${resource.brands}.${resource.notificationSettings}`}>
        {!isBrRegulationAudit && (
          <Tooltip title={<Trans>{record.enabled ? 'deactivate' : 'activate'}</Trans>} arrow={{ pointAtCenter: true }}>
            <StyledSwitchSmall onClick={() => actions.setRowState({ row: record, type: 'activate', visible: true })} checked={record.enabled} size="small" />
          </Tooltip>
        )}
      </Can>
    ),
  },
];

const sportsBookWalletsSettings = () => [
  {
    title: 'brand:features',
    key: 'key',
    align: 'left',
    dataIndex: 'key',
    width: '30%',
    render: (key) => i18n.t(sportsBookFeaturesWallets[key]),
  },
  {
    title: 'brand:wallets',
    key: 'wallet',
    align: 'left',
    dataIndex: 'wallet',
    render: (wallet) => getResourceValue('walletType', wallet, enumTypes.walletType) ?? <CellAlign type="left">-</CellAlign>,
  },
];

const casinoProductSettings = (actions) => [
  {
    title: 'mainSettings',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'no-header-separator',
    width: 240,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>
          <IconSvg icon={record.icon} height="1.4rem" width="1.4rem" />
        </div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    key: 'description',
    dataIndex: 'description',
    align: 'left',
    className: 'no-truncate no-header-separator',
    render: (text, record) => <Description description={record.description} changes={record.changes} />,
  },
  {
    title: 'action',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    width: 80,
    render: (text, record) => (
      <TableActions>
        <StyledSvgIconHover>
          <Can type={types.edit} rule={`${resource.brands}.${resource.casinoSettings}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{record.enabled ? 'deactivate' : 'activate'}</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledSwitchSmall onClick={() => actions.setAction({ data: record, type: 'activate', visible: true })} checked={record.enabled} size="small" />
              </Tooltip>
            )}
          </Can>
        </StyledSvgIconHover>
      </TableActions>
    ),
  },
];

const casinoProductWalletSettings = () => [
  {
    title: 'brand:features',
    key: 'key',
    align: 'left',
    dataIndex: 'key',
    width: '30%',
    render: (key) => i18n.t(sportsBookFeaturesWallets[key]),
  },
  {
    title: 'brand:wallets',
    key: 'wallet',
    align: 'left',
    dataIndex: 'wallet',
    render: (wallet) => getResourceValue('walletType', wallet, enumTypes.walletType) ?? <CellAlign type="left">-</CellAlign>,
  },
];

const gamesMobileLaunchTypes = (actions) => [
  {
    title: 'brand:gameLaunchType',
    dataIndex: 'iframeOption',
    key: 'iframeOption',
    align: 'left',
    className: 'no-header-separator',
    width: 240,
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>
          <IconSvg icon={record.icon} width="1.71rem" height="1.71rem" />
        </div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: '',
    key: 'description',
    dataIndex: 'description',
    align: 'left',
    className: 'no-truncate no-header-separator',
    render: (text, record) => <Description description={record.description} changes={record.changes} />,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    width: 80,
    render: (text, record) => (
      <TableActions>
        <StyledSvgIconHover>
          <Can type={types.edit} rule={`${resource.brands}.${resource.casinoSettings}`}>
            {!isBrRegulationAudit && (
              <Tooltip title={<Trans>{i18n.t('brand:setAsDefault')}</Trans>} arrow={{ pointAtCenter: true }}>
                <StyledStar disabled={!record.isSelected ? 0 : 1}>
                  <div onClick={() => actions.setAction({ type: 'setLaunchType', visible: true, data: record })}>
                    <IconSvg icon="StarIcon" width="1.2rem" height="1.2rem" color={record.isSelected ? lightModeColors.blue : ''} />
                  </div>
                </StyledStar>
              </Tooltip>
            )}
          </Can>
        </StyledSvgIconHover>
      </TableActions>
    ),
  },
];

const tags = [
  {
    title: 'category',
    dataIndex: 'categoryId',
    key: 'categoryId',
    align: 'left',
    className: 'no-truncate',
    render: (actions, record) => (
      <StyledTagsWrapper width="true" color={colors[record.category?.color]?.background}>
        <Popover placement="bottomLeft" content={record.category?.name} title="" overlayClassName="description-popover">
          <StyledSelectOption>
            <StyledTagCategoryTypeIcon color={record.category?.color}>
              <IconSvg icon={record.category?.isVertical ? 'VerticalTagIcon' : 'HorizontalTagIcon'} width="0.9rem" height="0.9rem" />
            </StyledTagCategoryTypeIcon>
            <StyledTruncate>{record.category?.name}</StyledTruncate>
          </StyledSelectOption>
        </Popover>
      </StyledTagsWrapper>
    ),
  },
  {
    title: 'tags',
    dataIndex: 'tagIds',
    key: 'tagIds',
    align: 'left',
    className: 'no-truncate',
    render: (actions, record) => {
      const data = _.isEmpty(record.tags)
        ? []
        : record.tags.map((el) => ({
          ...el,
          name: (
            <StyledTagsWrapper width="true" color={colors[el.color]?.background}>
              <StyledTagsPointer color={el.color} />
              <Popover placement="bottomLeft" content={el.name} title="" overlayClassName="description-popover">
                <StyledTruncate>{el.name}</StyledTruncate>
              </Popover>
            </StyledTagsWrapper>
          ),
          displayName: (
            <StyledTagsWrapper width="true" maxwidth="true" color={colors[el.color]?.background}>
              <StyledTagsPointer color={el.color} />
              <StyledTruncate>{el.name}</StyledTruncate>
            </StyledTagsWrapper>
          ),
        }));
      return <More data={data} allowTruncate={false} />;
    },
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    className: 'edit-action no-truncate',
    align: 'right',
    fixed: 'right',
    width: 90,
    render: (actions, record, index) => (
      <ActionsTextItems>
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            actions.edit(record, index);
          }}
        >
          <Tooltip placement="top" title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
            <StyledSvgIconHover>
              <div>
                <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
              </div>
            </StyledSvgIconHover>
          </Tooltip>
        </Button>
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            actions.delete(record);
          }}
        >
          <Tooltip placement="top" title={i18n.t('delete')} arrow={{ pointAtCenter: true }}>
            <StyledSvgIconHover>
              <div>
                <IconSvg icon="DeleteIcon" width="1.14rem" height="1.14rem" />
              </div>
            </StyledSvgIconHover>
          </Tooltip>
        </Button>
      </ActionsTextItems>
    ),
  },
];

const brandLayouts = (actions) => [
  {
    title: 'brand:availableLayouts',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: '16rem',
    render: (text, record) => (
      <StyledNameWithIcon>
        <div>
          <IconSvg icon={sportsBookLayoutTypesIconMapping[record.id]} height="1.14rem" width={null} />
        </div>
        <p>{record.name}</p>
      </StyledNameWithIcon>
    ),
  },
  {
    title: 'description',
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    className: 'no-truncate',
    render: (text, record) => (record.description ? record.description : <CellAlign>-</CellAlign>),
  },
  {
    title: 'actions',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    width: 100,
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        {!isBrRegulationAudit && (
          <StyledStar disabled={record.isMain}>
            <div
              onClick={() => {
                actions.setViewId(record.id);
              }}
            >
              <Tooltip title={<Trans>{i18n.t('brand:setMainView')}</Trans>} arrow={{ pointAtCenter: true }}>
                <div>
                  <IconSvg icon="StarIcon" width="1rem" height="1rem" color={record.isMain ? '#00a1af' : ''} />
                </div>
              </Tooltip>
            </div>
          </StyledStar>
        )}
        <div>
          <Tooltip placement="top" title={<Trans>view</Trans>}>
            <Link to={record.link} target="_blank" rel="noreferrer">
              <IconSvg icon="PreviewIcon" width="1.43rem" height="1.43rem" />
            </Link>
          </Tooltip>
        </div>
      </TableActions>
    ),
  },
];

const sportStatusIcons = (actions) => [
  {
    title: 'sportStatus',
    dataIndex: 'mappingId',
    key: 'mappingId',
    align: 'left',
    width: '30%',
    render: (text, record) => getResourceValue('sportPlayerStatus', record.mappingId, enumTypes.sportPlayerStatus) ?? <CellAlign>-</CellAlign>,
  },
  {
    title: 'icon',
    dataIndex: 'iconUrl',
    key: 'iconUrl',
    align: 'left',
    width: '60%',
    render: (text, record) => (record.iconUrl ? <img height={32} alt={getResourceValue('sportPlayerStatus', record.mappingId, enumTypes.sportPlayerStatus)} src={record.iconUrl} loading="lazy" /> : <CellAlign>-</CellAlign>),
  },
  {
    title: 'actions',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        {!isBrRegulationAudit && (
          <>
            <Tooltip placement="top" title={i18n.t('reset')}>
              <StyledSvgIconHover onClick={() => actions.setAction({ visible: true, type: 'resetIcon', data: record })}>
                <IconSvg icon="ResetIcon" width="1.14rem" />
              </StyledSvgIconHover>
            </Tooltip>
            <Tooltip placement="top" title={i18n.t('upload')} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover onClick={() => actions.setAction({ visible: true, type: 'uploadIcon', data: record })}>
                <IconSvg icon="UploadIcon" width="1.28rem" />
              </StyledSvgIconHover>
            </Tooltip>
          </>
        )}
      </TableActions>
    ),
  },
];

const brandAGPaymentCurrencySettings = (isView) => [
  {
    title: 'currency',
    dataIndex: 'currencyId',
    key: 'currencyId',
    align: 'left',
    width: 180,
    render: (actions, record) => (
      <StyledFlagWrapper>
        {record.currencyId && (
          <StyledFlag className="f24">
            <div className={`${record.currencyId.toLowerCase()} flag`} />
          </StyledFlag>
        )}
        <strong>{record.currencyId}</strong>
      </StyledFlagWrapper>
    ),
  },
  {
    title: 'brand:minDepositAmount',
    dataIndex: 'minDepositAmount',
    key: 'minDepositAmount',
    edit: true,
    align: 'left',
    width: 260,
    isDouble: true,
    render: (actions, record) => !_.isNull(record.minDepositAmount) && <span>{record.minDepositAmount}</span>,
    rules: () => [
      ({ getFieldValue }) => ({
        validator: (rule, value) => {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          if (value && getFieldValue('maxDepositAmount') && value > +getFieldValue('maxDepositAmount')) {
            return Promise.reject(
              <FormItemErrorMessage
                errorMassage={(translate) => translate('validate:mustBeLowerOrEqual', { fieldMin: translate('brand:minDepositAmount'), fieldMax: translate('brand:maxDepositAmount') })}
              />,
            );
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    title: 'brand:maxDepositAmount',
    dataIndex: 'maxDepositAmount',
    key: 'maxDepositAmount',
    edit: true,
    align: 'left',
    width: 260,
    isDouble: true,
    render: (actions, record) => !_.isNull(record.maxDepositAmount) && <span>{record.maxDepositAmount}</span>,
    rules: () => [
      ({ getFieldValue }) => ({
        validator: (rule, value) => {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          if (value && getFieldValue('minDepositAmount') && value < +getFieldValue('minDepositAmount')) {
            return Promise.reject(
              <FormItemErrorMessage
                errorMassage={(translate) => translate('validate:mustBeGreaterOrEqual', { fieldMin: translate('brand:minDepositAmount'), fieldMax: translate('brand:maxDepositAmount') })}
              />,
            );
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    title: 'brand:minWithdrawalAmount',
    dataIndex: 'minWithdrawalAmount',
    key: 'minWithdrawalAmount',
    edit: true,
    align: 'left',
    width: 260,
    isDouble: true,
    rules: () => [
      ({ getFieldValue }) => ({
        validator: (rule, value) => {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          if (value && getFieldValue('maxWithdrawalAmount') && value > +getFieldValue('maxWithdrawalAmount')) {
            return Promise.reject(
              <FormItemErrorMessage
                errorMassage={(translate) => translate('validate:mustBeLowerOrEqual', { fieldMin: translate('brand:minWithdrawalAmount'), fieldMax: translate('brand:maxWithdrawalAmount') })}
              />,
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    render: (actions, record) => !_.isNull(record.minWithdrawalAmount) && <span>{record.minWithdrawalAmount}</span>,
  },
  {
    title: 'brand:maxWithdrawalAmount',
    dataIndex: 'maxWithdrawalAmount',
    key: 'maxWithdrawalAmount',
    edit: true,
    align: 'left',
    width: 260,
    isDouble: true,
    rules: () => [
      ({ getFieldValue }) => ({
        validator: (rule, value) => {
          if (value && !new RegExp(AppConstants.regex.doubleNumber).test(value)) {
            return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:invalidNumber')} />);
          }
          if (value && getFieldValue('minWithdrawalAmount') && value < +getFieldValue('minWithdrawalAmount')) {
            return Promise.reject(
              <FormItemErrorMessage
                errorMassage={(translate) => translate('validate:mustBeGreaterOrEqual', { fieldMin: translate('brand:minWithdrawalAmount'), fieldMax: translate('brand:maxWithdrawalAmount') })}
              />,
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    render: (actions, record) => !_.isNull(record.maxWithdrawalAmount) && <span>{record.maxWithdrawalAmount}</span>,
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'action',
    className: 'edit-action',
    fixed: 'right',
    align: 'right',
    hidden: isView,
    render: (actions, record) => {
      const {
        minDepositAmount, maxDepositAmount, minWithdrawalAmount, maxWithdrawalAmount,
      } = record;

      const isResetActive = minDepositAmount || maxDepositAmount || minWithdrawalAmount || maxWithdrawalAmount;
      return (
        <ActionsTextItems>
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              actions.edit(record);
            }}
          >
            <Tooltip placement="top" title={i18n.t('edit')} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div>
                  <IconSvg icon="EditIcon" width="1.14rem" height="1.14rem" />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          </Button>
          <Button
            disabled={!isResetActive}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              actions.reset(record);
            }}
          >
            <Tooltip placement="top" title={i18n.t('reset')} arrow={{ pointAtCenter: true }}>
              <StyledSvgIconHover>
                <div>
                  <IconSvg icon="ResetIcon" width="1.14rem" height="1.14rem" />
                </div>
              </StyledSvgIconHover>
            </Tooltip>
          </Button>
        </ActionsTextItems>
      );
    },
  },
];

const jackpotFeeds = (actions) => [
  {
    title: 'brand:jackpot',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 160,
    render: (text, record) => (record.name ? (
      <Popover placement="bottomLeft" content={record.name} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.name}</StyledTruncate>
      </Popover>
    ) : (
      <CellAlign>-</CellAlign>
    )),
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
    align: 'left',
    width: 50,
    render: (text, record) => getResourceValue('jackpotFeedTypes', record.type, enumTypes.jackpotFeedTypes) ?? <CellAlign>-</CellAlign>,
  },
  {
    title: 'providerName',
    dataIndex: 'providerName',
    key: 'providerName',
    align: 'left',
    width: 160,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.providerName} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.providerName}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'providerId',
    dataIndex: 'providerId',
    key: 'providerId',
    align: 'left',
    width: 120,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.providerId} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.providerId}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'games',
    dataIndex: 'gamesCount',
    key: 'gamesCount',
    width: 100,
    render: (text, record) => {
      if (!record.gamesCount) {
        return record.gamesCount;
      }
      const query = window.location.search;
      return (
        <BlueBackgroundItem to={`/brands/${actions.getBrandId()}/jackpotFeedGames/${record.id}${query}`}>
          <HighlightedText>{record.gamesCount}</HighlightedText>
        </BlueBackgroundItem>
      );
    },
  },
  {
    title: 'state',
    key: 'enabled',
    dataIndex: 'enabled',
    align: 'left',
    width: 100,
    render: (text, record) => <Trans>{enableDisableState[+record.enabled]}</Trans>,
  },
  {
    title: 'brand:globalState',
    key: 'globallyEnabled',
    dataIndex: 'globallyEnabled',
    align: 'left',
    width: 160,
    render: (text, record) => <Trans>{enableDisableState[+record.globallyEnabled]}</Trans>,
  },
  {
    title: 'creationDate',
    key: 'createdDate',
    dataIndex: 'createdDate',
    align: 'left',
    width: 160,
    render: (text, record) => (record.createdDate ? getFormattedDate(record.createdDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'lastUpdateDate',
    key: 'updatedDate',
    dataIndex: 'updatedDate',
    align: 'left',
    width: 160,
    render: (text, record) => (record.updatedDate ? getFormattedDate(record.updatedDate, AppConstants.format.dateTime) : <CellAlign>-</CellAlign>),
  },
  {
    title: 'lastUpdateBy',
    key: 'lastUpdatedBy',
    dataIndex: 'lastUpdatedBy',
    align: 'left',
    width: 130,
    render: (lastUpdatedBy) => (lastUpdatedBy ? (
      <Popover placement="bottomLeft" content={lastUpdatedBy} title="" overlayClassName="description-popover">
        <StyledTruncate>{lastUpdatedBy}</StyledTruncate>
      </Popover>
    ) : (
      <CellAlign>-</CellAlign>
    )),
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    align: 'right',
    fixed: 'right',
    render: (text, record) => (
      <TableActions>
        <Can type={types.statusChange} rule={`${resource.brands}.${resource.brandJackpots}`}>
          {!isBrRegulationAudit && (
            <Tooltip title={<Trans>{record.enabled ? 'disable' : 'enable'}</Trans>} arrow={{ pointAtCenter: true }}>
              <StyledAction>
                <StyledSwitchSmall onClick={() => actions.setAction({ data: record, visible: true, type: 'statusChange' })} checked={record.enabled} size="small" />
              </StyledAction>
            </Tooltip>
          )}
        </Can>
      </TableActions>
    ),
  },
];

const jackpotFeedGames = () => [
  {
    title: 'id',
    dataIndex: 'gameId',
    key: 'gameId',
    width: 120,
  },
  {
    title: 'name',
    dataIndex: 'gameName',
    key: 'gameName',
    width: 160,
  },
  {
    title: 'provider',
    dataIndex: 'provider',
    key: 'provider',
    align: 'left',
    width: 160,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.provider} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.provider}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'providerId',
    dataIndex: 'providerId',
    key: 'providerId',
    align: 'left',
    width: 120,
    render: (text, record) => (
      <Popover placement="bottomLeft" content={record.providerId} title="" overlayClassName="description-popover">
        <StyledTruncate>{record.providerId}</StyledTruncate>
      </Popover>
    ),
  },
  {
    title: 'category',
    key: 'systemCategory',
    dataIndex: 'systemCategory',
    width: 160,
  },
  {
    title: 'reporting:gameType',
    dataIndex: 'gameType',
    key: 'gameType',
    width: 180,
    render: (text, record) => record.gameType ?? <CellAlign>-</CellAlign>,
  },
  {
    title: 'gameState',
    dataIndex: 'isLaunchable',
    key: 'isLaunchable',
    width: 140,
    render: (text, record) => i18n.t(activationState[+record.isLaunchable]),
  },
  {
    title: 'gameBrandState',
    key: 'systemState',
    dataIndex: 'systemState',
    align: 'left',
    width: 160,
    render: (text, record) => <Trans>{activationState[+record.systemState]}</Trans>,
  },
  {
    title: 'gameGlobalState',
    dataIndex: 'gameSystemState',
    key: 'gameSystemState',
    width: 160,
    render: (text, record) => i18n.t(activationState[+record.gameSystemState]),
  },
];

const brandSetting = {
  currency,
  addCurrency,
  country,
  addCountry,
  language,
  addLanguage,
  domain,
  customScripts,
  casino,
  generalVerificationSettings,
  sportsBook,
  casinoProviderGames,
  email,
  conversion,
  conversionLogs,
  standards,
  othersConversion,
  blockedCountry,
  blockedIP,
  largeTransactions,
  largeTransactionLogs,
  playerLimits,
  legalAgreements,
  paymentMethods,
  paymentMethodsFees,
  paymentMethodsLimits,
  paymentCurrencySettings,
  replacePaymentCurrencySettings,
  paymentMethodCurrencySettings,
  viewGeneralVerificationSettings,
  seo,
  taxes,
  taxDepositView,
  taxWithdrawalView,
  sportsBookWidgets,
  sportsBookMobileIntegration,
  sportsBookSettings,
  seoPages,
  unverifiedPlayersDepositLimits,
  preferences,
  sportsBookWalletsSettings,
  casinoProductSettings,
  casinoProductWalletSettings,
  tags,
  brandLayouts,
  gamesMobileLaunchTypes,
  sportStatusIcons,
  brandAGPaymentCurrencySettings,
  jackpotFeeds,
  jackpotFeedGames,
};

export default brandSetting;
