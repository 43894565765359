import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AppConstants } from 'constants/index';
import { resources } from 'constants/locale';
import { getLocaleResources } from 'actions/locale/localeActionCreator';
import LocalStorageService from 'services/localStorageService';
import Config from 'config/index';
import StoreService from './storeService';

const defaultNS = 'common';
const { env } = Config;

const getResources = () => Object.keys(resources[defaultNS]).reduce((accumulator, lng) => {
  const namespaceObj = {};
  Object.keys(resources).forEach((namespace) => {
    namespaceObj[namespace] = resources[namespace][lng] || {};
  });
  accumulator[lng] = namespaceObj;
  return accumulator;
}, {});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: getResources(),
    fallbackLng: AppConstants.defaultLanguage,
    debug: env !== 'production',

    // have a common namespace used around the full app
    ns: Object.keys(resources),
    defaultNS,

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

const getAsyncResources = () => {
  if (LocalStorageService.get('auth')) {
    StoreService.getStore().dispatch(getLocaleResources());
  }
};

i18n.on('languageChanged', getAsyncResources);

export default i18n;
